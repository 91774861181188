import React from 'react';
import { Text } from '@cvp/design-system/react';
import { useFormFops } from 'extranet/hooks/useFormFops';
import { IResponseObterListaFopsAtivos } from 'extranet/types/IResponseObterListaFopsAtivos';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { LinkFop } from 'main/styles/GlobalStyle';
import masks from 'main/utils/masks';

type ListaFopsProps = {
  tipoFop?: string;
  dataToList: Array<IResponseObterListaFopsAtivos> | undefined;
};
export interface IArquivoDownload {
  sucesso: true;
  mensagem: string;
  stackTrace: string;
  dados: string;
}
const ListarFops: React.FC<ListaFopsProps> = ({ tipoFop, dataToList }) => {
  const { baixarArquivoFop } = useFormFops();

  return (
    <>
      {tryGetValueOrDefault([dataToList], [] as IResponseObterListaFopsAtivos[])
        .filter(
          el =>
            el.tipo === tipoFop &&
            el.codigo !== 62 &&
            el.codigo !== 63 &&
            // el.codigo !== 223 &&
            el.dadosArquivos?.filter(
              e =>
                e.indicadorUsoDoArquivo === 'D' && e.codigoIdentificadorUnico,
            ),
        )

        .map((item: IResponseObterListaFopsAtivos) => (
          <Text variant="body01-sm" color="primary" margin key={item.codigo}>
            <LinkFop
              variant="text"
              key={item.codigo}
              onClick={() =>
                baixarArquivoFop(
                  item.dadosArquivos
                    ? item.dadosArquivos[0]?.codigoIdentificadorUnico
                    : '00000000',
                  item.dadosArquivos
                    ? item.dadosArquivos[0]?.nomeArquivo
                    : 'Planilha_teste.html',
                )
              }
            >
              <b>
                FOP {`000${item.codigo}`.slice(-3)}{' '}
                {`00${item.numeroVersao}`.slice(-2)}{' '}
              </b>
              - {masks.dateFop.mask(item.dataVersao)} - {item.nome}
            </LinkFop>
          </Text>
        ))}
    </>
  );
};

export default ListarFops;
