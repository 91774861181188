import React, { useEffect, useState } from 'react';
import Table from 'main/components/Table';
import { DadosSimulacao } from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';
import { preencherDadosSimulacao } from 'previdencia/features/SimulacaoRenda/factories/simulacaoRendaFactory';
import { COLUNAS_TABELA_DADOS_SIMULACAO } from 'previdencia/features/SimulacaoRenda/constants/constants';
import { TabelaDadosSimulacaoProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';

const TabelaDadosSimulacao: React.FC<TabelaDadosSimulacaoProps> = ({
  tabelaSimulacao,
  request,
}) => {
  const [dadosTabela, setDadosTabela] = useState<DadosSimulacao[] | undefined>(
    undefined,
  );

  const result =
    dadosTabela?.map(({ comparacao, simulacao, estimativaRenda }) => ({
      comparacao,
      simulacao: <strong>{simulacao}</strong>,
      estimativaRenda: <strong>{estimativaRenda}</strong>,
    })) ?? [];

  useEffect(() => {
    if (tabelaSimulacao) {
      setDadosTabela(preencherDadosSimulacao(tabelaSimulacao, request));
    }
  }, []);

  return (
    <Table
      noHeader
      responsive
      highlightOnHover
      striped
      data={result}
      columns={COLUNAS_TABELA_DADOS_SIMULACAO}
      noDataComponent="Não há dados para exibir."
      paginationComponentOptions={{
        rowsPerPageText: 'Items por página',
        rangeSeparatorText: 'de',
      }}
    />
  );
};

export default TabelaDadosSimulacao;
