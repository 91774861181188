import React from 'react';
import { Display, Grid, Card, Text } from '@cvp/design-system/react';
import { useHistory, useLocation } from 'react-router-dom';
import ButtonAction from 'previdencia/features/SolicitacaoResgate/components/ButtonAction';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { ParamsLocationData } from 'previdencia/features/SolicitacaoResgate/types/ParamsLocationData';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { solicitacaoResgateInfoLabels } from '../constants/constants';

const SolicitacaoResgateInfo: React.FC = () => {
  const history = useHistory();
  const { navigateTo } = usePrevNavigation();

  const location = useLocation<ParamsLocationData>();
  const {
    beneficioId,
    resgateId,
    dadosResgate,
    valorBrutoRetirada,
    planoId,
    saldo,
  } = location.state;

  const backRouter = () => {
    history.replace(`/cliente/produtos/previdencia/solicitacao-resgate`);
  };

  const nextRouter = () => {
    navigateTo('efetuar-resgate', {
      valorBrutoRetirada,
      resgateId,
      dadosResgate,
      beneficioId,
      planoId,
      saldo,
    });
  };

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Text variant="headline-06" color="primary" margin>
            {solicitacaoResgateInfoLabels.SOLICITAR_RESGATE}
          </Text>

          <Grid.Item xs={1}>
            <Text variant="body01-lg" color="text" margin>
              {solicitacaoResgateInfoLabels.ATENCAO}
            </Text>

            <Text variant="body01-md" color="text-light" margin>
              {solicitacaoResgateInfoLabels.REALIZACAO_RESGATE_NEGATIVA}
            </Text>

            <Text variant="body01-md" color="text-light" margin>
              {solicitacaoResgateInfoLabels.INFORME_CLIENTE_DIMINUIR_RECURSOS}
            </Text>

            <Text variant="body01-md" color="text-light" margin>
              {solicitacaoResgateInfoLabels.SUJIRA_POSSIVEL_OPERACAO_CREDITO}
            </Text>

            <Text variant="body01-md" color="text-light" margin>
              {solicitacaoResgateInfoLabels.EM_CASO_RESGATE_TOTAL}
            </Text>

            <Text variant="body01-md" color="text-light" margin>
              {solicitacaoResgateInfoLabels.VALORES_ACIMA_SUJEITOS_ALTERACAO}
            </Text>

            <Text variant="body01-md" color="text-light" margin>
              {solicitacaoResgateInfoLabels.HAVERA_INCIDENCIA_IMPOSTO_RENDA}
            </Text>

            <Text variant="body01-md" color="text-light" margin>
              {
                solicitacaoResgateInfoLabels.ANTES_EFETUAR_RESGATE_VALIDAR_EMAIL_TELEFONE
              }
            </Text>

            <Text variant="body01-md" color="text-light" margin>
              {
                solicitacaoResgateInfoLabels.ESSE_RESGETE_PODERA_SER_CANCELADO_POR_MOTIVO_SEGURANCA
              }
            </Text>

            <ButtonAction
              titleButtonLeft="Cancelar"
              titleButtonRight="Continuar"
              onClickButtonLeft={() => backRouter()}
              onClickButtonRight={() => nextRouter()}
            />
          </Grid.Item>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default SolicitacaoResgateInfo;
