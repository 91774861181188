import {
  ListaContribuicao,
  ListaFundos,
} from 'previdencia/features/SolicitacaoResgate/types/ListaContribuicao';
import { ResponseDefinirContribuicaoRegular } from 'previdencia/features/SolicitacaoResgate/types/ResponseDefinirContribuicaoRegular';
import { usePeco } from 'main/hooks/usePeco';
import { IHandleReponseResult } from 'main/types/HandleResponseApi/IHandleReponseResult';
import useObterConfirmacaoResgate from './useObterConfirmacaoResgate';
import { DadosFundo } from '../types/ResponsePermitirAlterarValorContribuicaoRegular';

type RequestDefinirContribuicaoRegular = {
  cpfCnpj: string;
  listaContribuicoes: ListaContribuicao[];
};

type UseObterDefinicaoContribuicaoRegular = {
  isSuccessDefinirContribuicaoRegular: boolean;
  isFetchingDefinicaoContribuicaoRegular: boolean;
  atualizarContribuicao: () => Promise<
    IHandleReponseResult<ResponseDefinirContribuicaoRegular> | undefined
  >;
  fundosValorContribuicaoSelecionado: DadosFundo[];
  isFetchingConfirmacaoResgate: boolean;
};

const useObterDefinicaoContribuicaoRegular = (
  cpf: string,
  numSolicitacao: string,
  numCertificado: string,
  fundosValorContribuicaoSelecionado: DadosFundo[],
): UseObterDefinicaoContribuicaoRegular => {
  const {
    fetchData: atualizarContribuicao,
    loading: isFetching,
    response: definirContribuicaoRegular,
  } = usePeco<
    RequestDefinirContribuicaoRegular,
    ResponseDefinirContribuicaoRegular
  >({
    api: {
      operationPath: 'PECO_DefinirContribuicaoRegular',
    },
    payload: {
      cpfCnpj: cpf,
      listaContribuicoes: [
        {
          listaFundos: fundosValorContribuicaoSelecionado
            .filter(item => !!item.selecionado)
            .map(
              fundo =>
                ({
                  codReserva: fundo.codReserva,
                  codFundo: fundo.codFundo,
                  vlContribuicaoAtual: fundo.vlContribuicaoAtual,
                } as ListaFundos),
            ),
          numCertificado,
          numSolicitacao,
        },
      ],
    },
  });

  const { isFetchingConfirmacaoResgate } = useObterConfirmacaoResgate(
    cpf,
    numSolicitacao,
    definirContribuicaoRegular?.entidade,
    !!definirContribuicaoRegular,
  );

  return {
    isSuccessDefinirContribuicaoRegular: !!definirContribuicaoRegular?.entidade,
    isFetchingDefinicaoContribuicaoRegular: isFetching,
    atualizarContribuicao,
    fundosValorContribuicaoSelecionado,
    isFetchingConfirmacaoResgate,
  };
};

export default useObterDefinicaoContribuicaoRegular;
