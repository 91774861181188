import { api, IApiResponse } from 'main/services';
import { obterEndpointConsultarDetalheCalculo } from 'previdencia/config/endpoints';
import { ResponseConsultarDetalheCalculo } from 'previdencia/features/SolicitacaoResgate/types/ResponseConsultarDetalheCalculo';

export const obterConsultaDetalheCalculo = async (
  cpfCnpj: string,
  numeroCertificado: string,
  numResgate: string,
  codopera = '4',
): Promise<ResponseConsultarDetalheCalculo | undefined> => {
  const { data } = await api.post<
    IApiResponse<ResponseConsultarDetalheCalculo>
  >(obterEndpointConsultarDetalheCalculo(), {
    cpfCnpj,
    numeroCertificado,
    numResgate,
    codopera,
  });

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data?.dados?.entidade;
};
