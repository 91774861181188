import React from 'react';
import { Text } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import VoltarDadosPlano from 'main/components/Button/voltarDadosPlano/VoltarDadosPlano';
import InformacoesProduto from 'previdencia/features/SimulacaoRenda/components/InformacoesProduto';
import TiposRenda from 'previdencia/features/SimulacaoRenda/components/TiposRenda';
import useObterTiposPagamentos from 'previdencia/features/SimulacaoRenda/hooks/useObterTiposRendas';
import { IndicacaoTipoRendaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';

const IndicacaoTipoRenda: React.FC<IndicacaoTipoRendaProps> = ({
  proximoServico,
  dadosBasicos,
  loadingDadosBasicos,
}) => {
  const { data: tiposRendas, isLoading: loadingTiposPagamentos } =
    useObterTiposPagamentos();

  if (loadingDadosBasicos || loadingTiposPagamentos) {
    return <SkeletonLoading blocks={2} />;
  }

  return (
    <>
      <Text variant="body01-sm" margin>
        Veja o quanto seu cliente pode ter de renda mensal com sua reserva
        atual.
      </Text>

      <InformacoesProduto dados={dadosBasicos} />

      <TiposRenda
        proximaTela={proximoServico}
        dados={tiposRendas}
        planoContratado={dadosBasicos?.codTipoPagamento}
      />

      <Text variant="caption-02" margin>
        * Os cálculos e as hipóteses aqui apresentadas são meras estimativas,
        não se constituindo em garantia ou obrigação da Caixa Vida e
        Previdência.
      </Text>

      <VoltarDadosPlano />
    </>
  );
};

export default IndicacaoTipoRenda;
