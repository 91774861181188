import { api, IApiResponse } from 'main/services';
import { obterEndpointCoberturas } from 'previdencia/config/endpoints';
import { ResponseCoberturas } from 'previdencia/features/SolicitacaoResgate/types/ResponseCoberturas';

export const obterCoberturas = async (
  cpf: string,
  numeroCertificado: string,
): Promise<ResponseCoberturas | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseCoberturas>>(
    obterEndpointCoberturas(),
    {
      cpf,
      numeroCertificado,
    },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data?.dados?.entidade;
};
