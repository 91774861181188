import React, { useEffect, useState } from 'react';
import { Display, Text, Grid } from '@cvp/design-system/react';
import { FlexContainer } from 'main/styles/GlobalStyle';
import Select, { SelectItem } from 'main/components/form/Select';
import useFieldLink from 'main/hooks/useFieldLink';
import { required } from 'main/features/Validation/validations';
import masks from 'main/utils/masks';
import RenderConditional from 'main/components/RenderConditional';
import { DadosBancarios } from 'previdencia/features/SolicitacaoResgate/types/DadosBancarios';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import SelectSearch, { ISelectSearchItem } from 'main/components/SelectSearch';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as S from './styles';
import { TipoContaBancaria } from '../../types/TipoContaBancaria';
import { DadosInstituicaoBancaria } from '../../types/DadosInstituicaoBancaria';
import {
  DADOS_BANCO_CONTA,
  footerEfetuarResgateLabels,
} from '../../constants/constants';

type FooterEfetuarResgateProps = {
  listaInstituicoesBancarias: DadosInstituicaoBancaria[];
  handleSelecionarNovaContaBancaria: (
    dadosBancarios: DadosBancarios | null,
  ) => void;
  handleObterTiposContasBancarias: (numeroBanco: string) => TipoContaBancaria[];
};

const FooterEfetuarResgate: React.FC<FooterEfetuarResgateProps> = ({
  listaInstituicoesBancarias,
  handleSelecionarNovaContaBancaria,
  handleObterTiposContasBancarias,
}) => {
  const [tipoContaBancariaLink] = useFieldLink('');
  const [agenciaLink] = useFieldLink('');
  const [contaLink] = useFieldLink('');
  const [digitoContaLink] = useFieldLink('');
  const [bancoSelecionado, setBancoSelecionado] = useState<ISelectSearchItem>();
  const tipoContaBancaria = tipoContaBancariaLink.get().value;
  const numeroAgencia = agenciaLink.get().value;
  const numeroConta = contaLink.get().value;
  const digitoConta = digitoContaLink.get().value;
  const [dadosContaNovaInformada, setDadosContaNovaInformada] =
    useState<DadosBancarios | null>(null);

  const isSelected = (fieldLink: LinkedValue<string>, value: string) => {
    return fieldLink.get().value === value;
  };

  const [quantidadeCharInput, setQuantidadeCharInput] = useState(
    DADOS_BANCO_CONTA.QTD_CHAR_INPUT.MAXIMA,
  );

  const lockMaxlengthContaCaixa = (): string => {
    if (
      checkIfAllItemsAreTrue([
        bancoSelecionado?.value === DADOS_BANCO_CONTA.NUM_BANCO_CAIXA,
        tipoContaBancaria.length ===
          DADOS_BANCO_CONTA.QTD_CHAR_TIPO_CONTA_OPERACAO.NSGD,
      ])
    ) {
      return DADOS_BANCO_CONTA.QTD_CHAR_INPUT.NSGD;
    }

    if (
      checkIfAllItemsAreTrue([
        bancoSelecionado?.value === DADOS_BANCO_CONTA.NUM_BANCO_CAIXA,
        tipoContaBancaria.length ===
          DADOS_BANCO_CONTA.QTD_CHAR_TIPO_CONTA_OPERACAO.SIDEC,
      ])
    ) {
      return DADOS_BANCO_CONTA.QTD_CHAR_INPUT.SIDEC;
    }

    return DADOS_BANCO_CONTA.QTD_CHAR_INPUT.MAXIMA;
  };
  useEffect(() => {
    setQuantidadeCharInput(lockMaxlengthContaCaixa());
  }, [bancoSelecionado?.value, tipoContaBancaria]);

  const todosOsCamposPreenchidos = (
    numBanco: string,
    numAgencia: string,
    numConta: string,
    dvConta: string,
    tipoConta: string,
  ): string => {
    return dvConta && numAgencia && numConta && tipoConta && numBanco;
  };

  useEffect(() => {
    const camposPreenchidos = todosOsCamposPreenchidos(
      tryGetValueOrDefault([bancoSelecionado?.value], ''),
      numeroAgencia,
      numeroConta,
      digitoConta,
      tipoContaBancaria,
    );

    if (!camposPreenchidos)
      if (dadosContaNovaInformada) {
        setDadosContaNovaInformada(null);
        handleSelecionarNovaContaBancaria(null);
        return;
      } else return;

    const numeroBancoSelecionado = tryGetValueOrDefault(
      [bancoSelecionado?.value?.toString()],
      '',
    );

    const dadosContaNova = {
      cpfCnpj: '',
      digitoConta,
      nomeBanco: tryGetValueOrDefault([bancoSelecionado?.label], ''),
      numeroAgencia,
      numeroConta,
      tipoContaBanco: tipoContaBancaria,
      numeroBanco: numeroBancoSelecionado,
      descricaoPagamento: 'RESGATE - TED',
      digitoAgencia: '',
      tipoPagamentoId: '24',
      contaNova: true,
    };

    setDadosContaNovaInformada(dadosContaNova);
    handleSelecionarNovaContaBancaria(dadosContaNova);
  }, [
    bancoSelecionado?.value,
    numeroAgencia,
    numeroConta,
    digitoConta,
    tipoContaBancaria,
  ]);

  useEffect(() => {
    agenciaLink.set({ value: '', isValid: true });
    contaLink.set({ value: '', isValid: true });
    digitoContaLink.set({ value: '', isValid: true });
  }, [tipoContaBancaria, bancoSelecionado]);

  return (
    <Display type="display-block">
      <Grid>
        <Grid.Item xs={1}>
          <S.WrapperMessageInfo>
            <Text variant="body01-md" margin>
              {footerEfetuarResgateLabels.INDICAR_CONTA_PRIMEIRO_TITULAR}
            </Text>

            <Text variant="body01-md" margin>
              {footerEfetuarResgateLabels.CLIENTES_CONTA_CAIXA}
            </Text>

            <Text variant="body01-md" margin>
              {footerEfetuarResgateLabels.OUTROS_CLIENTES}
            </Text>
          </S.WrapperMessageInfo>
        </Grid.Item>

        <Grid.Item xs={1}>
          <S.WrapperInput>
            <FlexContainer alignItems="center" style={{ width: '60%' }}>
              <S.TextInput>Banco:</S.TextInput>
              <SelectSearch
                options={listaInstituicoesBancarias.map(item => ({
                  value: item.codigoBanco?.toString(),
                  label: item.nomeBanco,
                }))}
                selectedItem={bancoSelecionado}
                setSelectedItem={item => setBancoSelecionado(item)}
                style={{ width: '60%' }}
              />
              <RenderConditional condition={!!bancoSelecionado?.value}>
                <S.WrapperInput style={{ width: '60%' }}>
                  <S.TextInput isMarginLeft>Tipo Conta:</S.TextInput>
                  <Select
                    placeholder="Selecione uma opção"
                    validationRules={[required()]}
                    link={tipoContaBancariaLink}
                  >
                    {handleObterTiposContasBancarias(
                      tryGetValueOrDefault([bancoSelecionado?.value], ''),
                    ).map(item => (
                      <SelectItem
                        key={item.codigo}
                        value={item.codigo}
                        text={item.descricao}
                        selected={isSelected(
                          tipoContaBancariaLink,
                          item.codigo,
                        )}
                      />
                    ))}
                  </Select>
                </S.WrapperInput>
              </RenderConditional>
            </FlexContainer>
          </S.WrapperInput>
          <S.WrapperInput>
            <FlexContainer alignItems="center">
              <S.TextInput>{footerEfetuarResgateLabels.AGENCIA}</S.TextInput>
              <S.InputFormBank
                inputMask={masks.agency}
                link={agenciaLink}
                validationRules={[required()]}
                width={140}
              />
              <FlexContainer alignItems="center">
                <S.TextInput isMarginLeft>Conta:</S.TextInput>
                <S.InputFormBank
                  width={190}
                  inputMask={masks.numberOnly}
                  maxLength={quantidadeCharInput}
                  link={contaLink}
                  validationRules={[required()]}
                />
                <S.Separador />
                <S.InputFormBank
                  width={60}
                  inputMask={masks.numberOnly}
                  maxLength="1"
                  link={digitoContaLink}
                  validationRules={[required()]}
                />
              </FlexContainer>
            </FlexContainer>
          </S.WrapperInput>
        </Grid.Item>
      </Grid>
    </Display>
  );
};

export default FooterEfetuarResgate;
