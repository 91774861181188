import styled from 'styled-components/macro';

export const Table = styled.table`
  display: none;
  th,
  td {
    font-size: 11px;
  }
  @media print {
    display: table;
  }
`;
