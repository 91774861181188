import { Grid, Radio, Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import Table, { Skeleton } from 'main/components/Table';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { COLUNAS_MOTIVO_RESGATE } from 'previdencia/features/SolicitacaoResgate/constants/colunasMotivoResgate';
import { motivoResgate } from 'previdencia/features/SolicitacaoResgate/constants/constants';
import { usePecoConsultarMotivosResgate } from 'previdencia/features/SolicitacaoResgate/hooks/usePecoConsultarMotivosResgate';
import {
  MotivoResgateProps,
  MotivoResgateTable,
  MotivoResgate as MotivoResgateType,
} from 'previdencia/features/SolicitacaoResgate/types/MotivoResgate';
import { ChangeEvent, FC, memo } from 'react';

const MotivoResgateDados: FC<MotivoResgateProps> = ({ motivo, setMotivo }) => {
  const { loading, response } = usePecoConsultarMotivosResgate();

  const dados: MotivoResgateTable[] = tryGetValueOrDefault(
    [response?.entidade],
    [],
  ).map((itemMotivo: MotivoResgateType) => ({
    codigo: itemMotivo.codigo,
    radio: (
      <Radio
        name="radio-selelcionar-motivo"
        value={itemMotivo.codigo}
        id={`radio-selelcionar-motivo-${itemMotivo.codigo}`}
        data-testid={`radio-selelcionar-motivo-${itemMotivo.codigo}`}
        aria-label={itemMotivo.descricao}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setMotivo(e.target.value)
        }
        checked={itemMotivo.codigo === motivo}
      />
    ),
    descricao: (
      <label htmlFor={`radio-selelcionar-motivo-${itemMotivo.codigo}`}>
        {itemMotivo.descricao}
      </label>
    ),
  }));

  return (
    <Grid>
      <Grid.Item xs={1}>
        <Text
          variant="body01-lg"
          color="primary"
          aria-label={motivoResgate.TITULO}
        >
          {motivoResgate.TITULO}
        </Text>
      </Grid.Item>
      <Grid.Item xs={1}>
        <Text
          variant="body02-md"
          color="text-light"
          aria-label={motivoResgate.SUB_TITULO}
        >
          {motivoResgate.SUB_TITULO}
        </Text>
      </Grid.Item>
      <Grid.Item xs={1}>
        <RenderConditional condition={loading}>
          <Skeleton colunas={COLUNAS_MOTIVO_RESGATE} />
        </RenderConditional>
        <RenderConditional condition={!loading}>
          <Table
            data={dados}
            columns={COLUNAS_MOTIVO_RESGATE}
            noHeader
            noDataComponent="Sem dados para exibir"
          />
        </RenderConditional>
      </Grid.Item>
    </Grid>
  );
};

const MotivoResgate = memo(MotivoResgateDados);

export { MotivoResgate };
