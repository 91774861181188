export const simulacaoDetalhadaColumns = [
  {
    name: 'Data de contribuição',
    selector: 'data',
    minWidth: '140px',
  },
  {
    name: 'Valor nominal',
    selector: 'valorNominal',
    minWidth: '140px',
  },
  {
    name: 'Rendimento',
    selector: 'rendimento',
    minWidth: '140px',
  },
  {
    name: 'Valor de saída bruto',
    selector: 'valorBruto',
    minWidth: '140px',
  },
  {
    name: 'Carregam. saída',
    selector: 'carregamentoSaida',
  },
  {
    name: 'Valor base IRRF',
    selector: 'baseIrrf',
    minWidth: '140px',
  },
  {
    name: 'Alíquota IRRF (%)',
    selector: 'aliquotaIrrf',
  },
  {
    name: 'Valor do IRRF',
    selector: 'valorIrrf',
    minWidth: '140px',
  },
  {
    name: 'Taxa de saída',
    selector: 'taxaSaida',
  },
  {
    name: 'Valor saída liquida',
    selector: 'valorLiquido',
    minWidth: '140px',
  },
];

export const simulacaoDetalhadaColumnsPrint = [
  'Data',
  'Rendimento',
  'Carregam. saída',
  'Val. base IRRF',
  'Alíquota IRRF',
  'Val. do IRRF',
  'Tx. saída',
  'Val. saída liq.',
];

export const simulacaoDetalhadaLabels = {
  SIMULACAO_RESGATE: ' SIMULAÇÃO DE RESGATE',
  DETALHES_DESCONTOS: ' Entenda os detalhes de como são feitos os descontos:',
  IMPORTANTE: '  IMPORTANTE',
  VALORES_ACIMA_ESTIMADOS_SUJEITOS_ALTERACAO:
    'Os valores acima são estimativas e estão sujeitos a alteração.',
  CENTRAL_RELACIONAMENTO:
    'Em caso de dúvidas, entre em contato com nosso chat online ou pela Central de Serviços e Relacionamento pelo número: 0800 702 4000',
  CAPITAIS_REGIOES_METROPOLITANAS:
    '- 3004 4000 (Capitais e Regiões Metropolitanas)',
  DEMAIS_REGIOES_BRASIL: '- 0800 702 4000 (Demais regiões do Brasil)',
  CANAL_ATENDIMENTO_SURDO:
    '- 0800 702 4260 (CAS - Canal de Atendimento ao Surdo)',
};

export const footerEfetuarResgateLabels = {
  INDICAR_CONTA_PRIMEIRO_TITULAR:
    'Indicar conta de titularidade do cliente (1° titular).',
  CLIENTES_CONTA_CAIXA:
    '- Para clientes com conta na CAIXA informe número de conta corrente ou poupança, no caso de pessoa física.',
  OUTROS_CLIENTES:
    '- Para clientes de outros bancos informar conta corrente ou poupança. Não está disponível resgate com crédito em conta de pessoas jurídicas.',
  AGENCIA: 'Agência:',
};

export const messageValidarDadosLabels = {
  ATENCAO: 'Atenção',
};

export const MessageConsultasProgramadasLabels = {
  SIMULACAO_SOLICITACAO_RESGATE: 'Simulação/Solicitação de Resgate',
};

export const messageDataAposentadoriaLabels = {
  SIMULACAO_SOLICITACAO_RESGATE: 'Simulação/Solicitação de Resgate',
  SIMULAR_RESGATE_ENCARGOS:
    'Simule um resgate e saiba quais são os encargos. juju',
};

export const resgateInfoLabels = {
  VALOR_MINIMO_FUNDO: 'Saldo mínimo a ser mantido no fundo ',
  VALOR_MAXIMO: 'Valor Máximo',
  PARCIAL: 'Parcial',
  TOTAL: 'Total',
  VALOR_MINIMO: 'Valor Mínimo',
  VALOR_FUNDO_PERMANECE: 'O valor do fundo deve permanecer',
  COM_SALDO_MINIMO: 'com um saldo de no mínimo ',
  SEM_DADOS: 'Sem dados para exibir',
  RESTANTE: 'Restante: ',
  NOVO_VALOR_RESGATE: 'Novo valor de Resgate',
  SIMULAR: 'Simular',
};

export const headerInfoSimulacaoLabels = {
  NOME: 'Nome:',
  PRODUTO_MODALIDADE: 'Produto e Modalidade',
  SALDO_EM: 'Saldo em',
  VALOR_SIMULADO: 'Valor simulado:',
  VALOR_LIQUIDO_ESTIMADO: 'Valor líquido estimado:',
  VALOR_MAXIMO_PERMITIDO: 'Valor máximo permitido:',
  CERTIFICADO: 'Certificado:',
  DATA_HORA_SIMULACAO: 'Data e hora da simulação:',
  VALOR_NOMINAL_ESTIMADO: 'Valor nominal estimado:',
  VALOR_IR_ESTIMADO: 'Valor do IR estimado:',
  TAXA_CARREGAMENTO_SAIDA_ESTIMADA: 'Taxa de carregamento na saída estimada:',
  VALOR_BLOQUEADO: 'Valor bloqueado:',
};

export const confirmaValoresLabels = {
  TITULO: ' Simulação Resgate',
  SUB_TITULO: 'Confira abaixo os valores da simulação:',
  SALDO_EM: 'Saldo em',
  VALOR_NOMIMAL_ESTIMADO: 'Valor nominal estimado:',
  VALOR_IR_ESTIMADO: ' Valor de IR estimado:',
  TAXA_CARREGAMENTO_SAIDA_ESTIMADA: 'Taxa de carregamento na saída estimada:',
  VALOR_LIQUIDO_ESTIMADO: 'Valor líquido estimado:',
  VALOR_MAXIMO_PERMITIDO: 'Valor máximo permitido:',
  RESERVA_BLOQUEADA: 'Reserva bloqueada:',
  VALORES_DETALHADOS: ' Valores detalhados',
  REALIZAR_RESGATE: 'Realizar resgate',
  VALOR_SIMULADO: 'Valor simulado:',
};

export const definirValorContribuicaoColumns = [
  {
    name: 'Fundo',
    selector: 'nomeFundo',
    minWidth: '480px',
  },
  {
    name: 'Perfil de risco',
    selector: 'perfilFundo',
    minWidth: '140px',
  },
  {
    name: 'Rentabilidade (ultimos 12 meses)',
    selector: 'rentabilidadeDozeMeses',
  },
  {
    name: 'Contribuicão mensal',
    selector: 'contribuicaoMensal',
  },
];

export const definirValorContribuicaoLabes = {
  ESCOLHA_NOVO_FUNDO: 'Escolha um novo fundo',
  VIMOS_VOCE_INVESTE_VALOR: ' Vimos que você investe o valor de ',
  EM_QUAIS_FUNDOS_QUER_CONTINUAR_CONTRIBUINDO:
    ' por mês. Em quais fundos você quer continuar contribuindo?',
};

export const efetuarSolicitacaoResgateLabels = {
  SOLICITACAO_RESGATE: 'Solicitação de Resgate',
  SALDO_PLANO: 'Saldo do plano:',
  VALOR_SOLICITADO: 'Valor Solicitado:',
  VALOR_NOMINAL_ESTIMADO: 'Valor Nominal estimado:',
  VALOR_IR_ESTIMADO: 'Valor do IR estimado:',
  TAXA_CARREGAMENTO_SAIDA_ESTIMADA: 'Taxa de Carregamento na Saída estimada:',
  VALOR_LIQUIDO_ESTIMADO: 'Valor Líquido estimado:',
  INFORMACOES_BANCARIAS: 'Informações Bancárias',
  SELECIONE_CONTA: 'Selecione uma das contas a seguir.',
  CLIQUE_PARA_INDICAR_OUTRA_CONTA: 'Clique aqui para indicar outra conta',
};

export const motivoSolictacaoResgateLabels = {
  SOLICITACAO_RESGATE: 'Solicitação de Resgate',
  PESQUISA_COM_CLIENTE:
    ' Prezado, para colaborar com a melhoria dos nossos produtos e serviços, gentileza responder a pesquisa com o cliente.',
  MOTIVO_RESGATE: 'Qual o motivo do Resgate?',
  DIFICULDADE_FINANCEIRA: 'Dificuldade financeira',
  INSATISFACAO_VENDA: 'Insatisfação com a Venda',
  ENCERROU_RELACIONAMENTO_CAIXA: 'Encerrou o Relacionamento com a Caixa',
  PROBLEMAS_SAUDE: 'Problemas de Saúde',
  BAIXA_RENTABILIDADE: 'Baixa Rentabilidade',
  MEU_ATENDIMENTO: 'Meu atendimento AGÊNCIA/CENTRAL',
  OUTROS_NEGOCIOS: 'Outros Negócios',
};

export const motivoResgate = {
  TITULO: 'Motivo do Resgate',
  SUB_TITULO: 'Selecione uma das opções a seguir:',
};

export const solicitacaoResgateLabels = {
  SIMULAR_SOLICITACAO_RESGATE: 'Simulação/Solicitação de Resgate',
  SIMULE_RESGATE_SAIBA_ENCARGOS:
    'Simule um resgate e saiba quais são os encargos.',
  SALDO_EM: 'Saldo em ',
  TIPO_RESGATE: 'Tipo de Resgate:',
  VALOR_SOLICITADO: 'Valor Solicitado:',
  ATENCAO_PLANO_COM_RESERVA_MINIMA:
    'Atenção: O plano deverá permanecer com reserva mínima de ',
  VOLTAR: 'Voltar',
  CONFIRMAR: ' Confirmar',
};

export const solicitacaoResgateInfoLabels = {
  SOLICITAR_RESGATE: ' Solicitação de Resgate',
  ATENCAO: 'ATENÇÃO:',
  REALIZACAO_RESGATE_NEGATIVA:
    '- A realização do resgate negativa sua meta e pode trazer prejuízo financeiro ao cliente em virtude do desconto de IR.',
  INFORME_CLIENTE_DIMINUIR_RECURSOS:
    '- Informe ao seu cliente que diminuir os recursos acumulados prejudica sua aposentadoria.',
  SUJIRA_POSSIVEL_OPERACAO_CREDITO:
    '- Sugira, se possível, uma operação de crédito para preservar o seu resultado.',
  EM_CASO_RESGATE_TOTAL:
    ' - Em caso de resgate total, havendo parcela em aberto em nome do titular do plano e ainda em procedimento de cobrança, o valor será devolvido ao cliente.',
  VALORES_ACIMA_SUJEITOS_ALTERACAO:
    '- Os valores acima estão sujeitos a alteração. O valor líquido será creditado somente em conta de pessoa física que seja do titular do plano.',
  HAVERA_INCIDENCIA_IMPOSTO_RENDA:
    '- Haverá incidência de imposto de renda conforme modalidade do plano e regime tributário escolhido.',
  ANTES_EFETUAR_RESGATE_VALIDAR_EMAIL_TELEFONE:
    '- Antes de efetuar qualquer resgate favor validar o e-mail e telefone de contato, por meio de documento que garanta a veracidade dos dados informados.',
  PEDIDO_SERA_AVALIADO_POSTERIORMENTE:
    '- Esse pedido será avaliado posteriormente e, sendo necessário, um contato será feito exclusivamente pela Caixa Previdência com o cliente. Na sua ausência será contatado o gerente da agência. É essencial para a continuidade desse procedimento atender ao contato da Caixa Previdência, bem como atualizar seus dados cadastrais antes do pedido.',
  ESSE_RESGETE_PODERA_SER_CANCELADO_POR_MOTIVO_SEGURANCA:
    '- Esse resgate poderá ser cancelado por motivos de segurança e divergências nas informações cadastrais e/ou bancárias. Nesse caso, um novo resgate deverá ser solicitado.',
};

export const informativoInicio = {
  IMPRESSAO:
    'Imprima o comprovante em 3 vias. Uma das vias deverá ser enviada, obrigatoriamente, à CAIXA Vida e Previdência pelo malote laranja.',
};
export const informativoFinalizar = {
  VALORES_ALTERACAO:
    ' Os valores acima estão sujeitos à alteração. O valor será creditado somente em conta pessoa física que seja titular do plano.',
  IMPOSTO:
    'Haverá incidência de imposto de renda conforme modalidade do plano e regime tributário escolhido.',
  RESGATE_TOTAL:
    'Em caso de resgate total, havendo parcela em aberto em nome do titular do plano e ainda em procedimento de cobrança, o valor será devolvido ao cliente.',
  AVALIACAO_SOLICITACAO:
    'Por motivo de segurança, esta solicitação de resgate será avaliada. A  Caixa Vida e Previdência poderá entrar em contato com o cliente em até 48 horas para validação do pedido de resgate.',
  SOLICITACAO_CANCELADA:
    'A solicitação poderá ser cancelada em caso de ausência ou divergência na validação. Nesse caso será necessário fazer um novo pedido e aguardar o novo prazo para pagamento.',
  PARCELAS_EM_ABERTO:
    ' Caso existam parcelas em aberto e ainda em procedimento de cobrança, o valor será devolvido para a conta de origem.',
  CARENCIA: 'Atenção: o período de carência é de 60 dias.',
};

export const TIPOS_RESGATE = {
  TOTAL: 'T',
  PARCIAL: 'P',
};

export const TEXTO_PRAZO_DIFERIMENTO = {
  MODAL_ALTERACAO: [
    {
      ID: 'MODAL_ALTERACAO_1',
      PARAGRAFO:
        'Para concluir a solicitação de resgate é necessário alterar a data de fim de diferimento.',
    },
    {
      ID: 'MODAL_ALTERACAO_2',
      PARAGRAFO: (
        <>
          Para isso, clique no menu na opção{' '}
          <strong>{'Alterações > Prazo Diferimento'}</strong>, altere para uma
          data futura.
        </>
      ),
    },
  ],
  MODAL_CONSULTA: [
    {
      ID: 'MODAL_CONSULTA_1',
      PARAGRAFO:
        'Para concluir a solicitação de resgate é necessário alterar a data de fim de diferimento, porém seu perfil neste canal não tem permissão para modificar essa data. Entre em contato com a equipe responsável por esta manutenção.',
    },
  ],
  AVISO_ALTERACAO:
    'Para concluir a solicitação de resgate é necessário alterar a data de fim de diferimento.',
  AVISO_CONSULTA:
    'Para concluir a solicitação de resgate é necessário alterar a data de fim de diferimento, porém seu perfil neste canal não tem permissão para modificar essa data. Entre em contato com a equipe responsável por esta manutenção.',
};

export const DADOS_BANCO_CONTA = {
  NUM_BANCO_CAIXA: '104',
  QTD_CHAR_INPUT: { MAXIMA: '99', NSGD: '12', SIDEC: '8' },
  QTD_CHAR_TIPO_CONTA_OPERACAO: {
    NSGD: 4,
    SIDEC: 3,
  },
};
