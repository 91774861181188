import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributarioServicos/types/AlteracaoRegimeTributario';

export const TEXTOS_REGIME_TRIBUTARIO = {
  TITULO: 'Alteração de Regime Tributário',
  SUBTITULO: 'Declaração',
  INFO_CHECKBOX: `O cliente confirma a opção pelo regime de tributação de
  alíquotas regressivas, previsto no artigo 1º da lei 11.053
  de 29 de dezembro de 2004, para o certificado indicado,
  ciente de que esta opção é irretratável.`,
};

export const MODAIS_REGIME_TRIBUTARIO: REGIME_TRIBUTARIO_TYPES.IModaisRegimeTributario =
  {
    modalTipo: false,
    modalDocusign: false,
    modalAlertaContatos: false,
    modalAlertaAlteracaoExpirada: false,
  };
