import { useContext } from 'react';
import { useAuth } from 'main/features/Auth/hooks/useAuth';
import { AppContext } from 'main/contexts/AppContext';
import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import * as PECO_TYPES from 'previdencia/features/AlteracaoRegimeTributarioServicos/types/AlteracaoRegimeTributarioUsePeco';
import * as REQUEST_TYPES from 'previdencia/features/AlteracaoRegimeTributarioServicos/types/AlteracaoRegimeTributarioRequest';
import * as RESPONSE_TYPES from 'previdencia/features/AlteracaoRegimeTributarioServicos/types/AlteracaoRegimeTributarioResponse';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributarioServicos/types/AlteracaoRegimeTributario';

export const useAlterarPerfilTributario =
  (): PECO_TYPES.IUseAlterarPerfilTributario => {
    const { user } = useAuth();
    const { cliente } = useContext(AppContext);

    const {
      response: dadosPerfilTributario,
      loading: loadingDadosPerfilTributario,
      fetchData: obterAtualizacaoPerfilTributario,
    } = usePeco<
      REQUEST_TYPES.IAlterarPerfilTributarioPayload,
      RESPONSE_TYPES.IAlterarPerfilTributarioResponse
    >({
      api: { operationPath: PECOS.SolicitarAlteracaoPerfilTributario },
      payload: {
        UserName: user?.nomeAcesso,
        numeroCertificado: cliente?.numCertificado,
        agencia: REGIME_TRIBUTARIO_TYPES.ResponsePerfilTributario.AGENCIA,
      },
    });

    return {
      dadosPerfilTributario,
      loadingDadosPerfilTributario,
      obterAtualizacaoPerfilTributario,
    };
  };
