import { useToast } from 'main/hooks/useToast';
import { IApiResponse } from 'main/services';
import {
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { useObterComprovanteOperacao } from 'previdencia/hooks/useObterComprovanteOperacao';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { StatusResgate } from 'previdencia/types/StatusResgate';
import { ISolicitacaoResgateContextData } from '../types/ISolicitacaoResgateContextData';
import { ResponseConfirmarResgate } from '../types/ResponseConfirmarResgate';

export const useHandleReponseResgate = () => {
  const { setFeatureData, featureData } =
    usePrevidenciaContext<ISolicitacaoResgateContextData>();
  const { toastError, toastSuccess } = useToast();
  const { navigateTo } = usePrevNavigation();
  const { gerarComprovante } = useObterComprovanteOperacao();

  const handleResponseResgate = (
    responseConfirmarResgate: IApiResponse<
      ResponseConfirmarResgate | undefined
    >,
  ) => {
    const retornoAbreviado = responseConfirmarResgate?.dados?.entidade;
    if (responseConfirmarResgate && retornoAbreviado) {
      setFeatureData({
        ...featureData,
        solicitarAssinaturaEletronica:
          retornoAbreviado?.status === StatusResgate.AGUARDANDO_ASSINATURA,
        statusResgate: retornoAbreviado.status,
        motivoPendenciaResgate: retornoAbreviado.dadosResultados?.observacao,
      });
    }
    const [mensagemSucesso] = tryGetValueOrDefault(
      [responseConfirmarResgate?.dados?.mensagens],
      [],
    );
    return {
      sucesso: !!responseConfirmarResgate,
      status: tryGetValueOrDefault([retornoAbreviado?.status], ''),
      mensagemSucesso: tryGetValueOrDefault([mensagemSucesso.descricao], ''),
    };
  };

  const exibirMensagensFeedbackResgate = async (
    sucesso: boolean,
    status: string,
    mensagemSucesso?: string,
  ) => {
    const exibirComprovante = checkIfSomeItemsAreTrue([
      status === StatusResgate.SOLICITADO,
      status === StatusResgate.VALIDADO,
    ]);
    const exibirToastSucesso = checkIfSomeItemsAreTrue([
      status === StatusResgate.SOLICITADO,
      status === StatusResgate.VALIDADO,
    ]);
    navigateTo('efetuar-resgate/sucesso', {
      resgateId: featureData?.codigoResgate,
    });
    if (exibirComprovante)
      await gerarComprovante(
        tryGetValueOrDefault([featureData?.codigoResgate], ''),
        'RESGATE',
      );
    if (sucesso && exibirToastSucesso) toastSuccess(mensagemSucesso);
    if (sucesso && status === StatusResgate.AGUARDANDO_ASSINATURA)
      toastSuccess('Solicitação de resgate recebida com sucesso.');
    if (!sucesso) toastError();
  };

  return {
    handleResponseResgate,
    exibirMensagensFeedbackResgate,
  };
};
