import React from 'react';
import { Text, Divider } from '@cvp/design-system/react';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { InformacoesRendaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import { validarPrimeiraStr } from 'previdencia/features/SimulacaoRenda/utils/validarPrimeiraStr';
import * as S from 'previdencia/features/SimulacaoRenda/components/InformacoesRenda/styles';

const InformacoesRenda: React.FC<InformacoesRendaProps> = ({ dados }) => {
  return (
    <S.ContainerInformacoesRenda>
      <S.RendaLiquida>
        <Text variant="body02-sm">Renda mensal líquida de:</Text>
        <Text variant="headline-08">
          <strong>
            {tryGetMonetaryValueOrDefault(dados?.vlrBeneficioLiquido)}
          </strong>
        </Text>
        <Text variant="caption-01" margin>
          {validarPrimeiraStr(dados?.descPeridoBeneficiarioRecebe, 'por ')}
        </Text>
      </S.RendaLiquida>

      <div>
        <Text variant="caption-01">Renda mensal bruta de:</Text>
        <Text variant="caption-01">
          <strong>{tryGetMonetaryValueOrDefault(dados?.vlrBeneficio)}</strong>
        </Text>
        <Divider />
        <Text variant="caption-01">Desconto do Imposto de Renda:</Text>
        <Text variant="caption-01">
          <strong>{tryGetMonetaryValueOrDefault(dados?.vlrIRRF)}</strong>
        </Text>
      </div>
    </S.ContainerInformacoesRenda>
  );
};

export default InformacoesRenda;
