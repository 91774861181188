import { IResponseSolicitarAssinatura } from 'main/components/AssinaturaDocuSign/IResponseSolicitarAssinatura';
import { api, IApiResponse } from 'main/services';
import {
  obterEndpointValidarNecessidadeAssinaturaEletronica,
  obterEndpointSolicitarAssinaturaResgatePrevidencia,
} from 'previdencia/config/endpoints';
import { IRequestSolicitarAssinaturaEletronica } from '../types/IRequestSolicitarAssinaturaEletronica';
import { IRequestVerificarAssinatura } from '../types/IRequestVerificarAssinatura';
import { IResponseVerificarAssinatura } from '../types/IResponseVerificarAssinatura';

export const verificarNecessidadeAssinatura = async (
  input: IRequestVerificarAssinatura,
): Promise<IApiResponse<IResponseVerificarAssinatura>> => {
  const { data } = await api.post<IApiResponse<IResponseVerificarAssinatura>>(
    obterEndpointValidarNecessidadeAssinaturaEletronica(),
    input,
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data;
};

export const solicitarAssinaturaEletronica = async (
  input: Partial<IRequestSolicitarAssinaturaEletronica>,
): Promise<IApiResponse<IResponseSolicitarAssinatura>> => {
  const { data } = await api.post<IApiResponse<IResponseSolicitarAssinatura>>(
    obterEndpointSolicitarAssinaturaResgatePrevidencia(),
    input,
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data;
};
