/* eslint-disable @typescript-eslint/no-explicit-any */
import { Radio } from '@cvp/design-system/react';

import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { isNegativeCurrent } from 'main/utils/string';
import { createColumn } from 'previdencia/features/AlteracaoBeneficiarios/utils/criarColunaTabela';
import {
  CellTypeRentabilidadeCotas,
  TabelaFundosType,
} from 'previdencia/types/Tabelas';
import {
  obterCodigoOperacaoENumeroConta,
  ordenaDataRealizado,
  ordenaValor,
} from 'previdencia/utils/previdencia';
import * as ALTERACAO_CONTA_TYPES from 'previdencia/features/AlteracaoFormaDadosPagamento/types/AlteracaoFormaDadosPagamento';
import * as ALTERACAO_CONTA_UTILS from 'previdencia/features/AlteracaoFormaDadosPagamento/utils/alteracaoFormaDadosPagamento';

export const COLUNAS_TABELA_FUNDOS = [
  {
    wrap: true,
    selector: 'check',
    width: '50px',
    name: '',
  },
  {
    selector: 'descFundo',
    name: 'Fundo',
    minWidth: '250px',
    cell: ({ descFundo }: TabelaFundosType) => (
      <p title={descFundo}>{descFundo}</p>
    ),
  },
  {
    name: 'Perfil do Risco',
    minWidth: '180px',
    selector: 'perfil',
  },
  {
    name: 'Rentabilidade (últimos 12 meses)',
    minWidth: '140px',
    selector: 'rentabilidade',
  },
  {
    selector: 'saldo',
    minWidth: '140px',
    name: 'Saldo',
  },
];

export const COLUNAS_TABELA_EXTRATO_RENTABILIDADE_COTAS = [
  {
    name: 'Data Extrato',
    selector: 'dataExtrato',
    minWidth: '135px',
  },
  {
    selector: 'dataMotivo',
    name: 'Data Movimentação',
    minWidth: '130px',
  },
  {
    selector: 'nomeMotivo',
    name: 'Tipo Movimentação',
    minWidth: '200px',
    cell: ({ nomeMotivo }: CellTypeRentabilidadeCotas) => (
      <p title={nomeMotivo}>{nomeMotivo}</p>
    ),
  },
  {
    name: 'Valor Movimentação',
    selector: 'valorMotivo',
    minWidth: '160px',
    conditionalCellStyles: [
      {
        when: (row: any) => isNegativeCurrent(row.valorMotivo),
        style: {
          color: 'red',
        },
      },
    ],
  },
  {
    name: 'Data Cota Movimentação',
    selector: 'dataCota',
    minWidth: '130px',
    style: {
      backgroundColor: 'rgba(239, 239, 239, 1)',
    },
  },
  {
    name: 'Valor Cota no Dia *',
    selector: 'valorCota',
    minWidth: '180px',
    style: {
      backgroundColor: 'rgba(239, 239, 239, 1)',
    },
  },
  {
    name: 'Quantidade Total de Cotas',
    selector: 'valorTotalCota',
    minWidth: '180px',
    style: {
      backgroundColor: 'rgba(239, 239, 239, 1)',
    },
    conditionalCellStyles: [
      {
        when: (row: any) => isNegativeCurrent(row.valorTotalCota),
        style: {
          color: 'red',
        },
      },
    ],
  },
  {
    name: 'Reserva Total',
    selector: 'valorSaldo',
    minWidth: '140px',
    conditionalCellStyles: [
      {
        when: (row: any) => isNegativeCurrent(row.Reserva),
        style: {
          color: 'red',
        },
      },
    ],
  },
  {
    name: 'Rentabilidade',
    selector: 'valorRentabilidade',
    minWidth: '180px',
    conditionalCellStyles: [
      {
        when: (row: any) => isNegativeCurrent(row.valorRentabilidade),
        style: {
          color: 'red',
        },
      },
    ],
  },
  {
    name: 'Cód fundo **',
    selector: 'codigoFundo',
    minWidth: '90px',
    style: {
      backgroundColor: 'rgba(239, 239, 239, 1)',
    },
  },
];

export const COLUNAS_TABELA_RESERVA = [
  {
    id: '1',
    name: 'Beneficiário',
    selector: 'beneficiario',
    minWidth: '400px',
  },
  {
    id: '2',
    name: 'Parentesco',
    selector: 'parentesco',
  },
  {
    id: '3',
    name: 'Data Nascimento',
    selector: 'dataNascimento',
  },
  {
    id: '4',
    name: 'Percentual',
    selector: 'percentual',
  },
  {
    id: '5',
    name: 'Remover',
    selector: 'remover',
  },
];

export const COLUNAS_TABELA_RESERVA_IMPRIMIR = [
  {
    id: '1',
    name: 'Beneficiário',
    selector: 'beneficiario',
    minWidth: '260px',
  },
  {
    id: '2',
    name: 'Parentesco',
    selector: 'parentesco',
    maxWidth: '40px',
  },
  {
    id: '3',
    name: 'Dt Nascimento',
    selector: 'dataNascimento',
    maxWidth: '130px',
  },
  {
    id: '4',
    name: 'Percentual',
    selector: 'percentual',
  },
];

export const COLUNAS_TABELA_ALTERACAO_BENEFICIARIOS = [
  {
    id: '1',
    name: 'Beneficiário',
    selector: 'beneficiario',
    minWidth: '500px',
  },
  {
    id: '2',
    name: 'Data Nascimento',
    selector: 'dataNascimento',
  },
  createColumn('3', 'Grau Parentesco', 'grauParentesco'),
  createColumn('4', 'Coberturas', 'coberturas'),
];

export const COLUNAS_TABELA_ALTERACAO_BENEFICIARIOS_IMPRIMIR = [
  {
    id: '1',
    name: 'Beneficiário',
    selector: 'beneficiario',
    minWidth: '280px',
  },
  {
    id: '2',
    name: 'Dt Nascimento',
    selector: 'dataNascimento',
    maxWidth: '130px',
  },
  {
    id: '3',
    name: 'Parentesco',
    selector: 'grauParentesco',
    maxWidth: '40px',
  },
  {
    id: '4',
    name: 'Coberturas',
    selector: 'coberturas',
    maxWidth: '75px',
  },
];

export const COLUNAS_TABELA_TIPO_RESERVA = [
  {
    name: 'Tipo de atualização',
    selector: 'desTipoAtualizacao',
    minWidth: '320px',
  },
  {
    name: 'Data',
    selector: 'dthRecal',
  },
  {
    name: 'Valor anterior',
    selector: 'vlrAnterior',
  },
  {
    name: 'Atualização*',
    selector: 'pctAtualizacaoCodIndice',
  },
  {
    name: 'Valor final',
    selector: 'vlrFinal',
  },
];

export const COLUNAS_TABELA_TIPO_CUIDADO_EXTRA = [
  {
    name: 'Tipo de atualização',
    selector: 'desTipoAtualizacao',
    minWidth: '360px',
  },
  {
    name: 'Data',
    selector: 'dthRecal',
  },
  {
    name: 'Cuidado Extra',
    selector: 'desPagamento',
    minWidth: '250px',
  },
  {
    name: 'Valor anterior',
    selector: 'vlrAnterior',
  },
  {
    name: 'Atualização*',
    selector: 'pctAtualizacaoCodIndice',
  },
  {
    name: 'Valor final',
    selector: 'vlrFinal',
  },
];

export const COLUNAS_HISTORICO_SOLICITACOES = [
  {
    name: 'Serviço',
    selector: 'servico',
    wrap: true,
    sortable: true,
  },
  {
    name: 'Realizado',
    selector: 'realizado',
    sortable: true,
    minWidth: '140px',
    maxWidth: '140px',
    sortFunction: ordenaDataRealizado,
  },
  {
    name: 'Canal',
    selector: 'canal',
    sortable: true,
    minWidth: '140px',
    maxWidth: '200px',
  },
  {
    name: 'Status',
    selector: 'status',
    wrap: true,
    sortable: true,
    minWidth: '140px',
    maxWidth: '180px',
  },
  {
    name: 'Valor',
    selector: 'valor',
    minWidth: '140px',
    sortable: true,
    sortFunction: ordenaValor,
  },
  {
    name: 'Comprovante',
    selector: 'comprovante',
    minWidth: '180px',
    maxWidth: '180px',
  },
];

export const COLUNAS_INFORMES_BENEFICIARIOS = [
  {
    name: 'Nome do Beneficiário',
    selector: 'nomeBeneficiario',
    minWidth: '500px',
  },
  {
    name: 'CPF',
    selector: 'cpf',
    minWidth: '100px',
  },
  {
    name: 'Informe de Rendimentos',
    selector: 'informe',
    minWidth: '350px',
  },
];

export const COLUNAS_TABELA_CONTAS_BANCARIAS = ({
  canal,
  obterContaCadastradaSelecionada,
}: ALTERACAO_CONTA_TYPES.IColunasTabelaContasBancariasParams) => [
  {
    name: '',
    selector: 'marcarBanco',
    wrap: true,
    width: '50px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) => (
      <Radio
        data-testid="btnEmail"
        onChange={() => {
          obterContaCadastradaSelecionada(row);
        }}
        checked={checkIfAllItemsAreTrue([
          !canal.isDisabled,
          canal.canal === row.idCanalRecuperado,
        ])}
        value="EMAIL"
        name="selectedMethod"
        disabled={canal.isDisabled}
      />
    ),
  },
  {
    name: 'Banco',
    selector: 'numeroBanco',
    minWidth: '140px',
  },
  {
    name: 'Agência',
    selector: 'numeroAgencia',
    minWidth: '140px',
  },
  {
    name: 'Operação',
    selector: 'operacao',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      obterCodigoOperacaoENumeroConta(row.numeroConta).operacao,
  },
  {
    name: 'Conta',
    selector: 'conta',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      `${obterCodigoOperacaoENumeroConta(row.numeroConta).numeroConta}-${
        row.digitoConta
      }`,
  },
];

export const COLUNAS_TABELA_DADOS_BANCARIOS_CONFIRMACAO = (
  certificado?: string,
) => [
  {
    name: 'CERTIFICADO',
    selector: 'certificado',
    wrap: true,
    minWidth: '140px',
    cell: () => certificado,
  },
  {
    name: 'FORMA DE PAGAMENTO',
    selector: 'formaPagamento',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      ALTERACAO_CONTA_UTILS.buscarMetodoPagamento(row.metodoPagamento),
  },
  {
    name: 'BANCO',
    selector: 'banco',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      row.numeroBanco,
  },
  {
    name: 'AGÊNCIA',
    selector: 'agencia',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      row.numeroAgencia,
  },
  {
    name: 'CONTA',
    selector: 'conta',
    minWidth: '140px',
    cell: (row: ALTERACAO_CONTA_TYPES.ListaTiposContasBancarias) =>
      `${
        obterCodigoOperacaoENumeroConta(
          tryGetValueOrDefault([row.numeroConta], ''),
        ).numeroConta
      }-${row.digitoConta}`,
  },
];

export const COLUNAS_TABELA_DADOS_ANTIGO_BANCARIOS_CONFIRMACAO = (
  certificado?: string,
) => [
  {
    name: 'CERTIFICADO',
    selector: 'certificadoAntigo',
    wrap: true,
    minWidth: '140px',
    cell: () => certificado,
  },
  {
    name: 'FORMA DE PAGAMENTO',
    selector: 'formaPagamentoAntigo',
    minWidth: '140px',
  },
  {
    name: 'BANCO',
    selector: 'bancoAntigo',
    minWidth: '140px',
  },
  {
    name: 'AGÊNCIA',
    selector: 'agenciaAntiga',
    minWidth: '140px',
  },
  {
    name: 'CONTA',
    selector: 'contaAntiga',
    minWidth: '140px',
  },
];

export const prevQueryCacheDuration = (duration = 60): number =>
  Number(process.env.REACT_APP_CACHE_DURATION) * duration;

export const ASSINATURA_BENEFICIO_PROTECAO = {
  CODIGO_EMPRESA: '500',
  TIPO_ASSINATURA: 'OFERTA_RISCO',
  TIPO_ENVIO: 'SMS',
};
