import React, { ChangeEvent, useCallback, useContext, useEffect } from 'react';
import {
  Display,
  Grid,
  Text,
  Card,
  Radio,
  Disclaimer,
} from '@cvp/design-system/react';
import Table from 'main/components/Table';
import FooterEfetuarResgate from 'previdencia/features/SolicitacaoResgate/components/FooterEfetuarResgate';
import ButtonAction from 'previdencia/features/SolicitacaoResgate/components/ButtonAction';
import { useHistory, useLocation } from 'react-router';
import SkeletonLoading from 'main/components/SkeletonLoading';
import useContaBancaria from 'previdencia/features/SolicitacaoResgate/hooks/useContaBancaria';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { ParamsLocationData } from 'previdencia/features/SolicitacaoResgate/types/ParamsLocationData';
import useObterSolicitacaoResgate from 'previdencia/features/SolicitacaoResgate/hooks/useObterSolicitacaoResgate';
import { colunsTableAccountBank } from 'previdencia/features/SolicitacaoResgate/constants/colunsTableAccountBank';
import useObterConsultaResumoAliquotas from 'previdencia/features/SolicitacaoResgate/hooks/useObterConsultaResumoAliquotas';
import useObterConsultaDetalheCalculo from 'previdencia/features/SolicitacaoResgate/hooks/useObterConsultaDetalheCalculo';
import { AppContext } from 'main/contexts/AppContext';
import * as S from 'previdencia/features/SolicitacaoResgate/pages/styles';
import { DadosBancarios } from 'previdencia/features/SolicitacaoResgate/types/DadosBancarios';
import { TipoContaBancaria } from 'previdencia/features/SolicitacaoResgate/types/TipoContaBancaria';
import { efetuarSolicitacaoResgateLabels } from 'previdencia/features/SolicitacaoResgate/constants/constants';
import { MotivoResgate } from 'previdencia/features/SolicitacaoResgate/components/MotivoResgate';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ISolicitacaoResgateContextData } from '../types/ISolicitacaoResgateContextData';
import { StatusResgate } from 'previdencia/types/StatusResgate';
import {
  PrevRedirectConfig,
  PREV_REDIRECTS_SCREENS,
} from 'previdencia/config/redirects';

const EfetuarSolicitacaoResgate: React.FC = () => {
  const history = useHistory();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const { featureData } =
    usePrevidenciaContext<ISolicitacaoResgateContextData>();

  const location = useLocation<ParamsLocationData>();
  const { dadosResgate, resgateId, beneficioId, planoId, saldo } =
    location.state;

  const { data: resumoAliquotas, isLoading: isLoadingConsultaResumoAliquota } =
    useObterConsultaResumoAliquotas(cpfCnpj, numCertificado, resgateId);

  const {
    data: consultaDetalheCalculo,
    isLoading: isLoadingConsultaDetalheCalculo,
  } = useObterConsultaDetalheCalculo(cpfCnpj, numCertificado, resgateId);
  const {
    isLoadingDadosBancarios,
    instituicoesBancarias,
    contasBancarias,
    obterTiposContasBancarias,
    alternarExibicaoFormularioDadosBancarios,
    exibeFormularioDadosBancarios,
  } = useContaBancaria(cpfCnpj, resgateId);
  const {
    contaBancariaSelecionada,
    solicitarResgate,
    executandoResgate,
    mensagemErro,
    handleSelectContaBancaria,
    motivo,
    setMotivo,
  } = useObterSolicitacaoResgate(
    cpfCnpj,
    numCertificado,
    beneficioId,
    planoId,
    resgateId,
    dadosResgate,
  );

  const goHome = useCallback(() => {
    history.replace(`/cliente/produtos/previdencia/solicitacao-resgate`);
  }, [history]);

  const selecionarOpcaoContaBancaria = (
    dadosBancarios: DadosBancarios | null,
    opcaoContaNova: boolean,
  ) => {
    handleSelectContaBancaria(dadosBancarios);
    alternarExibicaoFormularioDadosBancarios(opcaoContaNova);
  };

  const obterTipoContaBancariaPorCodigo = (
    numeroBanco: string,
    codigoTipoConta: string,
  ): TipoContaBancaria | null => {
    let retorno = null;
    obterTiposContasBancarias(numeroBanco).forEach(tipoContaBancaria => {
      if (tipoContaBancaria.codigo === codigoTipoConta) {
        retorno = tipoContaBancaria;
      }
    });
    return retorno;
  };

  const configurarColunaTipoConta = (
    numeroBanco: string,
    codigoTipoConta: string,
  ): string => {
    let numeroCodigoTipoConta = codigoTipoConta;
    if (codigoTipoConta === 'CC') numeroCodigoTipoConta = '001';
    if (codigoTipoConta === 'CP') numeroCodigoTipoConta = '013';

    const tipoConta = obterTipoContaBancariaPorCodigo(
      numeroBanco,
      numeroCodigoTipoConta,
    );
    return tipoConta?.descricao ?? '-';
  };

  const configurarColunaConta = (item: DadosBancarios): string => {
    if (checkIfAllItemsAreTrue([!!item.numeroConta, !!item.digitoConta])) {
      return `${item.numeroConta}-${item.digitoConta}`;
    }

    return '-';
  };

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        !!featureData?.codigoResgate,
        featureData?.statusResgate === StatusResgate.AGUARDANDO_ASSINATURA,
      ])
    ) {
      history.push(
        PrevRedirectConfig[
          PREV_REDIRECTS_SCREENS.HISTORICO_CANCELAMENTO_RESGATE
        ],
      );
    }
  }, []);

  const renderTable = () => {
    return (
      contasBancarias?.map(item => ({
        ...item,
        check: (
          <Radio
            disabled={
              !checkIfAllItemsAreTrue([
                !!item.nomeBanco,
                !!item.numeroAgencia,
                !!item.numeroConta,
                !!item.digitoConta,
              ])
            }
            name="radio-conta"
            value={JSON.stringify(item)}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              selecionarOpcaoContaBancaria(JSON.parse(e.target.value), false)
            }
            checked={contaBancariaSelecionada?.numeroConta === item.numeroConta}
          />
        ),
        nomeBanco: tryGetValueOrDefault([item.nomeBanco], '-'),
        numeroAgencia: tryGetValueOrDefault([item.numeroAgencia], '-'),
        codContaBanco: configurarColunaConta(item),
        tipoContaBanco: configurarColunaTipoConta(
          item.numeroBanco,
          item.tipoContaBanco,
        ),
      })) ?? []
    );
  };

  if (
    checkIfSomeItemsAreTrue([
      isLoadingDadosBancarios,
      isLoadingConsultaDetalheCalculo,
      isLoadingConsultaResumoAliquota,
    ])
  ) {
    return <SkeletonLoading blocks={2} />;
  }

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-06" color="primary" margin>
                {efetuarSolicitacaoResgateLabels.SOLICITACAO_RESGATE}
              </Text>
            </Grid.Item>
            <Grid.Item xs={1}>
              <S.WrapperSectionEfetuarResgate>
                <S.TextInfoEfetuarResgate>
                  {efetuarSolicitacaoResgateLabels.SALDO_PLANO}
                </S.TextInfoEfetuarResgate>
                <Text variant="body02-md" color="text-light">
                  {formatarValorPadraoBrasileiro(saldo)}
                </Text>
              </S.WrapperSectionEfetuarResgate>

              <S.WrapperSectionEfetuarResgate>
                <S.TextInfoEfetuarResgate>
                  {efetuarSolicitacaoResgateLabels.VALOR_SOLICITADO}
                </S.TextInfoEfetuarResgate>
                <Text variant="body02-md" color="text-light">
                  {formatarValorPadraoBrasileiro(
                    consultaDetalheCalculo?.totalValorSolicitado,
                  )}
                </Text>
              </S.WrapperSectionEfetuarResgate>

              <S.WrapperSectionEfetuarResgate>
                <S.TextInfoEfetuarResgate>
                  {efetuarSolicitacaoResgateLabels.VALOR_NOMINAL_ESTIMADO}
                </S.TextInfoEfetuarResgate>
                <Text variant="body02-md" color="text-light">
                  {formatarValorPadraoBrasileiro(
                    resumoAliquotas?.totalSaldoNominal,
                  )}
                </Text>
              </S.WrapperSectionEfetuarResgate>

              <S.WrapperSectionEfetuarResgate>
                <S.TextInfoEfetuarResgate>
                  {efetuarSolicitacaoResgateLabels.VALOR_IR_ESTIMADO}
                </S.TextInfoEfetuarResgate>
                <Text variant="body02-md" color="text-light">
                  {formatarValorPadraoBrasileiro(
                    consultaDetalheCalculo?.totalValorIrrf,
                  )}
                </Text>
              </S.WrapperSectionEfetuarResgate>

              <S.WrapperSectionEfetuarResgate>
                <S.TextInfoEfetuarResgate>
                  {
                    efetuarSolicitacaoResgateLabels.TAXA_CARREGAMENTO_SAIDA_ESTIMADA
                  }
                </S.TextInfoEfetuarResgate>
                <Text variant="body02-md" color="text-light">
                  {formatarValorPadraoBrasileiro(
                    consultaDetalheCalculo?.totalCarregamentoSaida,
                  )}
                </Text>
              </S.WrapperSectionEfetuarResgate>

              <S.WrapperSectionEfetuarResgate>
                <S.TextInfoEfetuarResgate>
                  {efetuarSolicitacaoResgateLabels.VALOR_LIQUIDO_ESTIMADO}
                </S.TextInfoEfetuarResgate>
                <Text variant="body02-md" color="text-light">
                  {formatarValorPadraoBrasileiro(
                    consultaDetalheCalculo?.totalValorLiquido,
                  )}
                </Text>
              </S.WrapperSectionEfetuarResgate>
            </Grid.Item>

            <Grid.Item xs={1}>
              <Text variant="body01-lg" color="primary" margin>
                {efetuarSolicitacaoResgateLabels.INFORMACOES_BANCARIAS}
              </Text>

              <Text variant="body02-md" color="text-light" margin>
                {efetuarSolicitacaoResgateLabels.SELECIONE_CONTA}
              </Text>
              <Table
                data={renderTable()}
                columns={colunsTableAccountBank}
                noHeader
                noDataComponent="Sem dados para exibir"
              />
            </Grid.Item>

            <Grid.Item xs={1}>
              <S.WrapperCheckBox>
                <Radio
                  name="group"
                  onChange={() => selecionarOpcaoContaBancaria(null, true)}
                  value="outraConta"
                  checked={exibeFormularioDadosBancarios}
                />
                <S.TextCheckBox>
                  {
                    efetuarSolicitacaoResgateLabels.CLIQUE_PARA_INDICAR_OUTRA_CONTA
                  }
                </S.TextCheckBox>
              </S.WrapperCheckBox>
            </Grid.Item>
          </Grid>

          <RenderConditional
            condition={exibeFormularioDadosBancarios}
            component={
              <FooterEfetuarResgate
                listaInstituicoesBancarias={instituicoesBancarias}
                handleObterTiposContasBancarias={obterTiposContasBancarias}
                handleSelecionarNovaContaBancaria={handleSelectContaBancaria}
              />
            }
          />

          <MotivoResgate motivo={motivo} setMotivo={setMotivo} />

          <RenderConditional
            condition={!!mensagemErro}
            component={
              <Grid>
                <Grid.Item xs={1}>
                  <Disclaimer variant="error">
                    <Disclaimer.Content text={mensagemErro} />
                  </Disclaimer>
                </Grid.Item>
              </Grid>
            }
          />

          <Grid>
            <Grid.Item xs={1}>
              <ButtonAction
                titleButtonLeft="Cancelar"
                titleButtonRight="Confirmar"
                loading={executandoResgate}
                onClickButtonRight={() => solicitarResgate()}
                onClickButtonLeft={() => goHome()}
                disabled={checkIfSomeItemsAreTrue([
                  !motivo,
                  !contaBancariaSelecionada,
                  executandoResgate,
                ])}
              />
            </Grid.Item>
          </Grid>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default EfetuarSolicitacaoResgate;
