import React from 'react';
import { Display, Card, Text, Grid } from '@cvp/design-system/react';
import MessageAlertResgate from 'previdencia/features/SolicitacaoResgate/components/MessageAlertResgate';
import { MessageConsultasProgramadasLabels } from '../../constants/constants';

type MessageConsultasProgramadasProps = {
  text: string;
};

const MessageConsultasProgramadas: React.FC<
  MessageConsultasProgramadasProps
> = ({ text }) => {
  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-06" color="primary" margin>
                {
                  MessageConsultasProgramadasLabels.SIMULACAO_SOLICITACAO_RESGATE
                }
              </Text>
              <MessageAlertResgate message={text} />
            </Grid.Item>
          </Grid>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default MessageConsultasProgramadas;
