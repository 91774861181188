import React, { useState, useContext } from 'react';
import {
  Card,
  Grid,
  Text,
  Display,
  Button,
  Divider,
} from '@cvp/design-system/react';
import { useHistory, useLocation } from 'react-router';
import ModalSimulacaoDetalhada from 'previdencia/features/SolicitacaoResgate/components/ModalSimulacaoDetalhada';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { AppContext } from 'main/contexts/AppContext';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { ParamsLocationData } from 'previdencia/features/SolicitacaoResgate/types/ParamsLocationData';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { formatarData } from 'main/utils';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import useObterConsultaResumoAliquotas from 'previdencia/features/SolicitacaoResgate/hooks/useObterConsultaResumoAliquotas';
import useObterConsultaDetalheCalculo from 'previdencia/features/SolicitacaoResgate/hooks/useObterConsultaDetalheCalculo';
import RenderConditional from 'main/components/RenderConditional';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import * as S from './styles';
import { confirmaValoresLabels } from '../constants/constants';
import { ISolicitacaoResgateContextData } from '../types/ISolicitacaoResgateContextData';

const ConfirmarValores: React.FC = () => {
  const history = useHistory();
  const { navigateTo } = usePrevNavigation();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const location = useLocation<ParamsLocationData>();
  const {
    valorBrutoRetirada,
    resgateId,
    beneficioId,
    dadosResgate,
    planoId,
    saldo,
  } = location.state;

  const simulacaoRetirada = location.state?.simulacaoRetirada;

  const { data: resumoAliquotas, isLoading: isLoadingConsultaResumoAliquota } =
    useObterConsultaResumoAliquotas(cpfCnpj, numCertificado, resgateId);

  const {
    data: consultaDetalheCalculo,
    isLoading: isLoadingConsultaDetalheCalculo,
  } = useObterConsultaDetalheCalculo(cpfCnpj, numCertificado, resgateId);

  const [exibirValoresDetalhados, setExibirValoresDetalhados] = useState(false);
  const { featureData } =
    usePrevidenciaContext<ISolicitacaoResgateContextData>();
  const nextRouter = () => {
    navigateTo('solicitacao-resgate-info', {
      resgateId,
      valorBrutoRetirada,
      beneficioId,
      dadosResgate,
      planoId,
      saldo,
    });
  };

  const closeExibirValoresDetalhados = () => {
    setExibirValoresDetalhados(false);
  };

  if (isLoadingConsultaDetalheCalculo || isLoadingConsultaResumoAliquota) {
    return <SkeletonLoading blocks={2} />;
  }

  return (
    <>
      <Display type="display-block">
        <PrevidenciaResumo />
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text variant="headline-06" color="primary" margin>
                  {confirmaValoresLabels.TITULO}
                </Text>
                <Text variant="body01-md" color="text-light" margin>
                  {confirmaValoresLabels.SUB_TITULO}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <S.WrapperValorSimulado>
                  <S.TextValorSimulcao>
                    Saldo em {formatarData(new Date())}:
                  </S.TextValorSimulcao>
                  <Text variant="body01-md" color="text-light">
                    {formatarValorPadraoBrasileiro(saldo)}
                  </Text>
                </S.WrapperValorSimulado>

                <S.WrapperValorSimulado>
                  <S.TextValorSimulcao>
                    {confirmaValoresLabels.VALOR_SIMULADO}
                  </S.TextValorSimulcao>
                  <Text variant="body01-md" color="text-light">
                    {formatarValorPadraoBrasileiro(
                      consultaDetalheCalculo?.totalValorSolicitado,
                    )}
                  </Text>
                </S.WrapperValorSimulado>

                <S.WrapperValorSimulado>
                  <S.TextValorSimulcao>
                    {confirmaValoresLabels.VALOR_NOMIMAL_ESTIMADO}
                  </S.TextValorSimulcao>
                  <Text variant="body01-md" color="text-light">
                    {formatarValorPadraoBrasileiro(
                      simulacaoRetirada?.detalhesResgate[0].valorNominal,
                    )}
                  </Text>
                </S.WrapperValorSimulado>

                <S.WrapperValorSimulado>
                  <S.TextValorSimulcao>
                    {confirmaValoresLabels.VALOR_IR_ESTIMADO}
                  </S.TextValorSimulcao>
                  <Text variant="body01-md" color="text-light">
                    {formatarValorPadraoBrasileiro(
                      resumoAliquotas?.totalValorIrrf,
                    )}
                  </Text>
                </S.WrapperValorSimulado>

                <S.WrapperValorSimulado>
                  <S.TextValorSimulcao>
                    {confirmaValoresLabels.TAXA_CARREGAMENTO_SAIDA_ESTIMADA}
                  </S.TextValorSimulcao>
                  <Text variant="body01-md" color="text-light">
                    {formatarValorPadraoBrasileiro(
                      consultaDetalheCalculo?.totalCarregamentoSaida,
                    )}
                  </Text>
                </S.WrapperValorSimulado>

                <S.WrapperValorSimulado>
                  <S.TextValorSimulcao>
                    {confirmaValoresLabels.VALOR_LIQUIDO_ESTIMADO}
                  </S.TextValorSimulcao>
                  <Text variant="body01-md" color="text-light">
                    {formatarValorPadraoBrasileiro(
                      consultaDetalheCalculo?.totalValorLiquido,
                    )}
                  </Text>
                </S.WrapperValorSimulado>

                <S.WrapperValoreDetalhadoButton>
                  <Button
                    variant="secondary"
                    onClick={() => setExibirValoresDetalhados(true)}
                  >
                    {confirmaValoresLabels.VALORES_DETALHADOS}
                  </Button>
                </S.WrapperValoreDetalhadoButton>

                <RenderConditional condition={!!featureData?.dataCarencia}>
                  <Display style={{ marginTop: 50 }}>
                    <Text variant="body01-md" color="error">
                      Certificado em carência para resgate até{' '}
                      {formatarData(featureData?.dataCarencia)}.
                    </Text>
                  </Display>
                </RenderConditional>
              </Grid.Item>
              <RenderConditional condition={!!featureData?.resgatePermitido}>
                <Grid.Item xs={1}>
                  <Divider />
                  <Display>
                    <Button variant="outlined" onClick={() => history.goBack()}>
                      Nova simulação
                    </Button>
                    <Button
                      onClick={() => nextRouter()}
                      disabled={checkIfSomeItemsAreTrue([
                        !!featureData?.dataCarencia,
                        !!featureData?.prazoDiferimentoExpirado,
                      ])}
                    >
                      {confirmaValoresLabels.REALIZAR_RESGATE}
                    </Button>
                  </Display>
                </Grid.Item>
              </RenderConditional>
              <RenderConditional condition={!featureData?.resgatePermitido}>
                <Grid.Item xs={1}>
                  <Divider />
                  <Text variant="body03-md" color="error">
                    Resgate não permitido.
                  </Text>
                </Grid.Item>
              </RenderConditional>

              <ModalSimulacaoDetalhada
                resumoAliquota={resumoAliquotas}
                saldo={saldo}
                consultaDetalheCalculo={consultaDetalheCalculo}
                valorBruto={valorBrutoRetirada}
                open={exibirValoresDetalhados}
                handleClose={closeExibirValoresDetalhados}
                resgateId={resgateId}
              />
            </Grid>
          </Card.Content>
        </Card>
      </Display>
    </>
  );
};

export default ConfirmarValores;
