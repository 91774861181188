import { api, IApiResponse } from 'main/services';
import { obterEndpointConsultarResumoAliquota } from 'previdencia/config/endpoints';
import { ResponseConsultarResumoAliquota } from 'previdencia/features/SolicitacaoResgate/types/ResponseConsultarResumoAliquota';

export const obterConsultaResumoAliquota = async (
  cpfCnpj: string,
  numeroCertificado: string,
  numResgate: string,
  codopera = '4',
): Promise<ResponseConsultarResumoAliquota | undefined> => {
  const { data } = await api.post<
    IApiResponse<ResponseConsultarResumoAliquota>
  >(obterEndpointConsultarResumoAliquota(), {
    cpfCnpj,
    numResgate,
    numeroCertificado,
    codopera,
  });

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data?.dados?.entidade;
};
