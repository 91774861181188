import { useToast } from 'main/hooks/useToast';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { useQuery, UseQueryResult } from 'react-query';
import * as SimularRetiradaApi from 'previdencia/features/SolicitacaoResgate/services/simularRetirada.api';
import { ResponseSimularRetirada } from 'previdencia/features/SolicitacaoResgate/types/ResponseSimularRetirada';
import { DadosResgate } from 'previdencia/features/SolicitacaoResgate/types/ResponseListaFundosReserva';

const useObterSimulacaoRetirada = (
  cpf: string,
  numCertificado: string,
  beneficioId: string,
  planoId: string,
  dadosResgate: DadosResgate[],
): UseQueryResult<ResponseSimularRetirada | undefined> => {
  const { toastError } = useToast();

  return useQuery(
    ['-prev-simulacao-retirada', cpf, numCertificado, beneficioId, planoId],
    () =>
      SimularRetiradaApi.obterSimulacaoRetirada(
        cpf,
        numCertificado,
        beneficioId,
        planoId,
        dadosResgate,
      ),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      enabled: false,
      onError: (error: Error) => toastError(error.message),
    },
  );
};

export default useObterSimulacaoRetirada;
