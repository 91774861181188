import { usePeco } from 'main/hooks/usePeco';
import { PECOS } from 'previdencia/config/endpoints';
import { MotivoResgate } from 'previdencia/features/SolicitacaoResgate/types/MotivoResgate';

export const usePecoConsultarMotivosResgate = () => {
  const { loading, response, fetchData } = usePeco<unknown, MotivoResgate[]>({
    api: { operationPath: PECOS.ConsultarMotivosResgate },
    autoFetch: true,
    cache: true,
  });
  return { loading, response, fetchData };
};
