import { useContext, useEffect, useState } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { IDadosResgate } from 'previdencia/features/SolicitacaoResgate/types/ResponseListaFundosReserva';
import { sumValorBruto } from 'previdencia/features/SolicitacaoResgate/utils/OperacoesValorFundo';
import { verificarCarencia } from 'previdencia/features/SolicitacaoResgate/utils/ValidarParametrosResgate';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { FormatarObjetoDadosResgate } from 'previdencia/features/SolicitacaoResgate/factories/solicitacaoResgateFactory';
import { ISolicitacaoResgateContextData } from 'previdencia/features/SolicitacaoResgate/types/ISolicitacaoResgateContextData';
import { TIPOS_RESGATE } from 'previdencia/features/SolicitacaoResgate/constants/constants';
import useObterSimulacaoRetirada from './useObterSimulacaoRetirada';
import { UseFundo } from '../types/UseFundo';
import useObterValorMinimoMaximo from './useObterValorMinimoMaximo';

const useFundo = (
  id: string,
  certificado: string,
  beneficioId: string | undefined,
  planoId: string | undefined,
  valorRetirada: string,
  valorSaldo: string | undefined,
  dadosResgate: IDadosResgate[] | undefined,
): UseFundo => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const [messageAlert, setMessageAlert] = useState('');

  const { navigateTo } = usePrevNavigation();

  const { data: valorMinimoMaximo } = useObterValorMinimoMaximo(
    cpfCnpj,
    numCertificado,
  );

  const [dadosResgateArr, setDadosResgateArr] = useState<
    IDadosResgate[] | undefined
  >(
    dadosResgate?.map(dado => ({
      ...dado,
      requisitoSaldoMinimoAtendido: true,
      isDisabled: false,
    })),
  );
  const dadosResgateTotal: IDadosResgate[] = [];
  const [dadosResgateTotalArray, setDadosResgateTotalArray] = useState<
    IDadosResgate[]
  >([]);

  const [fundoSelecionado, setFundoSelecionado] = useState<IDadosResgate[]>([]);
  const [tipoResgateSelecionadoArray, setTipoResgateSelecionadoArray] =
    useState<{ tipoResgate: string; fundoId: string }[]>([]);
  const { setFeatureData, featureData } =
    usePrevidenciaContext<ISolicitacaoResgateContextData>();
  const [isBackRouter, setIsBackRouter] = useState(false);
  const {
    data: simulacaoRetirada,
    isLoading,
    isFetching,
    refetch,
    isSuccess,
  } = useObterSimulacaoRetirada(
    id,
    certificado,
    beneficioId ?? '',
    planoId ?? '',
    FormatarObjetoDadosResgate(dadosResgateArr),
  );

  useEffect(() => {
    setIsBackRouter(true);
  }, []);

  useEffect(() => {
    if (
      checkIfSomeItemsAreTrue([
        dadosResgateTotal.length <= 0,
        tipoResgateSelecionadoArray.length <= 0,
      ])
    ) {
      return;
    }

    setDadosResgateTotalArray(prev => [...prev, ...dadosResgateTotal]);
  }, [tipoResgateSelecionadoArray]);

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        !!simulacaoRetirada,
        isSuccess,
        isBackRouter,
        !verificarCarencia(simulacaoRetirada),
      ])
    ) {
      setIsBackRouter(false);
      setFeatureData({
        ...featureData,
        codigoResgate: simulacaoRetirada?.retiradaId,
      });
      navigateTo('confirmar-valores-simulacao', {
        simulacaoRetirada,
        valorBrutoRetirada: valorRetirada,
        resgateId: simulacaoRetirada?.retiradaId,
        beneficioId,
        planoId,
        saldo: valorSaldo,
        dadosResgate: FormatarObjetoDadosResgate(dadosResgateArr),
      });
    }
  }, [simulacaoRetirada, isSuccess, refetch]);

  const handleSimularResgate = () => {
    let somaDosValoresPreenchidos = 0;
    somaDosValoresPreenchidos = sumValorBruto(dadosResgateArr);

    const valorRetiradaPreenchido =
      Number(masks.currency.unmask(valorRetirada)) / 100;

    if (
      valorRetiradaPreenchido < Number(valorMinimoMaximo?.valorMinimoResgate)
    ) {
      return;
    }

    const isResgateParcial = dadosResgateArr?.some(
      x => x.tipoResgate === TIPOS_RESGATE.PARCIAL,
    );

    if (
      checkIfAllItemsAreTrue([
        !!isResgateParcial,
        Number(somaDosValoresPreenchidos) !== valorRetiradaPreenchido,
      ])
    ) {
      setMessageAlert(
        `O valor dos resgates deve somar ${formatarValorPadraoBrasileiro(
          valorRetiradaPreenchido,
        )}`,
      );

      return;
    }

    refetch();
  };

  const handleSelecionarFundo = (item: IDadosResgate, fundoId: string) => {
    const itemFilter = fundoSelecionado.find(
      dados => dados.idSelecionado === fundoId,
    );

    if (itemFilter?.idSelecionado) {
      const filter = fundoSelecionado.filter(
        itemFundo => itemFundo.idSelecionado !== itemFilter.idSelecionado,
      );
      setFundoSelecionado(filter);
    } else {
      setFundoSelecionado(prevState => [
        ...prevState,
        { ...item, idSelecionado: item.idFundo + item.idReserva },
      ]);
    }
  };

  const handleTipoTransferencia = (tipoResgate: string, fundoId: string) => {
    const filter = tipoResgateSelecionadoArray.find(
      item => item.fundoId === fundoId,
    );

    if (filter) {
      setTipoResgateSelecionadoArray(prev =>
        prev.map(item => {
          if (item.fundoId === fundoId) {
            return {
              fundoId,
              tipoResgate,
            };
          }
          return item;
        }),
      );
    } else {
      setTipoResgateSelecionadoArray(prev => [
        ...prev,
        { fundoId, tipoResgate },
      ]);
    }
  };
  const obterChaveIdentificacaoFundo = (idFundo: string, idReserva: string) =>
    `${idFundo}${idReserva}`;

  return {
    simulacaoRetirada,
    isSuccess,
    refetch,
    dadosResgateTotal,
    tipoResgateSelecionadoArray,
    fundoSelecionado,
    dadosResgateArr,
    handleSelecionarFundo,
    handleTipoTransferencia,
    isFetching,
    messageAlert,
    handleSimularResgate,
    isLoading,
    dadosResgateTotalArray,
    setDadosResgateTotalArray,
    setDadosResgateArr,
    obterChaveIdentificacaoFundo,
  };
};

export default useFundo;
