import { useToast } from 'main/hooks/useToast';
import { useQuery } from 'react-query';
import * as coberturasApi from 'previdencia/features/SolicitacaoResgate/services/coberturas.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { BeneficioCoberturas } from 'previdencia/features/SolicitacaoResgate/types/ResponseCoberturas';

type UseObterCoberturas = {
  data: BeneficioCoberturas[] | undefined;
  isLoading: boolean;
};

const useObterCoberturas = (
  cpf: string,
  numCertificado: string,
): UseObterCoberturas => {
  const { toastError } = useToast();
  const { data, isLoading } = useQuery(
    ['-prev-coberturas-', cpf, numCertificado],
    () => coberturasApi.obterCoberturas(cpf, numCertificado),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (error: Error) => toastError(error.message),
    },
  );

  return {
    data: data?.retorno?.beneficios,
    isLoading,
  };
};

export default useObterCoberturas;
