import React from 'react';
import { simulacaoDetalhadaColumnsPrint } from '../../constants/constants';
import { ListaDetalhesCalculo } from '../../types/ResponseConsultarDetalheCalculo';
import * as S from './styles';

type TablePrintProps = {
  data: ListaDetalhesCalculo[];
};
const TablePrint: React.FC<TablePrintProps> = ({ data }) => {
  return (
    <S.Table>
      <tr>
        {simulacaoDetalhadaColumnsPrint.map(item => (
          <th>{item}</th>
        ))}
      </tr>
      {data.map(item => (
        <tr>
          <td>{item.data}</td>
          <td>{item.rendimento}</td>
          <td>{item.carregamentoSaida}</td>
          <td>{item.baseIrrf}</td>
          <td>{item.aliquotaIrrf}</td>
          <td>{item.valorIrrf}</td>
          <td>{item.taxaSaida}</td>
          <td>{item.valorLiquido}</td>
        </tr>
      ))}
    </S.Table>
  );
};

export default TablePrint;
