import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import * as consultaResgatesProgramadosApi from 'previdencia/features/SolicitacaoResgate/services/resgatesProgramas.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { ResponseResgatesProgramados } from 'previdencia/features/SolicitacaoResgate/types/ResponseResgatesProgramados';

const useOberConsultaResgatesProgramados = (
  cpf: string,
  numCertificado: string,
): UseQueryResult<ResponseResgatesProgramados | undefined> => {
  const { toastError } = useToast();
  return useQuery(
    ['prev-consulta-resgates-programados-', cpf, numCertificado],
    () =>
      consultaResgatesProgramadosApi.obterResgatesProgramados(
        cpf,
        numCertificado,
      ),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (error: Error) => toastError(error.message),
    },
  );
};

export default useOberConsultaResgatesProgramados;
