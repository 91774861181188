import { useState } from 'react';
import * as Api from 'main/features/prospeccao/services/api';
import { IProspeccao } from 'main/features/prospeccao/types/IProspeccao';
import { useAuth } from 'main/features/Auth/hooks';
import { useObterPerfilUsuario } from 'main/features/Auth/hooks/useObterPerfilUsuario';

type ListaProspeccaoState = {
  items: IProspeccao[];
  loading: boolean;
  error: any;
};

const getDefaultState = (): ListaProspeccaoState => ({
  error: null,
  items: [] as IProspeccao[],
  loading: true,
});

export const useListarProspeccoes = () => {
  const perfilUsuario = useObterPerfilUsuario();

  const [prospeccoesPerfilEconomiario, setProspeccoesPerfilEconomiario] =
    useState<ListaProspeccaoState>(getDefaultState());
  const [prospeccoesPerfilMedico, setProspeccoesPerfilMedico] =
    useState<ListaProspeccaoState>(getDefaultState());
  const [prospeccoesPerfilProduto, setProspeccoesPerfilProduto] =
    useState<ListaProspeccaoState>(getDefaultState());
  const [prospeccoesPerfilOperacao, setProspeccoesPerfilOperacao] =
    useState<ListaProspeccaoState>(getDefaultState());

  const obterListaProspeccoesPerfilMedico = async () => {
    try {
      setProspeccoesPerfilMedico(prevState => ({
        ...prevState,
        loading: true,
      }));

      const { dados, sucesso } = await Api.obterListaParaAnaliseMedica(
        perfilUsuario,
      );

      if (sucesso && dados && dados.entidade && dados.entidade.length > 0) {
        setProspeccoesPerfilMedico(prevState => ({
          ...prevState,
          items: dados.entidade || [],
        }));
      }
    } catch (error: unknown) {
      setProspeccoesPerfilMedico(prevState => ({
        ...prevState,
        error,
      }));
    } finally {
      setProspeccoesPerfilMedico(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const obterListaProspeccoesPerfilProduto = async () => {
    try {
      setProspeccoesPerfilProduto(prevState => ({
        ...prevState,
        loading: true,
      }));

      const { dados, sucesso } = await Api.obterListaParaAnalistaProduto(
        perfilUsuario,
      );

      if (sucesso && dados && dados.entidade && dados.entidade.length > 0) {
        setProspeccoesPerfilProduto(prevState => ({
          ...prevState,
          items: dados.entidade || [],
        }));
      }
    } catch (error: unknown) {
      setProspeccoesPerfilProduto(prevState => ({
        ...prevState,
        error,
      }));
    } finally {
      setProspeccoesPerfilProduto(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const obterListaProspeccoesPerfilOperacao = async () => {
    try {
      setProspeccoesPerfilOperacao(prevState => ({
        ...prevState,
        loading: true,
      }));

      const { dados, sucesso } = await Api.obterListaParaOperacao(
        perfilUsuario,
      );

      if (sucesso && dados && dados.entidade && dados.entidade.length > 0) {
        setProspeccoesPerfilOperacao(prevState => ({
          ...prevState,
          items: dados.entidade || [],
        }));
      }
    } catch (error: unknown) {
      setProspeccoesPerfilOperacao(prevState => ({
        ...prevState,
        error,
      }));
    } finally {
      setProspeccoesPerfilOperacao(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const obterListaProspeccoesPerfilEconomiario = async () => {
    try {
      setProspeccoesPerfilEconomiario(prevState => ({
        ...prevState,
        loading: true,
      }));

      const { dados, sucesso } = await Api.obterListaParaEconomiario(
        perfilUsuario,
      );

      if (sucesso && dados && dados.entidade && dados.entidade.length > 0) {
        setProspeccoesPerfilEconomiario(prevState => ({
          ...prevState,
          items: dados.entidade || [],
        }));
      }
    } catch (error: unknown) {
      setProspeccoesPerfilEconomiario(prevState => ({
        ...prevState,
        error,
      }));
    } finally {
      setProspeccoesPerfilEconomiario(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  return {
    prospeccoesPerfilMedico,
    prospeccoesPerfilProduto,
    prospeccoesPerfilOperacao,
    prospeccoesPerfilEconomiario,
    obterListaProspeccoesPerfilMedico,
    obterListaProspeccoesPerfilProduto,
    obterListaProspeccoesPerfilOperacao,
    obterListaProspeccoesPerfilEconomiario,
  };
};
