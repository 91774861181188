import styled from 'styled-components/macro';

export const ContainerInformacoesRenda = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: '30px',
  marginBottom: '20px',
});

export const RendaLiquida = styled.div({
  borderRight: '1px solid silver',
  paddingRight: '40px',
  marginRight: '40px',
  alignItems: 'center',
});
