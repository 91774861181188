import { Card, Grid, Display, Button, Text } from '@cvp/design-system/react';
import Modal from 'main/components/Modal';
import ModalTipoDocuSign from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import EmailSenderModal from 'main/components/EmailSenderModal';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { AppContext } from 'main/contexts/AppContext';
import { formatarData } from 'main/utils/date';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { IRedirecionamentoHistoricoSolicitacaoLocation } from 'previdencia/types/IRedirecionamentoHistoricoSolicitacaoLocation';
import {
  PrevRedirectConfig,
  PREV_REDIRECTS_SCREENS,
} from 'previdencia/config/redirects';
import {
  IObterContatosDefaultPrevidencia,
  useObterContatosDefaultPrevidencia,
} from 'main/hooks/useObterContatosDefaultPrevidencia';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { StatusResgate } from 'previdencia/types/StatusResgate';
import { useObterComprovanteOperacao } from 'previdencia/hooks/useObterComprovanteOperacao';
import { useToast } from 'main/hooks/useToast';
import { IMessageEvent } from 'main/types/IMessageEvent';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import ResumoResgate from '../components/ResumoResgate/ResumoResgate';
import TextoInformativoFinalizar from '../components/TextoInformativoFinalizar/TextoInformativoFinalizar';
import TextoInformativoInicio from '../components/TextoInformativoInicio/TextoInformativoInicio';
import { useAssinaturaEletronicaResgate } from '../hooks/useAssinaturaEletronicaResgate';
import useObterConsultaDetalheCalculo from '../hooks/useObterConsultaDetalheCalculo';
import useObterConsultaResumoAliquotas from '../hooks/useObterConsultaResumoAliquotas';
import { ISolicitacaoResgateContextData } from '../types/ISolicitacaoResgateContextData';

interface ParamsLocationData
  extends IRedirecionamentoHistoricoSolicitacaoLocation {
  resgateId: string;
  saldo: string;
}

export const ResgateSucesso = () => {
  const history = useHistory();
  const { navigateTo } = usePrevNavigation();
  const { toastError } = useToast();
  const [openModalMensagemPendencia, setOpenModalMensagemPendencia] =
    useState(false);
  const { data: responseObterContatosDefault } =
    useObterContatosDefaultPrevidencia();
  const { emailDefault, numerosTelefone, numeroTelefoneSemDDD, numeroDDD } =
    tryGetValueOrDefault<IObterContatosDefaultPrevidencia>(
      [responseObterContatosDefault],
      {} as IObterContatosDefaultPrevidencia,
    );
  const { subscribe, unsubscribe } = useMessageEvent();
  const { featureData, setFeatureData, clearFeatureData } =
    usePrevidenciaContext<ISolicitacaoResgateContextData>();
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const location = useLocation<ParamsLocationData>();
  const {
    resgateId,
    saldo,
    data: locationHistoricoSolicitacoes,
  } = location.state;
  const numeroResgate = tryGetValueOrDefault(
    [resgateId, locationHistoricoSolicitacoes?.idRequisicao],
    '',
  );

  const { data: resumoAliquotas, isLoading: isLoadingConsultaResumoAliquota } =
    useObterConsultaResumoAliquotas(cpfCnpj, numCertificado, numeroResgate);

  const objetoEmail = {
    tipoEmail: tipoEmailConstants.COMPROVANTE_RESGATE,
    parametrosEnvio: {
      numeroResgate,
      numeroCertificado: numCertificado,
      cpfCnpj,
    },
  };
  const {
    data: consultaDetalheCalculo,
    isLoading: isLoadingConsultaDetalheCalculo,
  } = useObterConsultaDetalheCalculo(cpfCnpj, numCertificado, numeroResgate);

  const { gerarComprovante, loading: loadingComprovante } =
    useObterComprovanteOperacao();

  const { solicitarAssinatura, loading: loadingSolicitarAssinatura } =
    useAssinaturaEletronicaResgate();

  const [urlAssinatura, setUrlAssinatura] = useState<string>('');
  const [openModalTipo, setModalTipo] = useState(false);
  const [openModalDocUsign, setOpenModalDocUsign] = useState(false);
  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] = useState(false);

  useEffect(() => {
    setModalTipo(!!featureData?.solicitarAssinaturaEletronica);
    setOpenModalMensagemPendencia(
      featureData?.statusResgate === StatusResgate.PENDENTE,
    );
  }, [featureData]);

  useEffect(() => {
    subscribe(event => {
      const {
        data: { eventName, eventSuccess },
      } = event as MessageEvent<IMessageEvent>;
      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setModalTipo(false);
        setExibirBotaoFecharModal(true);
        clearFeatureData();
      }
    });
    return () => {
      unsubscribe(() => setExibirBotaoFecharModal(false));
    };
  }, []);

  const verificarOrigemRedirectHistorico = () => {
    return checkIfSomeItemsAreTrue([
      location.state?.from === PREV_REDIRECTS_SCREENS.HISTORICO_SOLICITACOES,
      location.state?.from ===
        PREV_REDIRECTS_SCREENS.HISTORICO_CANCELAMENTO_RESGATE,
    ]);
  };

  useEffect(() => {
    if (
      checkIfAllItemsAreTrue([
        verificarOrigemRedirectHistorico(),
        !!location.state?.data?.idRequisicao,
      ])
    ) {
      setFeatureData({
        ...featureData,
        codigoResgate: location.state?.data.idRequisicao,
        solicitarAssinaturaEletronica: true,
      });
      setModalTipo(true);
    }
  }, [location.state?.from, location.state?.data?.idRequisicao]);

  if (
    checkIfSomeItemsAreTrue([
      isLoadingConsultaDetalheCalculo,
      isLoadingConsultaResumoAliquota,
    ])
  ) {
    return <SkeletonLoading lines={6} />;
  }

  const handleAssinar = async () => {
    const result = await solicitarAssinatura({
      email: emailDefault,
      numeroTelefoneSemDDD,
      numeroDDD,
    });
    if (result && result.urlAssinatura) {
      setUrlAssinatura(result.urlAssinatura);
      setOpenModalDocUsign(true);
      setModalTipo(false);
    }
  };

  const handleCloseModalTipo = async () => {
    setModalTipo(false);
    history.push(
      PrevRedirectConfig[PREV_REDIRECTS_SCREENS.HISTORICO_CANCELAMENTO_RESGATE],
    );
  };

  const fecharModaisAssinatura = () => {
    setModalTipo(false);
    setOpenModalDocUsign(false);
  };

  const handleCloseModal = () => {
    if (verificarOrigemRedirectHistorico()) {
      fecharModaisAssinatura();
      history.push(
        PrevRedirectConfig[PREV_REDIRECTS_SCREENS[location.state?.from]],
      );
    } else fecharModaisAssinatura();
  };

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <Card style={{ minHeight: 'auto' }}>
        <Card.Content data-testid="tabelaListaResgates">
          <Grid>
            <Grid.Item xs={1}>
              <TextoInformativoInicio
                dataSolicitacao={formatarData(new Date())}
              />
            </Grid.Item>
          </Grid>
          <RenderConditional
            condition={checkIfSomeItemsAreTrue([
              featureData?.statusResgate === StatusResgate.SOLICITADO,
              featureData?.statusResgate === StatusResgate.VALIDADO,
              featureData?.statusResgate ===
                StatusResgate.AGUARDANDO_ASSINATURA,
            ])}
          >
            <ResumoResgate
              dadosResgate={resumoAliquotas}
              saldo={saldo}
              consultaDetalheCalculo={consultaDetalheCalculo}
            />
            <TextoInformativoFinalizar />
          </RenderConditional>
          <RenderConditional
            condition={checkIfSomeItemsAreTrue([
              featureData?.statusResgate === StatusResgate.SOLICITADO,
              featureData?.statusResgate === StatusResgate.VALIDADO,
              featureData?.statusResgate ===
                StatusResgate.AGUARDANDO_ASSINATURA,
            ])}
          >
            <Display>
              <Button
                variant="outlined"
                onClick={() => navigateTo('cancelamento-solicitacao-resgate')}
              >
                Voltar
              </Button>
              <Button
                variant="primary"
                onClick={async () => gerarComprovante(numeroResgate, 'RESGATE')}
                loading={loadingComprovante}
              >
                Imprimir
              </Button>
              <RenderConditional
                condition={checkIfSomeItemsAreTrue([
                  featureData?.statusResgate === StatusResgate.SOLICITADO,
                  featureData?.statusResgate === StatusResgate.VALIDADO,
                ])}
              >
                <EmailSenderModal
                  objetoEmail={objetoEmail}
                  onError={() =>
                    toastError('Ocorreu um erro ao enviar o E-mail.')
                  }
                />
              </RenderConditional>
            </Display>
          </RenderConditional>
        </Card.Content>
      </Card>
      <RenderConditional condition={openModalTipo}>
        <ModalTipoDocuSign
          open={openModalTipo}
          executarAssinatura={() => handleAssinar()}
          handleClose={handleCloseModalTipo}
          tituloModal="Solicitação de resgate"
          loading={loadingSolicitarAssinatura}
          dadosCliente={{
            email: emailDefault,
            cpfCnpj,
            mobileNumber: numerosTelefone,
          }}
        />
      </RenderConditional>
      <RenderConditional
        condition={checkIfAllItemsAreTrue([openModalDocUsign, !!urlAssinatura])}
      >
        <ModalTokenDocuSign
          open={openModalDocUsign}
          urlTokenPage={urlAssinatura}
          handleClose={handleCloseModal}
          exibirBotaoFecharModal={exibirBotaoFecharModal}
        />
      </RenderConditional>

      <RenderConditional condition>
        <Modal
          open={openModalMensagemPendencia}
          onClose={() => setOpenModalMensagemPendencia(false)}
        >
          <>
            <Text variant="caption-02">
              <strong>
                Não foi possível concluir a solicitação de resgate.
              </strong>
            </Text>

            <br />
            <Text variant="caption-02" color="error">
              <strong>{featureData?.motivoPendenciaResgate}</strong>
            </Text>
          </>
        </Modal>
      </RenderConditional>
    </Display>
  );
};
