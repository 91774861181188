import { useCallback, useEffect, useState } from 'react';
import { useToast } from 'main/hooks/useToast';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
  useQueryClient,
} from 'react-query';
import * as listaFundosApi from 'previdencia/features/SolicitacaoResgate/services/listaFundosReserva.api';
import { IResponseListaFundosReservaRetorno } from 'previdencia/features/SolicitacaoResgate/types/ResponseListaFundosReserva';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { ISolicitacaoResgateContextData } from '../types/ISolicitacaoResgateContextData';

type useObterListaFundosRetorno = {
  listaFundosReserva: IResponseListaFundosReservaRetorno | undefined;
  isFetching: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<
    QueryObserverResult<IResponseListaFundosReservaRetorno | undefined, Error>
  >;
  disabledInputValorResgatado: boolean;
  handleHabilitarNovoValorResgate: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  isSuccessQuery: () => boolean;
};

const useObterListaFundos = (
  cpf: string,
  numCertificado: string,
  beneficioId?: string,
  planoId?: string,
): useObterListaFundosRetorno => {
  const { toastError } = useToast();
  const queryClient = useQueryClient();

  const { featureData } =
    usePrevidenciaContext<ISolicitacaoResgateContextData>();

  const [disabledInputValorResgatado, setDisabledInputValorResgatado] =
    useState(false);

  const { data, isFetching, isRefetchError, isSuccess, refetch } = useQuery(
    ['-prev-lista-fundos', cpf, numCertificado, beneficioId, planoId],
    () => {
      if (beneficioId && planoId) {
        return listaFundosApi.obterListaFundosParaReserva(
          numCertificado,
          featureData?.valorResgate || '',
          featureData?.resgateTotal ? 'T' : 'P',
        );
      }
      return undefined;
    },
    {
      staleTime: 0,
      retry: false,
      enabled: false,
      onError: (error: Error) => toastError(error.message),
    },
  );

  const isSuccessQuery = useCallback(() => {
    return isSuccess && !isFetching && !isRefetchError;
  }, [isSuccess, isFetching, isRefetchError]);

  useEffect(() => {
    if (isSuccessQuery() && data) {
      setDisabledInputValorResgatado(true);
    }
  }, [isSuccessQuery, data]);

  useEffect(() => {
    queryClient.resetQueries('-prev-lista-fundos');
    queryClient.resetQueries('-prev-simulacao-retirada');
    setDisabledInputValorResgatado(false);
  }, [queryClient]);

  return {
    isFetching,
    listaFundosReserva: data,
    refetch,
    disabledInputValorResgatado,
    handleHabilitarNovoValorResgate: setDisabledInputValorResgatado,
    isSuccessQuery,
  };
};

export default useObterListaFundos;
