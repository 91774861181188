import { ResponseSimularRetirada } from 'previdencia/features/SolicitacaoResgate/types/ResponseSimularRetirada';
import { verificarCarencia } from 'previdencia/features/SolicitacaoResgate/utils/ValidarParametrosResgate';

export const gerarColumns = (
  simulacaoRetirada: ResponseSimularRetirada | undefined,
  validarSaldoMinimo: boolean,
) => [
  {
    name: 'FUNDO',
    selector: 'descricaoFundo',
    minWidth: '400px',
    wrap: true,
  },
  {
    name: 'SALDO TOTAL',
    selector: 'valorSaldo',
    width: '250px',
  },
  {
    name: 'TIPO RESGATE',
    selector: 'tipoResgate',
    maxWidth: '250px',
  },
  {
    name: 'VALOR A RETIRAR',
    selector: 'valorRetirar',
    maxWidth:
      verificarCarencia(simulacaoRetirada) || validarSaldoMinimo
        ? '300px'
        : '200px',
  },
];
