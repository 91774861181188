/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { IDENTIFICAR_STRING_VALOR_MONETARIO } from 'main/utils/regex';
import masks from 'main/utils/masks';
import { IDadosResgate } from 'previdencia/features/SolicitacaoResgate/types/ResponseListaFundosReserva';
import { TIPOS_RESGATE } from '../constants/constants';

export const parseValorBruto = (valor: string) => {
  const resultado = parseFloat(masks.currencyInput.unmask(valor));
  const valorComMascara = IDENTIFICAR_STRING_VALOR_MONETARIO.test(valor);

  if (valorComMascara) {
    return resultado / 100;
  }
  return parseFloat(valor);
};

export const calculaValorRestante = (
  dadosResgate: IDadosResgate[] | undefined,
  valorRetiradaAtual: string,
) => {
  const valorRetiradaAtualParsed = parseFloat(
    masks.currencyInput.unmask(tryGetValueOrDefault([valorRetiradaAtual], '0')),
  );
  const sub = valorRetiradaAtualParsed / 100;

  const soma = (dadosResgate ?? [])
    .filter(x => x.isDisabled && !!x.valorBruto)
    ?.reduce((acc, current) => {
      return acc + parseValorBruto(current.valorBruto || '');
    }, 0);

  const calculoFinal = sub - tryGetValueOrDefault([soma], 0);

  return +calculoFinal.toFixed(2);
};

export const subValorBruto = (
  dadosResgate: IDadosResgate[] | undefined,
  valorRetiradaAtual: string,
) => {
  return calculaValorRestante(dadosResgate, valorRetiradaAtual);
};

export const sumValorBruto = (dadosResgate: IDadosResgate[] | undefined) => {
  const soma = dadosResgate
    ?.filter(x => x.isDisabled && !!x.valorBruto)
    ?.reduce(
      (acumulador, itemAtual) =>
        acumulador +
        parseValorBruto(tryGetValueOrDefault([itemAtual.valorBruto], '0')),
      0,
    );

  const sum = tryGetValueOrDefault([soma], 0);

  if (dadosResgate?.every(x => x.tipoResgate === TIPOS_RESGATE.TOTAL))
    return +sum.toFixed(2);
  return sum;
};

export const desabilitarBotaoSimular = (
  dadosResgate: IDadosResgate[] | undefined,
  valorRetiradaAtual: string,
) => {
  const resultadoCalculo = subValorBruto(dadosResgate, valorRetiradaAtual);
  if (
    resultadoCalculo > 0 &&
    dadosResgate?.every(x => x.tipoResgate === TIPOS_RESGATE.TOTAL)
  ) {
    const resultadoArrendondamento = Math.floor(resultadoCalculo);
    if (resultadoArrendondamento === 0) return false;
  }

  return resultadoCalculo < 0 || resultadoCalculo > 0;
};
