import React from 'react';
import { Button, Display } from '@cvp/design-system/react';
import { FlexContainer } from 'main/styles/GlobalStyle';

import * as S from './styles';

type ButtonActionProps = {
  titleButtonLeft: string;
  titleButtonRight: string;
  onClickButtonLeft: () => void;
  onClickButtonRight: () => void;
  justifyContent?: string;
  disabled?: boolean;
  loading?: boolean;
  variant?: string;
  [key: string]: unknown;
};

const ButtonAction: React.FC<ButtonActionProps> = ({
  titleButtonLeft,
  titleButtonRight,
  onClickButtonLeft,
  onClickButtonRight,
  justifyContent,
  disabled,
  loading,
  variant = 'secondary',
  ...rest
}) => {
  return (
    <S.Container>
      {!justifyContent ? (
        <Display>
          <Button {...rest} variant={variant} onClick={onClickButtonLeft}>
            {titleButtonLeft}
          </Button>
          <Button
            variant="primary"
            onClick={onClickButtonRight}
            disabled={disabled}
            loading={loading}
            {...rest}
          >
            {titleButtonRight}
          </Button>
        </Display>
      ) : (
        <FlexContainer justifyContent={justifyContent}>
          <Button variant="secondary" onClick={onClickButtonLeft} {...rest}>
            {titleButtonLeft}
          </Button>
          <Button
            variant="primary"
            onClick={onClickButtonRight}
            disabled={disabled}
            {...rest}
          >
            {titleButtonRight}
          </Button>
        </FlexContainer>
      )}
    </S.Container>
  );
};

export default ButtonAction;
