import { api, IApiResponse } from 'main/services';
import { obterEndpointConsultarResgatesProgramados } from 'previdencia/config/endpoints';
import { ResponseResgatesProgramados } from 'previdencia/features/SolicitacaoResgate/types/ResponseResgatesProgramados';

export const obterResgatesProgramados = async (
  cpfCnpj: string,
  numeroCertificado: string,
  numeroResgate = '',
): Promise<ResponseResgatesProgramados | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseResgatesProgramados>>(
    obterEndpointConsultarResgatesProgramados(),
    {
      cpfCnpj,
      numeroCertificado,
      numeroResgate,
    },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data?.dados?.entidade;
};
