import { obterEndpointSimularBeneficio } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import {
  RequestSimularBeneficioApi,
  ResponseSimularBeneficios,
} from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';

export const simularBeneficios = async (
  request: RequestSimularBeneficioApi | undefined,
): Promise<IApiResponse<ResponseSimularBeneficios> | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseSimularBeneficios>>(
    obterEndpointSimularBeneficio(),
    {
      cpf: request?.cpf,
      numeroCertificado: request?.numeroCertificado,
      tipoRenda: request?.tipoRenda,
      qtdPrazoAnos: request?.qtdPrazoAnos,
      pctReversao: request?.pctReversao,
      dthNascimentoConjuge: request?.dthNascimentoConjuge,
      staGeneroConjuge: request?.staGeneroConjuge,
      cpfBeneficiario: request?.cpfBeneficiario,
    },
  );

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data;
};
