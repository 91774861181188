import React from 'react';
import { Button, Display } from '@cvp/design-system/react';
import EmailSenderModal from 'main/components/EmailSenderModal';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { payloadSendEmailSimularRendaFactory } from 'previdencia/features/SimulacaoRenda/factories/payloadSendEmailSimularRendaFactory';
import { RedirecionamentoUltimasSimulacoesProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';

const RedirecionamentoUltimasSimulacoes: React.FC<
  RedirecionamentoUltimasSimulacoesProps
> = ({ exibirHistorico, request }) => {
  const objetoEmail = {
    tipoEmail: tipoEmailConstants.SIMULACAO_RENDA_PREVIDENCIA,
    parametrosEnvio: {
      cpfCnpj: request?.cpf,
      numeroCertificado: request?.numeroCertificado,
      tipoRenda: request?.tipoRenda,
      ...payloadSendEmailSimularRendaFactory(request),
    },
  };

  const inicio = (): void => {
    window.location.reload();
  };

  return (
    <Display>
      <Button variant="outlined" onClick={inicio}>
        Nova Simulação
      </Button>

      <Button variant="secondary" onClick={exibirHistorico}>
        Ver as Últimas Simulações
      </Button>

      <Button variant="secondary" onClick={() => window.print()}>
        Imprimir
      </Button>

      <EmailSenderModal objetoEmail={objetoEmail} />
    </Display>
  );
};

export default RedirecionamentoUltimasSimulacoes;
