import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Display,
  Divider,
  Grid,
  Text,
  TextField,
} from '@cvp/design-system/react';
import Modal from 'main/components/Modal';
import { Input } from 'main/components/form';
import Icon from 'main/components/Icon';
import Select, { SelectItem } from 'main/components/form/Select';
import RenderConditional from 'main/components/RenderConditional';
import SkeletonLoading from 'main/components/SkeletonLoading';
import { AppContext } from 'main/contexts/AppContext';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import { validate } from 'main/features/Validation/utils/validateRules';
import { minValue, required } from 'main/features/Validation/validations';
import useFieldLink from 'main/hooks/useFieldLink';
import { formatarData } from 'main/utils';
import masks from 'main/utils/masks';
import {
  formatarValorPadraoBrasileiro,
  removerMascaraValorMonetario,
} from 'main/utils/money';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import MessageConsultasProgramadas from 'previdencia/features/SolicitacaoResgate/components/MessageConsultasProgramadas';
import ModalAlert from 'previdencia/features/SolicitacaoResgate/components/ModalAlert';
import ResgateInfo from 'previdencia/features/SolicitacaoResgate/components/RegasteInfo';
import useObterConsultaResgatesProgramados from 'previdencia/features/SolicitacaoResgate/hooks/useOberConsultaResgatesProgramados';
import useObterCoberturas from 'previdencia/features/SolicitacaoResgate/hooks/useObterCoberturas';
import useObterListaFundos from 'previdencia/features/SolicitacaoResgate/hooks/useObterListaFundos';
import useObterValorMinimoMaximo from 'previdencia/features/SolicitacaoResgate/hooks/useObterValorMinimoMaximo';
import useValidarValorMaximoMinimo from 'previdencia/features/SolicitacaoResgate/hooks/useValidarValorMaximoMinimo';
import { isSelected } from 'previdencia/features/SolicitacaoResgate/utils/SelectTipoResgate';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import { useHistory } from 'react-router';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
} from 'main/utils/conditional';
import { BeneficioCoberturas } from '../types/ResponseCoberturas';
import {
  TEXTO_PRAZO_DIFERIMENTO,
  solicitacaoResgateLabels,
} from '../constants/constants';
import { useAssinaturaEletronicaResgate } from '../hooks/useAssinaturaEletronicaResgate';
import { ISolicitacaoResgateContextData } from '../types/ISolicitacaoResgateContextData';

const SolicitacaoResgate: React.FC = () => {
  const [valorResgateSolicitadoLink, validateValorResgateSolicitado] =
    useFieldLink('');
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const [tipoResgateLink, validateTipoResgate] = useFieldLink('');

  const tipoResgate = tipoResgateLink.get().value;
  const valorResgate = valorResgateSolicitadoLink.get().value;
  const [coberturasPR, setCoberturasPR] = useState<
    BeneficioCoberturas | undefined
  >();
  const {
    data: valorMinimoMaximo,
    isLoading: isLoadingValorMinimoMaximoResgate,
  } = useObterValorMinimoMaximo(cpfCnpj, numCertificado);

  const { handleCloseModal, handleIniciarSimulacao, modalMessage, openModal } =
    useValidarValorMaximoMinimo(valorResgate, tipoResgate, valorMinimoMaximo);

  const {
    data: consultaResgatesProgramados,
    isLoading: isLoadingConsultaResgatesProgramados,
  } = useObterConsultaResgatesProgramados(cpfCnpj, numCertificado);

  const { data: coberturas, isLoading: isLoadingCoberturas } =
    useObterCoberturas(cpfCnpj, numCertificado);
  const {
    listaFundosReserva,
    refetch,
    isFetching,
    disabledInputValorResgatado,
    handleHabilitarNovoValorResgate,
    isSuccessQuery,
  } = useObterListaFundos(
    cpfCnpj,
    numCertificado,
    coberturasPR?.coberturaId,
    coberturasPR?.planoId,
  );

  const coberturaReserva = coberturas?.find(
    cobertura => cobertura.descricaoCobertura === 'RESERVA',
  );

  const {
    data: responseObterContatosDefault,
    isFetched: recuperacaoEmailExecutada,
    isFetching: loadingObterContatos,
  } = useObterContatosDefaultPrevidencia();
  const { emailDefault, numerosTelefone } = responseObterContatosDefault ?? {};
  const [desabilitarSelectTipoResgate, setDesabilitarSelectTipoResgate] =
    useState(false);
  const {
    verificarPermissaoParaFazerResgate,
    loading: loadingVerificarNecessidadeAssinatura,
  } = useAssinaturaEletronicaResgate();
  const { featureData, setFeatureData, clearFeatureData } =
    usePrevidenciaContext<ISolicitacaoResgateContextData>();
  const [openModalAlertaContatos, setOpenModalAlertaContatos] = useState(false);
  const [
    openModalPrazoDiferimentoExpirado,
    setOpenModalPrazoDiferimentoExpirado,
  ] = useState(false);
  const { goDadosPlano } = usePrevNavigation();
  const history = useHistory();

  useEffect(() => {
    clearFeatureData();
  }, []);

  const verificarAssinatura = async (cobertura?: BeneficioCoberturas) => {
    const {
      resgatePermitido,
      assinaturaEletronicaRequerida,
      prazoDiferimentoExpirado,
    } = await verificarPermissaoParaFazerResgate(
      cobertura?.coberturaId,
      cobertura?.planoId,
    );

    if (!prazoDiferimentoExpirado) {
      setOpenModalAlertaContatos(assinaturaEletronicaRequerida);
    }

    setOpenModalPrazoDiferimentoExpirado(!!prazoDiferimentoExpirado);
    setFeatureData({
      ...featureData,
      isMonofundo: listaFundosReserva?.indicadorMultifundo !== 'S',
      resgatePermitido,
      prazoDiferimentoExpirado: !!prazoDiferimentoExpirado,
    });
  };

  const fecharModalPrazoDiferimentoExpiradoInfo = (): void => {
    setOpenModalPrazoDiferimentoExpirado(false);
  };

  useEffect(() => {
    setFeatureData({
      ...featureData,
      valorResgate: removerMascaraValorMonetario(
        masks.currencyInput.mask(valorResgate),
      ).toString(),
    });
  }, [valorResgate]);

  useEffect(() => {
    const cobertura = coberturas?.find(
      beneficio => beneficio.tipoBeneficio === 'PR',
    );
    if (cobertura && cobertura?.coberturaId) {
      setCoberturasPR(cobertura);
      verificarAssinatura(cobertura);
    }
  }, [coberturas, recuperacaoEmailExecutada]);

  useEffect(() => {
    if (listaFundosReserva?.detalhesResgate?.length) {
      setFeatureData({
        ...featureData,
        isMonofundo: listaFundosReserva?.indicadorMultifundo !== 'S',
        coberturaId: coberturaReserva?.coberturaId,
        planoId: coberturaReserva?.planoId,
        dataCarencia: listaFundosReserva?.detalhesResgate?.find(
          data => !!data.dataCarencia,
        )?.dataCarencia,
      });
    }
  }, [listaFundosReserva]);

  const preencherInputResgateTotal = () => {
    if (isSelected('total', tipoResgateLink)) {
      valorResgateSolicitadoLink.set({
        value: formatarValorPadraoBrasileiro(valorMinimoMaximo?.valorSaldo),
        isValid: true,
      });
    } else {
      handleHabilitarNovoValorResgate(false);
      valorResgateSolicitadoLink.set({
        value: '',
        isValid: false,
      });
    }
    setFeatureData({
      ...featureData,
      resgateParcial: tipoResgateLink.get().value === 'parcial',
      resgateTotal: tipoResgateLink.get().value === 'total',
    });
  };

  useEffect(() => {
    preencherInputResgateTotal();
  }, [tipoResgate]);

  useEffect(() => {
    if (!disabledInputValorResgatado) {
      valorResgateSolicitadoLink.set({
        isValid: true,
        value: '',
      });
      tipoResgateLink.set({ isValid: true, value: '' });
    }
  }, [disabledInputValorResgatado]);

  const exibirAlertaResgateProgramado =
    checkIfAllItemsAreTrue([
      !isLoadingConsultaResgatesProgramados,
      consultaResgatesProgramados?.observacaoPendenciaResgate !==
        'Não Existe(m) resgate(s) programados para o certifcado informado.',
    ]) || Number(valorMinimoMaximo?.valorSaldo) === 0;

  const loadingSkeletonBlocks = checkIfAllItemsAreTrue([
    isLoadingConsultaResgatesProgramados,
    isLoadingValorMinimoMaximoResgate,
    isLoadingCoberturas,
    !exibirAlertaResgateProgramado,
  ]);

  const loadingSkeletonLines = checkIfAllItemsAreTrue([
    loadingVerificarNecessidadeAssinatura,
    loadingObterContatos,
    !exibirAlertaResgateProgramado,
  ]);

  return (
    <>
      <RenderConditional condition={exibirAlertaResgateProgramado}>
        <MessageConsultasProgramadas
          text="Para resgates de plano de previdência com data de contrato expirado, o
      cliente deverá contatar a central de relacionamento no 0800 702 4000."
        />
      </RenderConditional>

      <RenderConditional condition={loadingSkeletonBlocks}>
        <SkeletonLoading blocks={2} />
      </RenderConditional>

      <RenderConditional condition={loadingSkeletonLines}>
        <SkeletonLoading lines={4} />
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          !loadingSkeletonBlocks,
          !loadingSkeletonLines,
          !exibirAlertaResgateProgramado,
        ])}
      >
        <Display type="display-block">
          <PrevidenciaResumo />
          <FeatureAuthorizer
            requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA]}
            requiredPermissions={[PREV_PERMISSIONS.SOLICITACAO_DE_RESGATE]}
          >
            <Card>
              <Card.Content padding={[4, 4, 4]}>
                <Grid>
                  <Grid.Item xs={1}>
                    <Text variant="headline-05" color="primary" margin>
                      {solicitacaoResgateLabels.SIMULAR_SOLICITACAO_RESGATE}
                    </Text>
                    <Text variant="body02-md" color="text-light">
                      {solicitacaoResgateLabels.SIMULE_RESGATE_SAIBA_ENCARGOS}
                    </Text>
                  </Grid.Item>
                  <Grid.Item xs={1 / 3}>
                    <Display>
                      <div>
                        <Text variant="body02-sm" color="text">
                          {solicitacaoResgateLabels.SALDO_EM}
                          {formatarData(new Date())}:
                        </Text>
                        <TextField
                          disabled="true"
                          value={formatarValorPadraoBrasileiro(
                            valorMinimoMaximo?.valorSaldo,
                          )}
                        />
                      </div>
                    </Display>
                  </Grid.Item>
                  <Grid.Item xs={1 / 3}>
                    <Display>
                      <div>
                        <Text variant="body02-sm" color="text">
                          {solicitacaoResgateLabels.TIPO_RESGATE}
                        </Text>
                        <Text variant="body02-sm" color="text">
                          <Select
                            placeholder="Selecione uma opção"
                            link={tipoResgateLink}
                            validationRules={[required()]}
                            id="selectTipoResgate"
                            isDisabled={desabilitarSelectTipoResgate}
                          >
                            <SelectItem
                              value="parcial"
                              text="Resgate Parcial"
                              selected={isSelected('parcial', tipoResgateLink)}
                            />
                            <SelectItem
                              value="total"
                              text="Resgate Total"
                              selected={isSelected('total', tipoResgateLink)}
                            />
                          </Select>
                        </Text>
                      </div>
                    </Display>
                  </Grid.Item>
                  <Grid.Item xs={1 / 3}>
                    <Display>
                      <div>
                        <Text variant="body02-sm" color="text">
                          {solicitacaoResgateLabels.VALOR_SOLICITADO}
                        </Text>
                        <Text variant="body02-sm" color="text">
                          <Input
                            link={valorResgateSolicitadoLink}
                            disabled={checkIfSomeItemsAreTrue([
                              disabledInputValorResgatado,
                              isSelected('total', tipoResgateLink),
                            ])}
                            inputMask={masks.currencyInput}
                            placeholder="Digite o valor"
                            validationRules={[
                              required(),
                              minValue(() => '', {
                                min: Number(
                                  valorMinimoMaximo?.valorMinimoResgate,
                                ),
                              }),
                            ]}
                            legend={`Valor Mínimo ${formatarValorPadraoBrasileiro(
                              valorMinimoMaximo?.valorMinimoResgate,
                            )}`}
                          />
                        </Text>
                      </div>
                    </Display>
                  </Grid.Item>
                  <Grid.Item xs={1}>
                    <RenderConditional
                      condition={checkIfAllItemsAreTrue([
                        !!featureData?.resgateParcial,
                        !!valorMinimoMaximo?.valorMinimoCertificado,
                      ])}
                      component={
                        <>
                          {
                            solicitacaoResgateLabels.ATENCAO_PLANO_COM_RESERVA_MINIMA
                          }
                          {formatarValorPadraoBrasileiro(
                            valorMinimoMaximo?.valorMinimoCertificado,
                          )}
                          .
                        </>
                      }
                    />
                  </Grid.Item>
                  <Grid.Item xs={1}>
                    <FeatureAuthorizer
                      requiredRoles={[
                        USER_PROFILES.ECONOMIARIO,
                        USER_PROFILES.ANALISTA_CVP,
                        USER_PROFILES.ANALISTA_POS_VENDA,
                        USER_PROFILES.ANALISTA_TI,
                        USER_PROFILES.ANALISTA_MANUTENCAO,
                      ]}
                    >
                      <RenderConditional
                        condition={!!featureData?.prazoDiferimentoExpirado}
                      >
                        <Text variant="body01-md" color="error">
                          {TEXTO_PRAZO_DIFERIMENTO.AVISO_ALTERACAO}
                        </Text>
                      </RenderConditional>
                    </FeatureAuthorizer>

                    <FeatureAuthorizer
                      requiredRoles={[
                        USER_PROFILES.ANALISTA_CONSULTA,
                        USER_PROFILES.ANALISTA_ENTRADA,
                        USER_PROFILES.ANALISTA_SAIDA,
                        USER_PROFILES.SAC_CAIXA_TERCEIROS,
                        USER_PROFILES.OPERADOR,
                        USER_PROFILES.ANALISTA_PRODUTO,
                        USER_PROFILES.MEDICO,
                      ]}
                    >
                      <RenderConditional
                        condition={!!featureData?.prazoDiferimentoExpirado}
                      >
                        <Text variant="body01-md" color="error">
                          {TEXTO_PRAZO_DIFERIMENTO.AVISO_CONSULTA}
                        </Text>
                      </RenderConditional>
                    </FeatureAuthorizer>
                  </Grid.Item>
                  <Grid.Item xs={1}>
                    <Display>
                      <Button variant="outlined" onClick={goDadosPlano}>
                        {solicitacaoResgateLabels.VOLTAR}
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          if (
                            validate([
                              validateValorResgateSolicitado,
                              validateTipoResgate,
                            ])
                          ) {
                            if (handleIniciarSimulacao()) {
                              refetch();
                              setDesabilitarSelectTipoResgate(true);
                            }
                          }
                        }}
                        loading={isFetching}
                        disabled={checkIfSomeItemsAreTrue([
                          disabledInputValorResgatado,
                          isFetching,
                          !!featureData?.prazoDiferimentoExpirado,
                        ])}
                      >
                        {solicitacaoResgateLabels.CONFIRMAR}
                      </Button>
                    </Display>
                  </Grid.Item>
                </Grid>
                <RenderConditional
                  condition={[
                    isSuccessQuery(),
                    disabledInputValorResgatado,
                  ].every(x => x)}
                >
                  <Divider />
                  <ResgateInfo
                    fundo={listaFundosReserva}
                    valorRetirada={valorResgateSolicitadoLink.get().value}
                    valorSaldo={valorMinimoMaximo?.valorSaldo}
                    valorMinimoMaximo={valorMinimoMaximo}
                    atualizaInputValorResgatado={value => {
                      handleHabilitarNovoValorResgate(value);
                      setDesabilitarSelectTipoResgate(false);
                    }}
                  />
                </RenderConditional>
              </Card.Content>
            </Card>
            <ModalAlertaContatos
              open={openModalAlertaContatos}
              onClose={() => setOpenModalAlertaContatos(false)}
              redirect={() =>
                history.push('/cliente/produtos/previdencia/dados-participante')
              }
              dados={{
                email: emailDefault,
                telefone: numerosTelefone,
              }}
            />

            <Modal
              open={openModalPrazoDiferimentoExpirado}
              onClose={fecharModalPrazoDiferimentoExpiradoInfo}
            >
              <Text margin color="secondary">
                <strong>
                  <Icon name="warning" /> Atenção!
                </strong>
              </Text>

              <FeatureAuthorizer
                requiredRoles={[
                  USER_PROFILES.ECONOMIARIO,
                  USER_PROFILES.ANALISTA_CVP,
                  USER_PROFILES.ANALISTA_TI,
                  USER_PROFILES.ANALISTA_MANUTENCAO,
                  USER_PROFILES.ANALISTA_POS_VENDA,
                ]}
              >
                {TEXTO_PRAZO_DIFERIMENTO.MODAL_ALTERACAO.map(item => (
                  <Text variant="caption-02" align="left" margin key={item.ID}>
                    {item.PARAGRAFO}
                  </Text>
                ))}
              </FeatureAuthorizer>

              <FeatureAuthorizer
                requiredRoles={[
                  USER_PROFILES.ANALISTA_CONSULTA,
                  USER_PROFILES.ANALISTA_ENTRADA,
                  USER_PROFILES.ANALISTA_SAIDA,
                  USER_PROFILES.SAC_CAIXA_TERCEIROS,
                  USER_PROFILES.OPERADOR,
                  USER_PROFILES.ANALISTA_PRODUTO,
                  USER_PROFILES.MEDICO,
                ]}
              >
                {TEXTO_PRAZO_DIFERIMENTO.MODAL_CONSULTA.map(item => (
                  <Text key={item.ID} margin variant="caption-02" align="left">
                    {item.PARAGRAFO}
                  </Text>
                ))}
              </FeatureAuthorizer>

              <Button
                variant="secondary"
                onClick={fecharModalPrazoDiferimentoExpiradoInfo}
              >
                Certo, entendi!
              </Button>
            </Modal>
          </FeatureAuthorizer>
          <ModalAlert
            title={modalMessage}
            onClose={() => handleCloseModal()}
            open={openModal}
          />
        </Display>
      </RenderConditional>
    </>
  );
};

export default SolicitacaoResgate;
