import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';

export const COLUNAS_TABELA_DADOS_SIMULACAO = [
  {
    name: 'Comparação',
    selector: 'comparacao',
    wrap: true,
    minWidth: '140px',
  },
  {
    name: 'Simulação',
    selector: 'simulacao',
    minWidth: '140px',
  },
  {
    name: 'Estimativa de Renda que você Planejou ao Contratar a Previdência',
    selector: 'estimativaRenda',
    minWidth: '140px',
  },
];

export const COLUNAS_TABELA_HISTORICO_SIMULACAO_RENDA = [
  {
    name: 'Data e Hora',
    selector: 'dthDiaSimulacaoFormatada',
    wrap: true,
    maxWidth: '220px',
    minWidth: '140px',
  },
  {
    name: 'Tipo de Renda',
    selector: 'nomTipoPagamento',
    minWidth: '140px',
  },
  {
    name: 'Período de Recebimento',
    selector: 'descPeridoBeneficiarioRecebe',
    minWidth: '140px',
  },
  {
    name: 'Renda Líquida',
    selector: 'vlrBeneficioLiquido',
    maxWidth: '160px',
    minWidth: '120px',
  },
];

export const TIPO_RENDA_CODIGO: string[] = [
  EnumTipoRendaCodigo.Vitalicia,
  EnumTipoRendaCodigo.Temporaria,
  EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
  EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
  EnumTipoRendaCodigo.PrazoCerto,
];
