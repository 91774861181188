import React, { useContext } from 'react';
import { Text, Button } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import RenderConditional from 'main/components/RenderConditional';
import PrazoRecebimentoRenda from 'previdencia/features/SimulacaoRenda/components/PrazoRecebimentoRenda';
import DadosRendaVitaliciaReversivel from 'previdencia/features/SimulacaoRenda/components/DadosRendaVitaliciaReversivel';
import { TipoRenda } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';
import { retornarTipoRenda } from 'previdencia/features/SimulacaoRenda/utils/simulacaoRenda';
import { InformacoesAdicionaisTipoRendaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import * as S from 'previdencia/features/SimulacaoRenda/components/InformacoesAdicionaisTipoRenda/styles';
import {
  RENDA_COM_PRAZO,
  RENDA_PRAZO_CERTO,
  RENDA_REVERSIVEL,
  RENDA_TEMPORARIA,
  RENDA_VITALICIA,
} from '../../constants/detalhesSimulacao';

const InformacoesAdicionaisTipoRenda: React.FC<
  InformacoesAdicionaisTipoRendaProps
> = ({ proximoServico, voltar, tipoRenda }) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const tiposRendaInformacaoaAdicional: TipoRenda[] = [
    {
      codigo: EnumTipoRendaCodigo.Temporaria,
      tipo: 'temporária',
      descricao: <Text variant="body02-sm">{RENDA_TEMPORARIA.EXEMPLO}</Text>,
    },
    {
      codigo: EnumTipoRendaCodigo.PrazoCerto,
      tipo: 'prazo certo',
      descricao: (
        <>
          <Text variant="body02-sm">{RENDA_PRAZO_CERTO.EXEMPLO_INICIO}</Text>
          <Text variant="body02-sm">{RENDA_PRAZO_CERTO.EXEMPLO_FIM}</Text>
        </>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.Vitalicia,
      tipo: 'vitalícia',
      descricao: <Text variant="body02-sm">{RENDA_VITALICIA.EXEMPLO}</Text>,
    },
    {
      codigo: EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
      tipo: 'vitalícia com prazo mínimo garantido',
      descricao: (
        <>
          <Text variant="body02-sm">{RENDA_COM_PRAZO.EXEMPLO_INICIO}</Text>
          <Text variant="body02-sm">{RENDA_COM_PRAZO.EXEMPLO_FIM}</Text>
        </>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
      tipo: 'vitalícia reversível ao beneficiário',
      descricao: <Text variant="body02-sm">{RENDA_REVERSIVEL.EXEMPLO}</Text>,
    },
  ];

  const retornoTipoRenda = retornarTipoRenda(
    tiposRendaInformacaoaAdicional,
    tipoRenda,
  );

  const labelInputPrazoRecebimentoRenda = (): string => {
    if (tipoRenda === EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido) {
      return 'Digite o prazo mínimo garantido:';
    }

    return 'Por quanto tempo deseja receber a renda?';
  };

  const exibirPrazoRecebimentoRenda = (): boolean => {
    return (
      tipoRenda === EnumTipoRendaCodigo.Temporaria ||
      tipoRenda === EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido ||
      tipoRenda === EnumTipoRendaCodigo.PrazoCerto
    );
  };

  return (
    <>
      <Text variant="body02-lg" margin>
        Tipo de renda: <strong>{retornoTipoRenda.tipo}</strong>
      </Text>

      <S.ContainerInfo>
        <Text variant="body02-sm">
          <strong>Veja como funciona:</strong>
        </Text>
        <Text variant="body02-sm">{retornoTipoRenda.descricao}</Text>
      </S.ContainerInfo>

      <S.Text variant="caption-02" margin>
        * Os cálculos e as hipóteses aqui apresentadas são meras estimativas,
        não se constituindo em garantia ou obrigação da Caixa Vida e
        Previdência.
      </S.Text>

      <RenderConditional condition={exibirPrazoRecebimentoRenda()}>
        <PrazoRecebimentoRenda
          proximoServico={proximoServico}
          voltar={voltar}
          tipoRenda={tipoRenda}
          label={labelInputPrazoRecebimentoRenda}
        />
      </RenderConditional>

      <RenderConditional
        condition={
          tipoRenda === EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario
        }
      >
        <DadosRendaVitaliciaReversivel
          proximoServico={proximoServico}
          voltar={voltar}
          tipoRenda={tipoRenda}
        />
      </RenderConditional>

      <RenderConditional
        condition={tipoRenda === EnumTipoRendaCodigo.Vitalicia}
      >
        <S.ContainerButtons>
          <Button variant="outlined" onClick={voltar}>
            Voltar
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              proximoServico({
                cpf: cpfCnpj,
                numeroCertificado: numCertificado,
                tipoRenda,
              })
            }
          >
            Simular
          </Button>
        </S.ContainerButtons>
      </RenderConditional>
    </>
  );
};

export default InformacoesAdicionaisTipoRenda;
