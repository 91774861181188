import {
  IBeneficiario,
  IDadosBeneficiarioBilheteVida,
} from 'seguros/types/IResponseCertificadosResumo';

export const COLUNAS_TABELA_BENEFICIARIOS_INDIVIDUAL = [
  {
    name: 'Nome',
    selector: 'nome',
    center: true,
  },
  {
    name: 'Grau Parentesco',
    selector: 'grauParentesco',
    center: true,
  },
  {
    name: '% Participação',
    selector: (row: IBeneficiario) => row.percentualParticipacao / 100,
    center: true,
  },
];

export const COLUNAS_TABELA_BENEFICIARIOS_BILHETE = [
  {
    name: 'Nome',
    selector: 'nome',
    center: true,
  },
  {
    name: 'Grau Parentesco',
    selector: 'parentesco',
    center: true,
  },
  {
    name: '% Participação',
    selector: (row: IDadosBeneficiarioBilheteVida) => row.porcentagem / 100,
    center: true,
  },
];

export const NAO_HA_DADOS_TABELA = 'Não há dados para exibir.';

export const enum INFO_DINAMICA_SEGURADO {
  TITULO_PF = 'Dados do Segurado',
  TITULO_PJ = 'Dados da Empresa',
  NOME_PF = 'Nome do Segurado',
  NOME_PJ = 'Nome da Empresa',
}

export const ATUALIZACAO_DADOS_SEGURADO = {
  DESCRICAO: `Altere os dados que estão disponíveis. Caso queira alterar outra
  informação, procure sua Agência da CAIXA.`,
  FORM: {
    LGPD_CVP: `O cliente permite que seus dados pessoais possam ser utilizados
    para ofertar outros produtos da Caixa Vida e Previdência e
    empresas coligadas.`,
    LGPD_PARCEIROS: `O cliente permite que seus dados pessoais possam ser utilizados
    para ofertar outros produtos da Caixa Vida e Previdência e
    empresas coligadas.`,
    LGPD_OBS: `OBS.: Caso Seja realizada marcação nas opções acima referentes à
    LGPD (Gestão de Consentimento), ao clicar em CONFIRMAR, os dados
    não são efetivados automaticamente, sendo necessário aguardar
    prazo de no mínimo 30 minutos.`,
    INFO_CONFIRMACAO:
      'Verifique se os dados estão corretos e clique em CONFIRMAR.',
  },
};

export const VALIDACAO = {
  CAMPO_OBRIGATORIO: 'Campo obrigatório',
  QTD_MINIMA_CEP: 'Mínimo de 8 dígitos',
  CEP_INVALIDO: 'CEP inválido',
  EMAIL_INVALIDO: 'E-MAIL inválido',
  EMAIL_MAX_CHAR_BILHETE: 'O e-mail deve ter no máximo 80 caracteres',
  EMAIL_MAX_CHAR_INDIVIDUAL_EMPRESARIAL:
    'O e-mail deve ter no máximo 40 caracteres',
};

export const DEFAULT_VALUE = {
  DEFAULT_EMPTY_API_SEGURADO: '0',
  QTD_MAXIMA_DIGITOS_CEP: 8,
  MACRO_PROCESSO: 2,
  SUB_PROCESSO_PARCEIROS: '1',
  SUB_PROCESSO_CAIXA: '2',
  NUM_CONSENTIMENTO_NEGOCIO: '40',
  CODIGO_CONSENTIMENTO_SISTEMA: 'LS0116',
  CODIGO_CONSENTIMENTO_MACRO_PROCESSO: 2,
  CODIGO_CONSENTIMENTO_UNIDADE_NEGOCIO: 11,
  CODIGO_CONSENTIMENTO_SUB_PROCESSO_1: 1,
  CODIGO_CONSENTIMENTO_SUB_PROCESSO_2: 2,
  TIPO_CONSENTIMENTO_0: 0,
  TIPO_CONSENTIMENTO_1: 1,
};
