import React from 'react';
import Table from 'main/components/Table';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { TabelaHistoricoSimulacoesProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import { COLUNAS_TABELA_HISTORICO_SIMULACAO_RENDA } from 'previdencia/features/SimulacaoRenda/constants/constants';
import { Button } from './styles';

const TabelaHistoricoSimulacoes: React.FC<TabelaHistoricoSimulacoesProps> = ({
  dados,
  obterDetalhes,
}) => {
  const result =
    dados?.map(
      ({
        dthDiaSimulacaoFormatada,
        dthHoraSimulacaoFormatada,
        vlrBeneficioLiquido,
        nomTipoPagamento,
        seqSimulacao,
        ...rest
      }) => ({
        dthDiaSimulacaoFormatada: `${dthDiaSimulacaoFormatada} - ${dthHoraSimulacaoFormatada}`,
        nomTipoPagamento: (
          <Button variant="text" onClick={() => obterDetalhes(seqSimulacao)}>
            <b>{nomTipoPagamento}</b>
          </Button>
        ),
        vlrBeneficioLiquido: tryGetMonetaryValueOrDefault(vlrBeneficioLiquido),
        ...rest,
      }),
    ) ?? [];

  return (
    <Table
      noHeader
      responsive
      highlightOnHover
      striped
      data={result}
      columns={COLUNAS_TABELA_HISTORICO_SIMULACAO_RENDA}
      noDataComponent="Não há dados para a serem exibidos."
      pagination
      paginationPerPage={10}
      paginationComponentOptions={{
        rowsPerPageText: 'Items por página',
        rangeSeparatorText: 'de',
      }}
    />
  );
};

export default TabelaHistoricoSimulacoes;
