import React, { useContext } from 'react';
import { Text, Divider } from '@cvp/design-system/react';
import Table from 'main/components/Table/Table';
import { ResponseConsultarResumoAliquota } from 'previdencia/features/SolicitacaoResgate/types/ResponseConsultarResumoAliquota';
import {
  ListaDetalhesCalculo,
  ResponseConsultarDetalheCalculo,
} from 'previdencia/features/SolicitacaoResgate/types/ResponseConsultarDetalheCalculo';
import { formatarData } from 'main/utils';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import Print from 'previdencia/components/Print';
import { tipoEmailConstants } from 'main/constants/tipoEmail';
import { AppContext } from 'main/contexts/AppContext';
import HeaderInfoSimulacao from './HeaderInfoSimulacao';
import { totalTabelaCalculo } from '../../factories/solicitacaoResgateFactory';
import {
  simulacaoDetalhadaColumns,
  simulacaoDetalhadaLabels,
} from '../../constants/constants';
import { conditionalRowStyles } from '../../utils/ConditionalRows';
import * as S from './styles';
import TablePrint from '../TablePrint/TablePrint';

type ModalSimulacaoDetalhadaProps = {
  resumoAliquota: ResponseConsultarResumoAliquota | undefined;
  consultaDetalheCalculo: ResponseConsultarDetalheCalculo | undefined;
  valorBruto: number;
  saldo: string;
  open: boolean;
  resgateId?: string;
  handleClose: () => void;
};

const ModalSimulacaoDetalhada: React.FC<ModalSimulacaoDetalhadaProps> = ({
  resumoAliquota,
  consultaDetalheCalculo,
  valorBruto,
  saldo,
  open,
  resgateId,
  handleClose,
}) => {
  const { cliente } = useContext(AppContext);
  const objetoEmail = {
    tipoEmail: tipoEmailConstants.SIMULACAO_RESGATE,
    parametrosEnvio: {
      numeroResgate: resgateId,
      numeroCertificado: cliente.numCertificado,
    },
  };
  function totalListaDetalhesCalculo() {
    const totalArray: ListaDetalhesCalculo[] | undefined = [];

    totalArray.push(totalTabelaCalculo(consultaDetalheCalculo));
    totalArray.push(...(consultaDetalheCalculo?.listaDetalheCalculo || []));

    return totalArray;
  }
  const renderTable = () => {
    return (
      totalListaDetalhesCalculo().map(
        detalheCalculo =>
          ({
            ...detalheCalculo,
            data:
              formatarData(detalheCalculo.data) === 'Invalid Date'
                ? 'Total'
                : formatarData(detalheCalculo.data),
            rendimento: formatarValorPadraoBrasileiro(
              detalheCalculo.rendimento,
            ),
            carregamentoSaida: formatarValorPadraoBrasileiro(
              detalheCalculo.carregamentoSaida,
            ),
            baseIrrf: formatarValorPadraoBrasileiro(detalheCalculo.baseIrrf),
            valorIrrf: formatarValorPadraoBrasileiro(detalheCalculo.valorIrrf),
            aliquotaIrrf: `${Number(detalheCalculo.aliquotaIrrf).toFixed(2)}`,
            taxaSaida: formatarValorPadraoBrasileiro(detalheCalculo.taxaSaida),
            valorLiquido: formatarValorPadraoBrasileiro(
              detalheCalculo.valorLiquido,
            ),
          } as ListaDetalhesCalculo),
      ) ?? []
    );
  };
  return (
    <S.ModalDetalhes show={open} onClose={() => handleClose()}>
      <HeaderInfoSimulacao
        resumoAliquota={resumoAliquota}
        detalhesCalculo={consultaDetalheCalculo}
        saldo={saldo}
        valorBruto={valorBruto}
      />

      <Text variant="headline-08" color="primary" margin>
        {simulacaoDetalhadaLabels.DETALHES_DESCONTOS}
      </Text>
      <TablePrint data={renderTable()} />

      <Table
        className="hide-print"
        data={renderTable()}
        noHeader
        columns={simulacaoDetalhadaColumns}
        noDataComponent="Não há dados para exibir."
        pagination
        paginationPerPage={10}
        paginationComponentOptions={{
          rowsPerPageText: 'Items por página',
          rangeSeparatorText: 'de',
        }}
        conditionalRowStyles={conditionalRowStyles}
      />
      <Divider />
      <S.ContentInfo>
        <Text variant="headline-08" color="primary" margin>
          {simulacaoDetalhadaLabels.IMPORTANTE}
        </Text>

        <Text variant="body03-md" color="text-light" margin>
          {simulacaoDetalhadaLabels.VALORES_ACIMA_ESTIMADOS_SUJEITOS_ALTERACAO}
        </Text>

        <Text variant="body03-md" color="text-light">
          {simulacaoDetalhadaLabels.CENTRAL_RELACIONAMENTO}
        </Text>
      </S.ContentInfo>
      <Print objetoEmail={objetoEmail} />
    </S.ModalDetalhes>
  );
};

export default ModalSimulacaoDetalhada;
