import { ChangeEvent, useCallback, useContext, useState } from 'react';
import {
  Display,
  Text,
  Card,
  Grid,
  Checkbox,
  TextField,
} from '@cvp/design-system/react';
import Table from 'main/components/Table/Table';
import { levelRisk } from 'previdencia/utils/riskStatus';
import { CustomRiskBarStyle } from 'previdencia/features/SolicitacaoResgate/components/CustomRiskBar/styles';
import { FlexContainer } from 'main/styles/GlobalStyle';
import { useHistory, useLocation } from 'react-router';
import {
  ResponsePermitirAlterarValorContribuicaoRegular,
  DadosFundo,
} from 'previdencia/features/SolicitacaoResgate/types/ResponsePermitirAlterarValorContribuicaoRegular';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import useObterDefinicaoContribuicaoRegular from 'previdencia/features/SolicitacaoResgate/hooks/useObterDefinicaoContribuicaoRegular';
import RenderConditional from 'main/components/RenderConditional';
import ButtonAction from 'previdencia/features/SolicitacaoResgate/components/ButtonAction';
import { AppContext } from 'main/contexts/AppContext';
import masks from 'main/utils/masks';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { ListaFundos } from 'previdencia/features/SolicitacaoResgate/types/ListaContribuicao';
import {
  definirValorContribuicaoColumns,
  definirValorContribuicaoLabes,
} from '../constants/constants';
import { obterIdFundo } from '../utils/GerarIdFundo';

const DefinirValorContribuicao: React.FC = () => {
  const history = useHistory();

  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const goHome = useCallback(() => {
    history.replace(`/cliente/produtos/previdencia/solicitacao-resgate`);
  }, [history]);

  const location = useLocation<{
    dadosContribuicao: ResponsePermitirAlterarValorContribuicaoRegular;
    numSolicitacao: string;
  }>();
  const { dadosContribuicao, numSolicitacao } = location.state;

  const [
    fundosValorContribuicaoSelecionado,
    setFundoValorContribuicaoSelecionado,
  ] = useState<DadosFundo[]>(
    dadosContribuicao.dadosFundo.map(dado => ({
      ...dado,
      codReserva: dado.codReserva,
      codFundo: dado.codFundo,
      vlContribuicaoAtual: '0',
      selecionado: false,
    })),
  );

  const {
    atualizarContribuicao: refetch,
    isFetchingDefinicaoContribuicaoRegular,
    isFetchingConfirmacaoResgate,
  } = useObterDefinicaoContribuicaoRegular(
    cpfCnpj,
    numSolicitacao,
    numCertificado,
    fundosValorContribuicaoSelecionado,
  );

  function handleSelecionarFundoValorContribuicao(fundo: ListaFundos) {
    setFundoValorContribuicaoSelecionado(prevFundos =>
      prevFundos?.map(prevfundo =>
        prevfundo.codFundo !== fundo.codFundo
          ? { ...prevfundo }
          : {
              ...prevfundo,
              selecionado: !prevfundo.selecionado,
              vlContribuicaoAtual: !fundo.selecionado
                ? '0'
                : fundo.vlContribuicaoAtual,
            },
      ),
    );
  }

  function handleDistribuicaoContribuicaoMensal(fundo: DadosFundo) {
    setFundoValorContribuicaoSelecionado(prevFundos =>
      prevFundos?.map(prevfundo =>
        prevfundo.codFundo !== fundo.codFundo
          ? { ...prevfundo }
          : { ...prevfundo, ...fundo },
      ),
    );
  }

  const getFundoSelecionadoByItem = (item: DadosFundo) => {
    return fundosValorContribuicaoSelecionado?.find(
      x =>
        obterIdFundo(x.codFundo, x.codReserva) ===
        obterIdFundo(item.codFundo, item.codReserva),
    );
  };

  const parseValorContribuicao = (value: string) => {
    const valueUnmasked = masks.currencyInput.unmask(value);
    if (Number.isNaN(parseFloat(valueUnmasked))) return '0';
    return parseFloat(valueUnmasked) / 100;
  };

  const obterValorTotalContribuicao = () =>
    fundosValorContribuicaoSelecionado
      .map(item =>
        parseFloat(tryGetValueOrDefault([item.vlContribuicaoAtual], '0')),
      )
      .reduce((acc, cur) => acc + cur, 0);

  const obterValorRestante = () => {
    const soma = fundosValorContribuicaoSelecionado
      .map(item =>
        parseFloat(tryGetValueOrDefault([item.vlContribuicaoAtual], '0')),
      )
      .reduce((acc, cur) => acc + cur, 0);
    return parseFloat(dadosContribuicao.valorContribuicaolAtual) - soma;
  };

  const verificarValorContribuicaoInvalido = (item: DadosFundo) => {
    const valorInformadoSuperiorContribuicao =
      obterValorTotalContribuicao() >
      parseFloat(dadosContribuicao.valorContribuicaolAtual);
    const valorInformadoInferiorMinimo =
      parseFloat(
        tryGetValueOrDefault(
          [getFundoSelecionadoByItem(item)?.vlContribuicaoAtual],
          '',
        ),
      ) < parseFloat(dadosContribuicao.valorMinContribuicao);

    return valorInformadoSuperiorContribuicao || valorInformadoInferiorMinimo;
  };

  const checkRedistribuicaoValida = () => {
    const redistribuicaoValida =
      obterValorTotalContribuicao() ===
      parseFloat(dadosContribuicao.valorContribuicaolAtual);

    const fundosSelecionados = fundosValorContribuicaoSelecionado.filter(
      item => item.selecionado,
    );

    const valoresDistribuidosAtendeRegraMinimo = fundosSelecionados.every(
      item =>
        parseFloat(tryGetValueOrDefault([item.vlContribuicaoAtual], '0')) >
        parseFloat(dadosContribuicao.valorMinContribuicao),
    );

    return checkIfAllItemsAreTrue([
      redistribuicaoValida,
      !!fundosSelecionados.length,
      valoresDistribuidosAtendeRegraMinimo,
    ]);
  };

  const renderTable = () => {
    return fundosValorContribuicaoSelecionado.map(item => {
      return {
        ...item,
        nomeFundo: (
          <FlexContainer alignItems="center">
            <Checkbox
              checked={!!item.selecionado}
              value={JSON.stringify({
                item,
              })}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const fundoContribuicaoSelecionado = JSON.parse(e.target.value);

                handleSelecionarFundoValorContribuicao({
                  codFundo: fundoContribuicaoSelecionado.item.codFundo,
                  codReserva: fundoContribuicaoSelecionado.item.codReserva,
                  vlContribuicaoAtual: '0',
                });
              }}
            />
            <Text style={{ marginLeft: 14 }}>{item.nomeFundo}</Text>
          </FlexContainer>
        ),
        perfilFundo: <CustomRiskBarStyle risk={levelRisk(item.perfilFundo)} />,
        contribuicaoMensal: (
          <>
            <RenderConditional
              condition={checkIfAllItemsAreTrue([
                !!item.selecionado,
                Number(dadosContribuicao?.qtdeFundosDisponiveis) === 1,
              ])}
              component={
                <Text>
                  {formatarValorPadraoBrasileiro(
                    dadosContribuicao?.valorContribuicaolAtual,
                  )}
                </Text>
              }
            />
            <RenderConditional
              condition={checkIfAllItemsAreTrue([
                Number(dadosContribuicao?.qtdeFundosDisponiveis) >= 2,
                !!item.selecionado,
              ])}
            >
              <TextField
                value={formatarValorPadraoBrasileiro(
                  getFundoSelecionadoByItem(item)?.vlContribuicaoAtual,
                )}
                error={verificarValorContribuicaoInvalido(item)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleDistribuicaoContribuicaoMensal({
                    ...item,
                    codFundo: item.codFundo,
                    codReserva: item.codReserva,
                    vlContribuicaoAtual: parseValorContribuicao(
                      e.target.value,
                    ).toString(),
                  });
                }}
              />
              <Text variant="caption-02" margin>
                Valor mínimo:{' '}
                {formatarValorPadraoBrasileiro(
                  dadosContribuicao.valorMinContribuicao,
                )}
              </Text>
            </RenderConditional>
          </>
        ),
        rentabilidadeDozeMeses: `${item.rentabilidadeDozeMeses}%`,
      };
    });
  };

  return (
    <Display type="display-block">
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-06" color="primary" margin>
                {definirValorContribuicaoLabes.ESCOLHA_NOVO_FUNDO}
              </Text>
              <Text variant="body02-md" color="text" margin>
                {definirValorContribuicaoLabes.VIMOS_VOCE_INVESTE_VALOR}
                <strong>
                  {formatarValorPadraoBrasileiro(
                    dadosContribuicao.valorContribuicaolAtual,
                  )}
                </strong>{' '}
                {
                  definirValorContribuicaoLabes.EM_QUAIS_FUNDOS_QUER_CONTINUAR_CONTRIBUINDO
                }
              </Text>
            </Grid.Item>

            <Grid.Item xs={1}>
              <Table
                data={renderTable()}
                columns={definirValorContribuicaoColumns}
              />
            </Grid.Item>
            <Grid.Item xs={1}>
              <FlexContainer>
                <RenderConditional
                  condition={
                    obterValorTotalContribuicao() >
                    parseFloat(dadosContribuicao.valorContribuicaolAtual)
                  }
                >
                  <Text variant="body02-md" color="text" margin>
                    Os valores precisam somar:{' '}
                    {formatarValorPadraoBrasileiro(
                      dadosContribuicao.valorContribuicaolAtual,
                    )}
                  </Text>
                </RenderConditional>

                <RenderConditional
                  condition={
                    obterValorTotalContribuicao() <
                    parseFloat(dadosContribuicao.valorContribuicaolAtual)
                  }
                >
                  <Text variant="body02-md" color="text" margin>
                    <strong> Restante: </strong>
                    {formatarValorPadraoBrasileiro(obterValorRestante())}
                  </Text>
                </RenderConditional>
              </FlexContainer>
              <FlexContainer>
                <ButtonAction
                  loading={checkIfSomeItemsAreTrue([
                    isFetchingDefinicaoContribuicaoRegular,
                    isFetchingConfirmacaoResgate,
                  ])}
                  titleButtonLeft="Cancelar"
                  titleButtonRight="Confirmar"
                  disabled={checkIfSomeItemsAreTrue([
                    isFetchingDefinicaoContribuicaoRegular,
                    isFetchingConfirmacaoResgate,
                    !checkRedistribuicaoValida(),
                  ])}
                  onClickButtonRight={() => refetch()}
                  onClickButtonLeft={() => goHome()}
                />
              </FlexContainer>
            </Grid.Item>
          </Grid>
        </Card.Content>
      </Card>
    </Display>
  );
};

export default DefinirValorContribuicao;
