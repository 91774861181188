import React from 'react';
import { Text } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import { StatusResgate } from 'previdencia/types/StatusResgate';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import { checkIfSomeItemsAreTrue } from 'main/utils/conditional';
import { informativoInicio } from '../../constants/constants';
import { ISolicitacaoResgateContextData } from '../../types/ISolicitacaoResgateContextData';

type TextoInformativoProps = {
  dataSolicitacao: string;
};
const TextoInformativoInicio: React.FC<TextoInformativoProps> = ({
  dataSolicitacao,
}) => {
  const { featureData } =
    usePrevidenciaContext<ISolicitacaoResgateContextData>();
  return (
    <>
      <Text variant="headline-06" color="primary" margin>
        Solicitação de Resgate
      </Text>
      <RenderConditional
        condition={checkIfSomeItemsAreTrue([
          featureData?.statusResgate === StatusResgate.SOLICITADO,
          featureData?.statusResgate === StatusResgate.VALIDADO,
        ])}
      >
        <Text variant="body02-md" margin>
          Operação realizada com sucesso em {dataSolicitacao}. O resgate será
          pago em até 5 dias úteis.
        </Text>
        <Text variant="body02-md" margin>
          {informativoInicio.IMPRESSAO}
        </Text>
      </RenderConditional>
      <RenderConditional
        condition={featureData?.statusResgate === StatusResgate.PENDENTE}
      >
        <Text variant="body02-md" margin>
          Não foi possível concluir a solicitação de resgate.
        </Text>
        <Text variant="body02-md" margin>
          {featureData?.motivoPendenciaResgate}
        </Text>
      </RenderConditional>
      <RenderConditional
        condition={
          featureData?.statusResgate === StatusResgate.AGUARDANDO_ASSINATURA
        }
      >
        <Text variant="body02-md" margin>
          Solicitação de resgate recebida com sucesso.
        </Text>
        <Text variant="body02-md">
          A efetivação da transação ocorrerá somente após a conclusão do
          processo de assinatura eletrônica.
        </Text>
      </RenderConditional>
    </>
  );
};
export default TextoInformativoInicio;
