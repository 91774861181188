import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { formatarData } from 'main/utils/date';
import * as S from '../../../../../main/styles/GlobalStyle';
import { ResponseConsultarResumoAliquota } from '../../types/ResponseConsultarResumoAliquota';
import { ResponseConsultarDetalheCalculo } from '../../types/ResponseConsultarDetalheCalculo';

type ResumoResgateProps = {
  dadosResgate: ResponseConsultarResumoAliquota | undefined;
  consultaDetalheCalculo: ResponseConsultarDetalheCalculo | undefined;
  saldo: string;
};

const ResumoResgate: React.FC<ResumoResgateProps> = ({
  dadosResgate,
  consultaDetalheCalculo,
  saldo,
}) => {
  return (
    <S.ContainerLista>
      <S.ContainerCinza>
        <Text variant="body02-sm">Saldo em: {formatarData(new Date())}:</Text>
        <Text variant="body02-sm">{formatarValorPadraoBrasileiro(saldo)}</Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Produto e Modalidade:</Text>
        <Text variant="body02-sm">
          {dadosResgate?.desProduto}, {dadosResgate?.tipProduto}
        </Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Valor simulado:</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(
            consultaDetalheCalculo?.totalValorSolicitado,
          )}
        </Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Valor nominal estimado:</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(dadosResgate?.totalSaldoNominal)}
        </Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Valor do IR estimado:</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(dadosResgate?.totalValorIrrf)}
        </Text>
      </S.ContainerCinza>
      <S.ContainerBranco>
        <Text variant="body02-sm">Taxa de carregamento na saída:</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(
            consultaDetalheCalculo?.totalCarregamentoSaida,
          )}
        </Text>
      </S.ContainerBranco>
      <S.ContainerCinza>
        <Text variant="body02-sm">Valor líquido estimado:</Text>
        <Text variant="body02-sm">
          {formatarValorPadraoBrasileiro(
            consultaDetalheCalculo?.totalValorLiquido,
          )}
        </Text>
      </S.ContainerCinza>
    </S.ContainerLista>
  );
};

export default ResumoResgate;
