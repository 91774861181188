import styled from 'styled-components/macro';
import Input from 'main/components/form/Input';
import Select from 'main/components/form/Select';

export const WrapperMessageInfo = styled.div(({ theme }) => ({
  padding: 20,
  borderRadius: 8,
  border: `1px solid ${theme.color.brandPrimary.light}`,
  marginBottom: 28,
}));

export const WrapperInput = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
}));

export const TextInput = styled.p<{ isMarginLeft?: boolean }>(
  ({ isMarginLeft }) => ({
    color: '#414042',
    marginRight: 10,
    marginLeft: isMarginLeft ? 12 : 0,
    fontWeight: '500',
    fontSize: 18,
    lineHeight: '160%',
  }),
);

export const InputFormBank = styled(Input)<{ width?: number }>(({ width }) => ({
  width: width || 270,
}));

export const Separador = styled.div(() => ({
  marginLeft: 3,
  marginRight: 3,
  width: 8,
  height: 1,
  backgroundColor: '#414042',
}));

export const SelectInstituicaoBancaria = styled(Select)<{ width?: number }>(
  ({ width }) => ({
    marginLeft: 16,
    width: width || 400,
  }),
);
