import React, { useContext } from 'react';
import { Text, Button, Grid } from '@cvp/design-system/react';
import useFieldLink from 'main/hooks/useFieldLink';
import { AppContext } from 'main/contexts/AppContext';
import { Input } from 'main/components/form';
import { intervalValue, required } from 'main/features/Validation/validations';
import { ValidaInputIntervalValue } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import { PrazoRecebimentoRendaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import * as S from 'previdencia/features/SimulacaoRenda/components/PrazoRecebimentoRenda/styles';

const PrazoRecebimentoRenda: React.FC<PrazoRecebimentoRendaProps> = ({
  proximoServico,
  voltar,
  tipoRenda,
  label,
}) => {
  const { cliente } = useContext(AppContext);

  const [valorLink, validarValor] = useFieldLink('');

  const rangePrazoMaximo =
    tipoRenda === EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido
      ? 15
      : 35;

  const rangePrazoMinimo = tipoRenda === EnumTipoRendaCodigo.PrazoCerto ? 1 : 5;

  const validacaoInput: ValidaInputIntervalValue = {
    min: rangePrazoMinimo,
    max: rangePrazoMaximo,
  };

  const simularPrazoRenda = (): void => {
    if (validarValor().isValid) {
      proximoServico({
        cpf: cliente?.cpfCnpj,
        numeroCertificado: cliente?.numCertificado,
        tipoRenda,
        qtdPrazoAnos: valorLink.get().value,
      });
    }
  };

  return (
    <>
      <Grid>
        <Grid.Item xs={1} md={1 / 3}>
          <Text variant="caption-01" color="text-light">
            {label()}
          </Text>

          <Input
            link={valorLink}
            placeholder="Digite o prazo..."
            validationRules={[
              required(),
              intervalValue(() => '', validacaoInput),
            ]}
            legend={`valores entre ${rangePrazoMinimo} e ${rangePrazoMaximo} anos`}
          />
        </Grid.Item>
      </Grid>

      <S.ContainerButtons>
        <Button variant="outlined" onClick={voltar}>
          Voltar
        </Button>
        <Button variant="primary" onClick={simularPrazoRenda}>
          Simular
        </Button>
      </S.ContainerButtons>
    </>
  );
};

export default PrazoRecebimentoRenda;
