import { useState, useEffect } from 'react';
import { Card, Display, Text } from '@cvp/design-system/react';
import { UseQueryResult } from 'react-query';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import RenderConditional from 'main/components/RenderConditional';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import { Beneficios } from 'previdencia/types/Beneficiarios';
import HistoricoSimulacoes from 'previdencia/features/SimulacaoRenda/components/HistoricoSimulacoes';
import IndicacaoTipoRenda from 'previdencia/features/SimulacaoRenda/components/IndicacaoTipoRenda';
import InformacoesAdicionaisTipoRenda from 'previdencia/features/SimulacaoRenda/components/InformacoesAdicionaisTipoRenda';
import ResultadoSimulacao from 'previdencia/features/SimulacaoRenda/components/ResultadoSimulacao';
import SimulacaoRendaNaoPermitida from 'previdencia/features/SimulacaoRenda/components/SimulacaoRendaNaoPermitida';
import { RequestSimularBeneficioApi } from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';
import useConsultarDadosBasicos from 'previdencia/features/SimulacaoRenda/hooks/useConsultarDadosBasicos';
import { useObterCoberturas } from 'previdencia/features/SimulacaoRenda/hooks/useObterDadosCoberturas';
import { TIPO_RENDA_CODIGO } from 'previdencia/features/SimulacaoRenda/constants/constants';
import { ISimulacaoRendaContextData } from 'previdencia/features/SimulacaoRenda/types/ISimulacaoRendaContextData';
import { ResponseConsultarDadosBasicos } from 'previdencia/features/SimulacaoRenda/types/dadosBasicos';
import {
  EnumTipoRendaCodigo,
  EtapasFluxoSimulacaoRenda,
} from 'previdencia/features/SimulacaoRenda/types/enum';

const SimulacaoRenda = (): React.ReactElement => {
  const {
    certificado,
    cpfCnpj,
    featureData,
    setFeatureData,
    clearFeatureData,
  } = usePrevidenciaContext<ISimulacaoRendaContextData>();

  const { response: responseCoberturas, ObterCoberturas } =
    useObterCoberturas();

  const {
    data: dadosBasicos,
    isLoading: loadingDadosBasicos,
    error,
    isError,
  }: UseQueryResult<
    ResponseConsultarDadosBasicos | undefined
  > = useConsultarDadosBasicos();

  const mensagemErroSimulacaoNaoPermitida = error as Error;

  const coberturasPorTipoRenda: Beneficios[] | undefined =
    responseCoberturas?.retorno?.beneficios?.filter(cobertura => {
      return TIPO_RENDA_CODIGO.includes(cobertura.codTipoPagamentoOriginal);
    });

  const [etapaAtual, setEtapaAtual] = useState<number>(
    EtapasFluxoSimulacaoRenda.INICIO_SIMULACAO,
  );
  const [tipoRenda, setTipoRenda] = useState<string>();
  const [requestSimularRenda, setRequestSimularRenda] =
    useState<RequestSimularBeneficioApi>();

  const receberTipoRenda = (codigoTipoRenda: string): void => {
    const coberturaContratada = coberturasPorTipoRenda?.find(
      cobertura => cobertura.codTipoPagamentoOriginal === codigoTipoRenda,
    );

    const calculoAutomaticoSimulacao =
      coberturaContratada &&
      codigoTipoRenda !== EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario;

    if (calculoAutomaticoSimulacao) {
      setRequestSimularRenda({
        cpf: cpfCnpj,
        numeroCertificado: certificado,
        tipoRenda: codigoTipoRenda,
        qtdPrazoAnos: coberturaContratada.termoDesejado || '',
      });
      setEtapaAtual(EtapasFluxoSimulacaoRenda.RESULTADO_SIMULACAO);
    } else {
      setTipoRenda(codigoTipoRenda);
      setEtapaAtual(EtapasFluxoSimulacaoRenda.CALCULO_PRE_SIMULACAO);
    }
  };

  const simularRenda = (request: RequestSimularBeneficioApi): void => {
    setRequestSimularRenda(request);
    setEtapaAtual(EtapasFluxoSimulacaoRenda.RESULTADO_SIMULACAO);
  };

  const exibirHistoricoSimulacoes = (): void => {
    setEtapaAtual(EtapasFluxoSimulacaoRenda.HISTORICO_SIMULACAO);
  };

  const voltarTiposRenda = (): void => {
    setEtapaAtual(EtapasFluxoSimulacaoRenda.INICIO_SIMULACAO);
  };

  useEffect(() => {
    ObterCoberturas();
  }, []);

  useEffect(() => {
    setFeatureData({
      ...featureData,
      etapaAtual,
      tipoRenda,
      requestSimularRenda,
      coberturasPorTipoRenda,
    });

    return clearFeatureData;
  }, [etapaAtual, tipoRenda, requestSimularRenda, responseCoberturas]);

  const fluxosTela = [
    <IndicacaoTipoRenda
      proximoServico={receberTipoRenda}
      dadosBasicos={dadosBasicos}
      loadingDadosBasicos={loadingDadosBasicos}
    />,
    <InformacoesAdicionaisTipoRenda
      proximoServico={simularRenda}
      voltar={voltarTiposRenda}
      tipoRenda={tipoRenda}
    />,
    <ResultadoSimulacao
      request={requestSimularRenda}
      exibirHistorico={exibirHistoricoSimulacoes}
    />,
    <HistoricoSimulacoes
      voltar={voltarTiposRenda}
      irResultadoSimulacao={simularRenda}
    />,
  ];

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredRoles={[...PRINCIPAL_USERS, USER_PROFILES.ANALISTA_SAIDA]}
        requiredPermissions={[PREV_PERMISSIONS.SIMULADOR_DE_RENDA]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Text variant="headline-05" color="primary" margin>
              Simulação de Renda
            </Text>
            <RenderConditional condition={!isError}>
              {fluxosTela[etapaAtual]}
            </RenderConditional>

            <RenderConditional condition={isError}>
              <SimulacaoRendaNaoPermitida
                mensagemErro={mensagemErroSimulacaoNaoPermitida?.message}
              />
            </RenderConditional>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
    </Display>
  );
};

export default SimulacaoRenda;
