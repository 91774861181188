import React from 'react';
import {
  Display,
  Card,
  Text,
  Grid,
  Divider,
  Button,
} from '@cvp/design-system/react';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';
import * as CONST from '../constants/InformacoesIRPF';

export const InformacoesIRPF: React.FC = () => {
  const { goDadosSeguradoVida } = useVidaNavigation();
  return (
    <Display type="display-inline">
      <Card>
        <Card.Content padding={[4, 4, 4]}>
          <Grid>
            <Grid.Item xs={1}>
              <Text variant="headline-06" color="primary" margin>
                {CONST.TITULO_IRPF}
              </Text>
            </Grid.Item>
          </Grid>
          <Text variant="body01-md" color="text-light" margin>
            {CONST.PARA_FINS.INICIO} <u>{CONST.PARA_FINS.MORTE}</u>{' '}
            {CONST.PARA_FINS.OU} <u>{CONST.PARA_FINS.INVALIDEZ}</u>{' '}
            {CONST.PARA_FINS.PERMANENTE} <u>{CONST.PARA_FINS.NAO_PRECISAM}</u>{' '}
            {CONST.PARA_FINS.FIM}
            <br />
            <br />
            {CONST.PARA_AQUELES.INICIO} <u>{CONST.PARA_AQUELES.DESPESAS}</u>{' '}
            {CONST.PARA_AQUELES.MEIO} <u>{CONST.PARA_AQUELES.MORTE}</u> ou{' '}
            <u>{CONST.PARA_AQUELES.INVALIDEZ}</u> {CONST.PARA_AQUELES.FIM}{' '}
            <b>
              <a href={CONST.PARA_AQUELES.SITE_DA_RECEITA_LINK}>
                ({CONST.PARA_AQUELES.SITE_DA_RECEITA})
              </a>
            </b>
            .
          </Text>

          <Divider />

          <Display>
            <Button variant="outlined" onClick={goDadosSeguradoVida}>
              Voltar
            </Button>

            <Button variant="secondary" onClick={window.print}>
              Imprimir
            </Button>
          </Display>
        </Card.Content>
      </Card>
    </Display>
  );
};
