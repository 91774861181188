import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { IDadosResgate } from 'previdencia/features/SolicitacaoResgate/types/ResponseListaFundosReserva';
import { ResponseSimularRetirada } from 'previdencia/features/SolicitacaoResgate/types/ResponseSimularRetirada';
import { TIPOS_RESGATE } from '../constants/constants';

type tipoResgateSelecionado = {
  tipoResgate: string;
  fundoId: string;
};
export const retornaTipoResgateSelecionado = (
  fundoId: string,
  tipoResgateSelecionadoArray: tipoResgateSelecionado[],
): string => {
  const tipoResfateSelecionado = tipoResgateSelecionadoArray.find(
    item => item.fundoId === fundoId,
  );
  if (!tipoResfateSelecionado?.tipoResgate) {
    return 'P';
  }
  return tipoResfateSelecionado?.tipoResgate;
};

export const isCheckedTipoResgate = (
  fundoId: string,
  tipoResgateSelecionadoArray: tipoResgateSelecionado[],
): boolean => {
  const isCheckTipoResgate = tipoResgateSelecionadoArray.find(
    item => item.fundoId === fundoId,
  );
  if (!isCheckTipoResgate?.tipoResgate) {
    return false;
  }
  return isCheckTipoResgate.tipoResgate === TIPOS_RESGATE.TOTAL;
};

export const retornaTipoResgateSelecionadoFundoId = (
  fundoId: string,
  tipoResgateSelecionadoArray: tipoResgateSelecionado[],
): string => {
  const tipoResfateSelecionado = tipoResgateSelecionadoArray.find(
    item => item.fundoId === fundoId,
  );
  if (!tipoResfateSelecionado?.fundoId) {
    return '';
  }
  return tipoResfateSelecionado?.fundoId;
};

export const converterValoresResgateParaFloat = (
  item: IDadosResgate,
  valorRetirada: string,
) => {
  const valorBrutoParsed =
    parseFloat(
      masks.currencyInput.unmask(tryGetValueOrDefault([valorRetirada], '0')),
    ) / 100;
  const valorSaldoParsed = parseFloat(
    tryGetValueOrDefault([item.valorSaldo], '0'),
  );
  const valorMinimoSaldoParsed = parseFloat(
    tryGetValueOrDefault([item.valorMinimoSaldo], '0'),
  );

  return { valorBrutoParsed, valorSaldoParsed, valorMinimoSaldoParsed };
};

export const verificarCriterioSaldoMinimo = (item: IDadosResgate) => {
  const { valorBrutoParsed, valorMinimoSaldoParsed, valorSaldoParsed } =
    converterValoresResgateParaFloat(item, item.valorBruto || '');
  if (
    item.tipoResgate === TIPOS_RESGATE.TOTAL &&
    valorSaldoParsed < valorMinimoSaldoParsed
  ) {
    return false;
  }
  return (
    item.tipoResgate === TIPOS_RESGATE.PARCIAL &&
    valorBrutoParsed > valorSaldoParsed - valorMinimoSaldoParsed
  );
};

export const isValidaSaldoMinimo = (
  item: IDadosResgate,
  valorBruto: string,
): boolean => {
  const { valorBrutoParsed, valorMinimoSaldoParsed, valorSaldoParsed } =
    converterValoresResgateParaFloat(item, valorBruto);

  if (valorBrutoParsed < 50) return false;

  if (valorBrutoParsed > valorSaldoParsed) return false;

  if (
    item.tipoResgate === TIPOS_RESGATE.PARCIAL &&
    valorBrutoParsed > valorSaldoParsed
  ) {
    return false;
  }

  if (
    item.tipoResgate === TIPOS_RESGATE.PARCIAL &&
    valorBrutoParsed > valorSaldoParsed - valorMinimoSaldoParsed
  ) {
    return false;
  }

  return true;
};

export const isChecked = (
  idSelecionado: string,
  fundoSelecionado: IDadosResgate[],
): boolean => {
  const isCheck = fundoSelecionado.find(
    item => item.idSelecionado === idSelecionado,
  );
  return !!isCheck;
};

export const retornaFundoIdSelecionado = (
  fundoId: string,
  fundoSelecionado: IDadosResgate[],
): string => {
  const fundoIdSelecionado = fundoSelecionado.find(
    item => item.idSelecionado === fundoId,
  );
  if (!fundoIdSelecionado?.idSelecionado) {
    return '';
  }
  return fundoIdSelecionado?.idSelecionado;
};

export function verificarCarencia(
  simulacaoRetirada: ResponseSimularRetirada | undefined,
): boolean {
  const isCarencia = [];

  simulacaoRetirada?.detalhesResgate.forEach(item => {
    if (item.razao) {
      isCarencia.push(item.razao);
    }
  });

  return isCarencia.length > 0;
}

export const getFloatParamUnMasked = (valor?: string) => {
  return (
    parseFloat(masks.currencyInput.unmask(tryGetValueOrDefault([valor], '0'))) /
    100
  );
};
