import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Display, Button, Text } from '@cvp/design-system/react';
import { useContratosPrestamistaContext } from 'contratosPrestamista/contexts/ContratosPrestamistaContext';
import Table from 'main/components/Table';
import SkeletonLoading from 'main/components/SkeletonLoading';
import StepBarWrapper from 'contratosPrestamista/features/dpseletronica/components/StepBarWrapper';
import * as ConditionalUtils from 'main/utils/conditional';
import { useAuth } from 'main/features/Auth/hooks';
import ModalGerenciarSocios from 'contratosPrestamista/components/ModalGerenciarSocios';
import UploadProposta from 'contratosPrestamista/features/dpseletronica/components/UploadProposta';
import {
  MENSAGEM_TABELA_DADOS,
  PARAMETROS_DPS,
} from 'contratosPrestamista/constants/parametrosDps';
import useFieldLink from 'main/hooks/useFieldLink';
import { validate } from 'main/features/Validation/utils/validateRules';
import * as Helpers from 'contratosPrestamista/features/dpseletronica/utils/helpers';
import * as Factories from 'contratosPrestamista/features/dpseletronica/factories';
import * as TypesDPS from 'contratosPrestamista/features/dpseletronica/types';
import { usePecoFluxoPJ } from 'contratosPrestamista/features/dpseletronica/hooks/usePecoFluxoPJ';
import { ISubscricaoSocio } from 'contratosPrestamista/types';
import { usePecoSolicitarDps } from 'contratosPrestamista/hooks/usePecoSolicitarDps';
import { usePecoInserirAtualizarPropostaDps } from 'contratosPrestamista/hooks/usePecoAtualizarStatusPropostaDps';
import { useVerificarNecessidadeDps } from 'contratosPrestamista/hooks/useVerificarNecessidadeDps';
import { useAdicionarSocio } from 'contratosPrestamista/hooks/useAdicionarSocio';
import { IDadosBasicoProposta } from 'contratosPrestamista/types/IDadosBasicoProposta';
import { GerarColunasTabelaSociosPJ } from '../constants/ColunasTabelaSociosPJ';
import { toastError } from 'main/hooks/useToast';
import { IEntradaUploadDocumentoFluxoDps } from 'contratosPrestamista/types/IEntradaUploadDocumentoFluxoDps';
import { TipoPessoaEnum } from 'contratosPrestamista/features/dpsEletronicaPostecipado/constants/EnumTipoPessoa';

const FluxoPJ = () => {
  const history = useHistory();
  const [arquivoProposta, validateArquivoProposta] = useFieldLink<FileList>(
    {} as FileList,
  );
  const { user } = useAuth();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalValidarSocio, setOpenModalValidarSocio] =
    useState<boolean>(false);
  const [openModalAdicionarSocio, setModalAdicionarSocios] =
    useState<boolean>(false);
  const { featureData, setFeatureData } =
    useContratosPrestamistaContext<TypesDPS.IDpsEletronicaFeatureDataFluxoPJ>();
  const [dadosSocios, setDadosSocios] = useState<ISubscricaoSocio[]>(
    [] as ISubscricaoSocio[],
  );
  const [validarSocio, setValidarSocio] = useState<ISubscricaoSocio>(
    {} as ISubscricaoSocio,
  );
  const service = usePecoFluxoPJ();
  const serviceSolicitarDps = usePecoSolicitarDps();
  const serviceAtualizarDps = usePecoInserirAtualizarPropostaDps();
  const serviceVerificarNecessidadeDps = useVerificarNecessidadeDps();
  const serviceAdicionarSocio = useAdicionarSocio();

  const [dpsEnviada, setDpsEnviada] = useState<boolean>(false);
  const [listaEnvioDps, setListaEnvioDps] = useState<TypesDPS.IDadosEnvioDps[]>(
    [],
  );

  const algunsSociosnecessitaDps = dadosSocios.some(
    x => x.necessitaDps === true,
  );

  const enviarDps = async () => {
    await Promise.all(
      dadosSocios.map(async item => {
        if (item.necessitaDps) {
          const payloadDadosBasicoProposta: IDadosBasicoProposta = {
            capitalSegurado: item.capitalSegurado,
            cpf: item.cpf,
            numeroProposta: featureData?.dadosContrato?.codContrato,
            valorAcumulo: item.valorAcumulo,
            tipoPessoa: TipoPessoaEnum.juridica,
            cnpj: item.cnpj,
            dataNascimento: item.dataNascimento,
            email: item.email,
            nomeSocio: item.nome,
            nomeEmpresa:
              service.responseConsultarSubscricao?.entidade?.resumo.at(0)
                ?.razaoSocial,
          };
          await serviceAdicionarSocio.incluirSocioSmart(
            payloadDadosBasicoProposta,
            item.necessitaDps,
            '0',
          );
        }
        return item;
      }),
    );
    const resultadoEnvioDps = await Promise.all(
      dadosSocios.map(async item => {
        if (item.necessitaDps) {
          const resultadoSolicitacaoDps =
            await serviceSolicitarDps.fecthSolicitarDps(
              Factories.construirPayloadSolicitarDpsFluxoPJ(item, {
                ...featureData,
                codigoUsuario: user.nomeAcesso,
                valorAcumuloPJ: item.valorAcumulo,
              }),
            );

          setListaEnvioDps(prevState => [
            ...prevState,
            {
              cpf: item.cpf,
              isDPSReqSuccessfully: !!resultadoSolicitacaoDps?.entidade,
            },
          ]);

          return resultadoSolicitacaoDps;
        }
        return item;
      }),
    );

    setDpsEnviada(resultadoEnvioDps.some(x => x?.sucessoBFF === true));
  };

  const obterValorImportanciaSegurada = async (cpf: string) => {
    const result = await service.fetchDataImportanciaSegurada({
      cpfCnpjCliente: cpf,
    });

    return Helpers.converterValorContrato(
      result?.entidade?.valorTotalContratos,
    );
  };

  const mapearDadosSocios = async (socios: ISubscricaoSocio[]) => {
    const subscricaoSocios = await Promise.all(
      socios.map(async item => {
        const novoItem = item;
        const valorContrato = await obterValorImportanciaSegurada(item.cpf);
        const capitalSegurado = Helpers.calcularValorCapitalSeguradoIndividual(
          featureData?.dadosContrato?.capitalSegurado,
          socios.length,
        );
        const valorAcumulo = Helpers.calcularValorAcumulo(
          valorContrato,
          capitalSegurado,
        );
        if (!novoItem.dpsVerificada) {
          const payloadDadosBasicoProposta: IDadosBasicoProposta = {
            capitalSegurado,
            cpf: novoItem.cpf,
            numeroProposta: featureData?.dadosContrato?.codContrato,
            valorAcumulo,
            tipoPessoa: TipoPessoaEnum.juridica,
            cnpj: novoItem.cnpj,
          };
          const resultNecessitaDps =
            await serviceVerificarNecessidadeDps.verificarExigenciaDps(
              payloadDadosBasicoProposta,
            );
          novoItem.necessitaDps = resultNecessitaDps.necessitaDps;
          novoItem.dpsVerificada = true;
          novoItem.validado = !!novoItem.novoSocio;
          novoItem.listaDpsExistentes = resultNecessitaDps.lista;
        }

        return {
          ...Helpers.mapearEnderecoSocio(novoItem),
          capitalSegurado,
          valorAcumulo,
        };
      }),
    );
    setDadosSocios(subscricaoSocios);
  };
  const obterDadosSubscricao = async () => {
    const result = await service.fetchConsultarSubscricao({
      cnpj: featureData?.dadosContrato?.cpfCnpj,
      idProduto: PARAMETROS_DPS.ID_PRODUTO,
    });

    await mapearDadosSocios(
      ConditionalUtils.tryGetValueOrDefault(
        [result?.entidade?.subscricaoSocios],
        [] as ISubscricaoSocio[],
      ),
    );
  };

  const handleModalValidarSocio = (socio: unknown) => {
    setOpenModalValidarSocio(!openModalValidarSocio);
    setValidarSocio(socio as ISubscricaoSocio);
    setDadosSocios(Helpers.obterListaSociosValidado(socio, dadosSocios));
  };

  const handleAdicionarSocio = async (socio: unknown) => {
    setModalAdicionarSocios(!openModalAdicionarSocio);
    const socioCast = socio as ISubscricaoSocio;
    const socioItem = dadosSocios.find(x => x.numCpfSocio === socioCast.cpf);
    if (socioItem) {
      toastError('O sócio informado já encontra-se cadastrado!');
      return;
    }
    const novosSocios = Helpers.obterRetornoAdicionarSocio(
      socio,
      dadosSocios,
      featureData?.dadosContrato?.cpfCnpj,
    );
    await mapearDadosSocios(novosSocios);
  };

  const handleExcluirSocio = async (cpf: string) => {
    const sociosUpdated = dadosSocios
      .filter(x => x.cpf !== cpf)
      .map(item => ({ ...item, dpsVerificada: false }));
    await mapearDadosSocios(sociosUpdated);
    setOpenModalValidarSocio(false);
  };

  const handleOpenValidarSocio = (socio: ISubscricaoSocio) => {
    setValidarSocio(socio);
    setOpenModalValidarSocio(true);
  };
  const colunasTabelaSocios = useMemo(
    () =>
      GerarColunasTabelaSociosPJ({
        dadosSocios,
        handleOpenValidarSocio,
      }),
    [dadosSocios],
  );

  useEffect(() => {
    obterDadosSubscricao();
  }, []);

  if (
    ConditionalUtils.checkIfSomeItemsAreTrue([
      serviceAtualizarDps.loadingAtualizarPropostaDps,
      serviceVerificarNecessidadeDps.loadingVerificarNecessidadeDps,
      service.loadingConsultarSubscricao,
      service.loadingObterIs,
    ])
  )
    return <SkeletonLoading />;
  return (
    <Display type="display-block">
      <StepBarWrapper>
        <Text variant="headline-06" style={{ marginTop: 20, marginBottom: 20 }}>
          Validação de dados/Envio de DPS
        </Text>

        <Table
          responsive
          className="disableReactDataTableOverflow"
          columns={colunasTabelaSocios}
          data={dadosSocios}
          pagination
          paginationPerPage={10}
          paginationComponentOptions={{
            rowsPerPageText: 'Items por página',
            rangeSeparatorText: 'de',
          }}
          noDataComponent={MENSAGEM_TABELA_DADOS}
        />

        <Button
          variant="outlined"
          onClick={() => setModalAdicionarSocios(!openModalAdicionarSocio)}
        >
          Adicionar Sócio
        </Button>
        <br />
        <br />

        <UploadProposta
          openModal={openModal}
          dadosContrato={ConditionalUtils.tryGetValueOrDefault(
            [featureData?.dadosContrato],
            {} as IEntradaUploadDocumentoFluxoDps,
          )}
          handleCloseModal={() => setOpenModal(false)}
          dpsEnviada={dpsEnviada}
          listaEnvioDps={listaEnvioDps}
          executeAction={enviarDps}
          loadingSolicitarDps={ConditionalUtils.checkIfSomeItemsAreTrue([
            serviceSolicitarDps.loadingSolicitarDps,
            serviceAdicionarSocio.loadingIncluirSocioSmart,
          ])}
          arquivoProposta={arquivoProposta}
        />
        <Display style={{ marginTop: '1.5rem' }}>
          <br />
          <Button
            variant="outlined"
            onClick={() => {
              setFeatureData({
                ...featureData,
                etapas: ['Identificação', 'Upload/DPS'],
                etapaAtual: 1,
                showStepBar: true,
              });

              history.push('/prestamista/dps/identificacao');
            }}
          >
            Voltar
          </Button>

          <Button
            disabled={ConditionalUtils.checkIfAllItemsAreTrue([
              !arquivoProposta.get().value.item?.name,
              !algunsSociosnecessitaDps,
            ])}
            onClick={() => {
              if (validate([validateArquivoProposta])) {
                setOpenModal(true);
              }
            }}
          >
            {ConditionalUtils.getTernaryResult(
              algunsSociosnecessitaDps,
              'Enviar link DPS',
              'Concluir',
            )}
          </Button>
        </Display>

        <ModalGerenciarSocios
          dadosPessoa={validarSocio}
          loading={service.loadingConsultarSubscricao}
          openModal={openModalValidarSocio}
          handleExcluirSocio={handleExcluirSocio}
          handleSubmitForm={handleModalValidarSocio}
          handleClose={() => setOpenModalValidarSocio(false)}
          tipo="VALIDAR"
          disableValidate={!validarSocio.necessitaDps}
        />

        <ModalGerenciarSocios
          dadosPessoa={{} as ISubscricaoSocio}
          handleClose={() => setModalAdicionarSocios(!openModalAdicionarSocio)}
          handleSubmitForm={handleAdicionarSocio}
          loading={service.loadingConsultarSubscricao}
          openModal={openModalAdicionarSocio}
          tipo="ADICIONAR"
          disableDelete
        />
      </StepBarWrapper>
    </Display>
  );
};

export default FluxoPJ;
