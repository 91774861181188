import React, { useState, ChangeEvent, useEffect } from 'react';
import { Display, Card, Text, Radio } from '@cvp/design-system/react';
import { useHistory, useLocation } from 'react-router-dom';
import ButtonAction from 'previdencia/features/SolicitacaoResgate/components/ButtonAction';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import { ParamsLocationData } from 'previdencia/features/SolicitacaoResgate/types/ParamsLocationData';
import { motivoSolictacaoResgateLabels } from '../constants/constants';

const MotivoSolicitacaoResgate: React.FC = () => {
  const history = useHistory();
  const { navigateTo } = usePrevNavigation();

  const location = useLocation<ParamsLocationData>();
  const {
    resgateId,
    dadosResgate,
    valorBrutoRetirada,
    beneficioId,
    planoId,
    saldo,
  } = location.state;

  const [motivoSolicitacao, setMotivoSolicitacao] = useState('');
  const [disabledButtonNext, setDisabledButtonNext] = useState(true);

  const backRouter = () => {
    history.replace(`/cliente/produtos/previdencia/solicitacao-resgate`);
  };

  useEffect(() => {
    if (!motivoSolicitacao) {
      setDisabledButtonNext(true);
      return;
    }

    setDisabledButtonNext(false);
  }, [motivoSolicitacao]);

  const nextRouter = () => {
    navigateTo('efetuar-resgate', {
      dadosResgate,
      valorBrutoRetirada,
      planoId,
      resgateId,
      beneficioId,
      saldo,
    });
  };

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <Card>
        <Card.Content xs={1}>
          <Text variant="headline-05" color="primary" margin>
            {motivoSolictacaoResgateLabels.SOLICITACAO_RESGATE}
          </Text>

          <Text variant="body01-lg" color="text" margin>
            {motivoSolictacaoResgateLabels.PESQUISA_COM_CLIENTE}
          </Text>

          <Text variant="body01-md" color="primary" margin>
            {motivoSolictacaoResgateLabels.MOTIVO_RESGATE}
          </Text>

          <Display alignItems="center">
            <Radio
              name="group"
              value="dificuldade-financeira"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMotivoSolicitacao(e.target.value)
              }
            />
            <Text variant="caption-03">
              {motivoSolictacaoResgateLabels.DIFICULDADE_FINANCEIRA}
            </Text>
          </Display>

          <Display alignItems="center">
            <Radio
              name="group"
              value="insatisfacao-com-a-venda"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMotivoSolicitacao(e.target.value)
              }
            />
            <Text variant="caption-03">
              {motivoSolictacaoResgateLabels.INSATISFACAO_VENDA}
            </Text>
          </Display>

          <Display alignItems="center">
            <Radio
              name="group"
              value="encerrou-o-relacionamento-com-a-caixa"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMotivoSolicitacao(e.target.value)
              }
            />
            <Text variant="caption-03">
              {motivoSolictacaoResgateLabels.ENCERROU_RELACIONAMENTO_CAIXA}
            </Text>
          </Display>

          <Display alignItems="center">
            <Radio
              name="group"
              value="problemas-de-saude"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMotivoSolicitacao(e.target.value)
              }
            />
            <Text variant="caption-03">
              {motivoSolictacaoResgateLabels.PROBLEMAS_SAUDE}
            </Text>
          </Display>

          <Display alignItems="center">
            <Radio
              name="group"
              value="baixa-rentabilidade"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMotivoSolicitacao(e.target.value)
              }
            />
            <Text variant="caption-03">
              {motivoSolictacaoResgateLabels.BAIXA_RENTABILIDADE}
            </Text>
          </Display>

          <Display alignItems="center">
            <Radio
              name="group"
              value="mau-atendimento-agencia-central"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMotivoSolicitacao(e.target.value)
              }
            />
            <Text variant="caption-03">
              {motivoSolictacaoResgateLabels.MEU_ATENDIMENTO}
            </Text>
          </Display>

          <Display alignItems="center">
            <Radio
              name="group"
              value="outros-negocios"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setMotivoSolicitacao(e.target.value)
              }
            />
            <Text variant="caption-03">
              {motivoSolictacaoResgateLabels.OUTROS_NEGOCIOS}
            </Text>
          </Display>

          <ButtonAction
            titleButtonLeft="Cancelar"
            titleButtonRight="Continuar"
            onClickButtonLeft={() => backRouter()}
            onClickButtonRight={() => nextRouter()}
            disabled={disabledButtonNext}
          />
        </Card.Content>
      </Card>
    </Display>
  );
};

export default MotivoSolicitacaoResgate;
