import { useToast } from 'main/hooks/useToast';
import {
  checkIfAllItemsAreTrue,
  getTernaryResult,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import {
  CODIGO_BANCO_CAIXA,
  formatarContaSeBancoCaixa,
} from 'main/utils/formatarNumeroContaCaixa';
import { useHandleReponseResgate } from 'previdencia/features/SolicitacaoResgate/hooks/useHandleResponseResgate';
import { usePecoObterSolicitacaoResgate } from 'previdencia/features/SolicitacaoResgate/hooks/usePecoObterSolicitacaoResgate';
import { obterConfirmacaoResgate } from 'previdencia/features/SolicitacaoResgate/services/confirmarResgate.api';
import { obterPermitirAlterarValorContribuicaoRegular } from 'previdencia/features/SolicitacaoResgate/services/permitirAlterarValorContribuicaoRegular';
import { DadosBancarios } from 'previdencia/features/SolicitacaoResgate/types/DadosBancarios';
import { ParamsLocationData } from 'previdencia/features/SolicitacaoResgate/types/ParamsLocationData';
import { PayloadSolicitacaoResgate } from 'previdencia/features/SolicitacaoResgate/types/PayloadSolicitacaoResgate';
import { DadosResgate } from 'previdencia/features/SolicitacaoResgate/types/ResponseListaFundosReserva';
import { ResponsePermitirAlterarValorContribuicaoRegular } from 'previdencia/features/SolicitacaoResgate/types/ResponsePermitirAlterarValorContribuicaoRegular';
import { DetalhesPagamento } from 'previdencia/features/SolicitacaoResgate/types/ResponseSimularRetirada';
import { UseObterSolicitacaoResgateReturn } from 'previdencia/features/SolicitacaoResgate/types/UseObterSolicitacaoResgateReturn';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import * as ValidarContaApi from 'previdencia/services/validarConta.api';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const useObterSolicitacaoResgate = (
  cpf: string,
  numCertificado: string,
  beneficioId: string,
  planoId: string,
  resgateId: string,
  resgateData: DadosResgate[],
): UseObterSolicitacaoResgateReturn => {
  const [motivo, setMotivo] = useState<string | undefined>(undefined);
  const [isSuccessRequest, setIsSuccessRequest] = useState(true);
  const [contaBancariaSelecionada, setContaBancariaSelecionada] =
    useState<DadosBancarios | null>(null);
  const { navigateTo } = usePrevNavigation();
  const location = useLocation<ParamsLocationData>();
  const { handleResponseResgate, exibirMensagensFeedbackResgate } =
    useHandleReponseResgate();
  const dadosResgateArray: DadosResgate[] = [];
  const dadosTipoPagamentoArray: DetalhesPagamento[] = [];
  const [mensagemErro, setMensagemErro] = useState<string | undefined>();
  const [executandoResgate, setExecutandoResgate] = useState(false);
  const { toastError } = useToast();
  const setMensagemTemporariaErro = (mensagem: string | undefined) => {
    setMensagemErro(mensagem);
    setTimeout(() => {
      setMensagemErro(undefined);
    }, 10000);
  };

  const { fetchData: obterSolicitacaoResgateApi } =
    usePecoObterSolicitacaoResgate();

  const setMensagemErroValidacaoConta = () => {
    setMensagemErro(
      'Não foi possível validar a conta bancária, verifique se o cliente é o titular da conta ou se a conta existe',
    );
  };

  const validarConta = async (): Promise<boolean> => {
    try {
      const deveValidarConta =
        contaBancariaSelecionada?.numeroBanco === CODIGO_BANCO_CAIXA &&
        contaBancariaSelecionada?.contaNova;
      if (!deveValidarConta) {
        setMensagemErro(undefined);
        return true;
      }

      const resultadoValidacao = await ValidarContaApi.validarConta(
        contaBancariaSelecionada?.cpfCnpj ?? '',
        contaBancariaSelecionada?.numeroAgencia ?? '',
        contaBancariaSelecionada?.tipoContaBanco ?? '',
        contaBancariaSelecionada?.digitoConta ?? '',
        contaBancariaSelecionada?.numeroBanco ?? '',
        contaBancariaSelecionada?.numeroConta ?? '',
      );

      if (resultadoValidacao?.codigoRetorno === '00') {
        setMensagemErro(undefined);
        return true;
      }

      setMensagemErroValidacaoConta();
      return false;
    } catch (exception: unknown) {
      setMensagemErroValidacaoConta();
      return false;
    }
  };

  const invocarSolicitacaoResgate = async (): Promise<boolean> => {
    try {
      const payload: PayloadSolicitacaoResgate = {
        cpfCnpj: cpf,
        numeroCertificado: numCertificado,
        beneficioId,
        planoId,
        resgateId,
        codigoMotivo: String(motivo),
        dadosResgate: dadosResgateArray,
        dadosTipoPagamento: dadosTipoPagamentoArray,
      };
      const result = await obterSolicitacaoResgateApi(payload);
      const sucesso = checkIfAllItemsAreTrue([
        Boolean(result?.sucessoBFF),
        Boolean(result?.sucessoGI),
      ]);

      if (
        checkIfAllItemsAreTrue([
          !sucesso,
          Boolean(result?.mensagens),
          Boolean(result?.mensagens?.length),
        ])
      ) {
        const mensagem = result?.mensagens?.at(0)?.descricao;
        setMensagemTemporariaErro(mensagem);
        toastError(mensagem);
        setIsSuccessRequest(false);
        return false;
      }
      setIsSuccessRequest(true);
      return true;
    } catch (exception: unknown) {
      if (exception instanceof Error)
        setMensagemTemporariaErro(exception.message);
      if (typeof exception === 'string') setMensagemTemporariaErro(exception);
      return false;
    }
  };

  const invocarVerificacaoPermissaoContribuicaoRegular =
    async (): Promise<ResponsePermitirAlterarValorContribuicaoRegular | null> => {
      try {
        const retornoInvocacao =
          await obterPermitirAlterarValorContribuicaoRegular(
            resgateId,
            cpf,
            numCertificado,
          );
        return retornoInvocacao ?? null;
      } catch (exception: unknown) {
        if (exception instanceof Error)
          setMensagemTemporariaErro(exception.message);
        if (typeof exception === 'string') setMensagemTemporariaErro(exception);
        return null;
      }
    };

  const invocarConfirmacaoResgate = async (): Promise<{
    sucesso: boolean;
    status: string;
    mensagemSucesso?: string;
  }> => {
    try {
      const responseConfirmarResgate = await obterConfirmacaoResgate(
        cpf,
        resgateId,
      );
      return handleResponseResgate(responseConfirmarResgate);
    } catch (exception: unknown) {
      if (exception instanceof Error)
        setMensagemTemporariaErro(exception.message);
      if (typeof exception === 'string') setMensagemTemporariaErro(exception);
      return {
        sucesso: false,
        status: '',
      };
    }
  };

  const converterCodigoOperacaoParaSigla = (codigoOperacao: string): string => {
    try {
      const numeroCodigoOperacao = parseInt(codigoOperacao, 10);
      if (
        numeroCodigoOperacao === 1 ||
        numeroCodigoOperacao === 3 ||
        numeroCodigoOperacao === 23 ||
        numeroCodigoOperacao === 1292 ||
        numeroCodigoOperacao === 3701
      )
        return 'CC';
      if (
        numeroCodigoOperacao === 13 ||
        numeroCodigoOperacao === 22 ||
        numeroCodigoOperacao === 1288 ||
        numeroCodigoOperacao === 3702
      )
        return 'CP';
      return '';
    } catch (exception: unknown) {
      if (exception instanceof Error)
        setMensagemTemporariaErro(exception?.message);
      if (typeof exception === 'string') setMensagemTemporariaErro(exception);
      return '';
    }
  };

  const solicitarResgate = async () => {
    setExecutandoResgate(true);
    dadosResgateArray.push(...resgateData);

    dadosTipoPagamentoArray.push({
      ...contaBancariaSelecionada,
      digitoAgencia: contaBancariaSelecionada?.digitoAgencia || '',
      nomeBanco: contaBancariaSelecionada?.nomeBanco,
      tipoContaBanco: converterCodigoOperacaoParaSigla(
        tryGetValueOrDefault([contaBancariaSelecionada?.tipoContaBanco], ''),
      ),
      numeroConta: getTernaryResult(
        !!contaBancariaSelecionada?.contaNova,
        formatarContaSeBancoCaixa({
          codigoBanco: tryGetValueOrDefault(
            [contaBancariaSelecionada?.numeroBanco],
            '',
          ),
          agencia: tryGetValueOrDefault(
            [contaBancariaSelecionada?.numeroAgencia],
            '',
          ),
          numeroConta: tryGetValueOrDefault(
            [contaBancariaSelecionada?.numeroConta],
            '',
          ),
          operacao: tryGetValueOrDefault(
            [contaBancariaSelecionada?.tipoContaBanco],
            '',
          ),
        }),
        tryGetValueOrDefault([contaBancariaSelecionada?.numeroConta], ''),
      ),
    });

    if (!(await validarConta())) {
      setExecutandoResgate(false);
      return;
    }

    if (!(await invocarSolicitacaoResgate())) {
      setExecutandoResgate(false);
      return;
    }

    const resultadoVerificacaoPermissaoContribuicao =
      await invocarVerificacaoPermissaoContribuicaoRegular();
    if (resultadoVerificacaoPermissaoContribuicao === null) {
      setExecutandoResgate(false);
      return;
    }

    if (
      resultadoVerificacaoPermissaoContribuicao.permiteDefinirContribRegular ===
      'true'
    ) {
      const { dadosResgate, valorBrutoRetirada } = location.state;
      navigateTo('definir-valor-contribuicao', {
        dadosContribuicao: resultadoVerificacaoPermissaoContribuicao,
        numSolicitacao: resgateId,
        dadosResgate,
        valorBrutoRetirada,
        resgateId,
        beneficioId,
        planoId,
      });
    }
    if (
      resultadoVerificacaoPermissaoContribuicao.permiteDefinirContribRegular ===
      'false'
    ) {
      const { sucesso, status, mensagemSucesso } =
        await invocarConfirmacaoResgate();
      if (!sucesso) {
        setExecutandoResgate(false);
        return;
      }
      exibirMensagensFeedbackResgate(sucesso, status, mensagemSucesso);
    }

    setExecutandoResgate(false);
  };

  const handleSelectContaBancaria = (value: DadosBancarios | null): void => {
    const contaBancaria = value;
    if (contaBancaria) contaBancaria.cpfCnpj = cpf;
    setContaBancariaSelecionada(contaBancaria);
  };

  return {
    contaBancariaSelecionada,
    handleSelectContaBancaria,
    solicitarResgate,
    executandoResgate,
    mensagemErro,
    isSuccessRequest,
    motivo,
    setMotivo,
  };
};

export default useObterSolicitacaoResgate;
