import React from 'react';
import { Text } from '@cvp/design-system/react';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { TextoRendaMensalLiquidaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import { validarPrimeiraStr } from 'previdencia/features/SimulacaoRenda/utils/validarPrimeiraStr';
import { retornarTipoRenda } from 'previdencia/features/SimulacaoRenda/utils/simulacaoRenda';
import { TipoRenda } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import * as S from 'previdencia/features/SimulacaoRenda/components/TextoRendaMensalLiquida/styles';

const TextoRendaMensalLiquida: React.FC<TextoRendaMensalLiquidaProps> = ({
  dados,
}) => {
  const textoPorTipoPagamento: TipoRenda[] = [
    {
      codigo: EnumTipoRendaCodigo.PrazoCerto,
      tipo: 'prazo certo',
      descricao: (
        <Text variant="body02-sm" margin>
          Os beneficiários receberão o valor integral
        </Text>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
      tipo: 'vitalícia com prazo mínimo garantido',
      descricao: (
        <Text variant="body02-sm" margin>
          Os beneficiários receberão o valor integral
        </Text>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
      tipo: 'vitalícia reversível ao beneficiário',
      descricao: (
        <Text variant="body02-sm" margin>
          O beneficiário receberá a renda mensal de
        </Text>
      ),
    },
  ];

  const retornoTipoRenda = retornarTipoRenda(
    textoPorTipoPagamento,
    dados?.codTipoPagamento,
  );

  return (
    <>
      <Text variant="body02-sm" margin>
        <strong>No caso do seu falecimento</strong>
      </Text>
      <S.ContainerInfo>
        {retornoTipoRenda?.descricao}
        <S.ContainerInfoValor>
          <Text variant="headline-08">
            {formatarValorPadraoBrasileiro(dados?.vlrReversao)}
          </Text>
          <Text variant="body02-sm">
            {validarPrimeiraStr(
              dados?.descPeriodoAnosBeneficiarioRecebe,
              'por até ',
            )}
          </Text>
        </S.ContainerInfoValor>
      </S.ContainerInfo>
    </>
  );
};

export default TextoRendaMensalLiquida;
