import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const Content = styled.div`
  width: 45%;
  height: 50%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
`;

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const WrapperWarning = styled.div`
  width: 100px;
  height: 100px;
`;

export const WrapperText = styled.div`
  margin-top: 20px;
  text-align: center;
`;

export const WrapperClose = styled.div`
  display: flex;
  align-self: flex-end;
  cursor: pointer;
`;
