import styled from 'styled-components/macro';

export const Container = styled.div(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginTop: 40,
}));

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const WrapperItem = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 30,
}));

export const TextItem = styled.p(() => ({
  color: '#808288',
  marginRight: 20,
  fontSize: 16,
  fontWeight: '600',
}));
