import React from 'react';
import { Text, Button, Modal } from '@cvp/design-system/react';
import { ReactComponent as Warnner } from 'assets/img/icon_info_m.svg';
import * as S from './styles';

type ModalAlertProps = {
  onClose: () => void;
  title: string;
  open: boolean;
};

export const ModalAlert: React.FC<ModalAlertProps> = ({
  title,
  onClose,
  open,
}) => {
  return (
    <Modal show={open} onClose={onClose}>
      <S.WrapperContent>
        <S.WrapperWarning>
          <Warnner />
        </S.WrapperWarning>
        <S.WrapperText>
          <Text variant="body02-md" margin>
            {title}
          </Text>
        </S.WrapperText>
        <Button variant="secondary" onClick={onClose}>
          Fechar
        </Button>
      </S.WrapperContent>
    </Modal>
  );
};

export default ModalAlert;
