import styled from 'styled-components/macro';

export const ContentResgate = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingLeft: 12,
});

export const ContentResgateItem = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 36,
}));

export const ResgateInfoText = styled.p<{ isMarginRight?: boolean }>(
  ({ isMarginRight }) => ({
    marginRight: isMarginRight ? 36 : 0,
    color: '#808288',
    textTransform: 'uppercase',
    fontSize: 16,
  }),
);

export const WrapperButtonConfirma = styled.div(() => ({
  marginLeft: 26,
}));

export const WrapperValorSimulado = styled.p(() => ({
  marginBottom: 26,
  display: 'flex',
  alignItems: 'center',
}));

export const TextValorSimulcao = styled.p({
  marginRight: 16,
  color: '#808288',
  textTransform: 'uppercase',
  fontSize: 16,
  fontWeight: '600',
});

export const WrapperValoreDetalhadoButton = styled.div(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
}));

export const TextInfoEfetuarResgate = styled.p(() => ({
  fontSize: 20,
  marginRight: 16,
  color: '#808288',
}));

export const WrapperSectionEfetuarResgate = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 20,
}));

export const WrapperCheckBox = styled.div(() => ({
  paddingLeft: 16,
  marginTop: 20,
  display: 'flex',
  alignItems: 'center',
}));

export const TextCheckBox = styled.h4(() => ({
  fontSize: 18,
  color: '#414042',
  marginLeft: 8,
  fontWeight: '500',
  lineHeight: '160%',
}));
