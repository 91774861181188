import { api, IApiResponse } from 'main/services';
import {
  obterEndpointContasBancarias,
  obterEndpointRecuperarListaBancos,
} from 'previdencia/config/endpoints';
import { ResponseContasBancarias } from 'previdencia/features/SolicitacaoResgate/types/ResponseContasBancarias';
import { DadosInstituicaoBancaria } from 'previdencia/features/SolicitacaoResgate/types/DadosInstituicaoBancaria';

export const obterContasBancarias = async (
  cpfCnpj: string,
  resgateId?: string,
): Promise<ResponseContasBancarias[] | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseContasBancarias[]>>(
    obterEndpointContasBancarias(),
    {
      cpfCnpj,
      resgateId,
    },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data?.dados?.entidade;
};

export const obterListaBancos = async (
  cpfCnpj: string,
): Promise<DadosInstituicaoBancaria[] | undefined> => {
  const { data } = await api.post<IApiResponse<DadosInstituicaoBancaria[]>>(
    obterEndpointRecuperarListaBancos(),
    {
      cpfCnpj,
    },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data?.dados?.entidade?.map(dadosBanco => {
    if (dadosBanco.codigoBanco?.toString().length < 3) {
      return {
        ...dadosBanco,
        codigoBanco: dadosBanco.codigoBanco.toString().padStart(3, '0'),
      };
    }
    return {
      ...dadosBanco,
      codigoBanco: dadosBanco.codigoBanco.toString(),
    };
  });
};
