import { formatarData, formatarDataHora } from 'main/utils';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { tryGetMonetaryValueOrDefault } from 'main/utils/money';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import {
  DadosSimulacao,
  RequestSimularBeneficioApi,
  ResponseSimularBeneficios,
} from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';
import { validarGenero } from 'previdencia/features/SimulacaoRenda/utils/validarGenero';

export const preencherDadosSimulacao = (
  dados: ResponseSimularBeneficios | undefined,
  request: RequestSimularBeneficioApi | undefined,
): DadosSimulacao[] => {
  const dadosTabela: DadosSimulacao[] = [];

  dadosTabela.push({
    comparacao: 'Data da simulação',
    estimativaRenda: tryGetValueOrDefault(
      [formatarData(dados?.dthInicioGozoOriginal)],
      '--',
    ),
    simulacao: formatarDataHora(new Date().toString()),
  });

  dadosTabela.push({
    comparacao: 'Reserva considerada',
    estimativaRenda: tryGetMonetaryValueOrDefault(dados?.vlrReservaOriginal),
    simulacao: tryGetMonetaryValueOrDefault(dados?.vlrReserva),
  });

  dadosTabela.push({
    comparacao: 'Renda líquida',
    estimativaRenda: tryGetMonetaryValueOrDefault(
      dados?.vlrBeneficioLiquidoOriginal,
    ),
    simulacao: tryGetMonetaryValueOrDefault(dados?.vlrBeneficioLiquido),
  });

  dadosTabela.push({
    comparacao: 'Renda bruta',
    estimativaRenda: tryGetMonetaryValueOrDefault(dados?.vlrBeneficioOriginal),
    simulacao: tryGetMonetaryValueOrDefault(dados?.vlrBeneficio),
  });

  dadosTabela.push({
    comparacao: 'Desconto do IR',
    estimativaRenda: tryGetMonetaryValueOrDefault(dados?.vlrIRRFOriginal),
    simulacao: tryGetMonetaryValueOrDefault(dados?.vlrIRRF),
  });

  dadosTabela.push({
    comparacao: 'Tempo para receber a renda',
    estimativaRenda: tryGetValueOrDefault(
      [dados?.descPeridoBeneficiarioRecebeOriginal],
      '--',
    ),
    simulacao: tryGetValueOrDefault(
      [dados?.descPeridoBeneficiarioRecebe],
      '--',
    ),
  });

  dadosTabela.push({
    comparacao: 'Beneficiários receberão renda em caso do seu falecimento?',
    estimativaRenda: tryGetValueOrDefault(
      [dados?.descBeneficiarioRecebeOriginal],
      '--',
    ),
    simulacao: tryGetValueOrDefault([dados?.descBeneficiarioRecebe], '--'),
  });

  if (
    dados?.codTipoPagamento !== EnumTipoRendaCodigo.Vitalicia &&
    dados?.codTipoPagamento !== EnumTipoRendaCodigo.Temporaria
  ) {
    dadosTabela.push({
      comparacao: 'Por quanto tempo?',
      estimativaRenda: dados?.descPeriodoAnosBeneficiarioRecebeOriginal || '--',
      simulacao: dados?.descPeriodoAnosBeneficiarioRecebe || '--',
    });

    dadosTabela.push({
      comparacao: 'Quanto da renda?',
      estimativaRenda: dados?.descPctBeneficiarioRecebeOriginal || '--',
      simulacao: dados?.descPctBeneficiarioRecebe || '--',
    });
  }

  if (
    dados?.codTipoPagamento ===
    EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario
  ) {
    dadosTabela.push({
      comparacao: 'Data de Nascimento do Beneficiário',
      estimativaRenda: '--',
      simulacao: formatarData(request?.dthNascimentoConjuge) || '--',
    });

    dadosTabela.push({
      comparacao: 'Sexo',
      estimativaRenda: '--',
      simulacao: validarGenero(request?.staGeneroConjuge) || '--',
    });
  }

  dadosTabela.push({
    comparacao: 'Tipo de renda',
    estimativaRenda: tryGetValueOrDefault(
      [dados?.nomTipoPagamentoOriginal],
      '--',
    ),
    simulacao: tryGetValueOrDefault([dados?.nomTipoPagamento], '--'),
  });

  return dadosTabela;
};
