import { api, IApiResponse } from 'main/services';
import { obterEndpointSimularRetirada } from 'previdencia/config/endpoints';
import { DadosResgate } from 'previdencia/features/SolicitacaoResgate/types/ResponseListaFundosReserva';
import { ResponseSimularRetirada } from 'previdencia/features/SolicitacaoResgate/types/ResponseSimularRetirada';

export const obterSimulacaoRetirada = async (
  cpfCnpj: string,
  numeroCertificado: string,
  beneficioId: string,
  planoId: string,
  dadosResgate: DadosResgate[],
): Promise<ResponseSimularRetirada | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseSimularRetirada>>(
    obterEndpointSimularRetirada(),
    {
      cpfCnpj,
      numeroCertificado,
      beneficioId,
      planoId,
      dadosResgate,
    },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data?.dados?.entidade;
};
