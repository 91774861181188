import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  DefaultValuesSimulacaoRenda,
  EnumTipoRendaCodigo,
} from 'previdencia/features/SimulacaoRenda/types/enum';
import { RequestSimularBeneficioApi } from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';

export const payloadSendEmailSimularRendaFactory = (
  request: RequestSimularBeneficioApi | undefined,
) => {
  if (
    request?.tipoRenda === EnumTipoRendaCodigo.PrazoCerto ||
    request?.tipoRenda === EnumTipoRendaCodigo.Temporaria ||
    request?.tipoRenda === EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido
  ) {
    return {
      qtdPrazoAnos: request?.qtdPrazoAnos,
    };
  }

  if (
    request?.tipoRenda === EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario
  ) {
    return {
      pctReversao: tryGetValueOrDefault(
        [request?.pctReversao],
        DefaultValuesSimulacaoRenda.PACOTE_REVERSAO,
      ),
      dthNascimentoConjuge: tryGetValueOrDefault(
        [request?.dthNascimentoConjuge],
        '',
      ),
      staGeneroConjuge: tryGetValueOrDefault([request?.staGeneroConjuge], ''),
      cpfBeneficiario: tryGetValueOrDefault(
        [request?.cpfBeneficiario],
        DefaultValuesSimulacaoRenda.CPF_BENEFICIARIO,
      ),
    };
  }

  return {};
};
