import React from 'react';
import { Text, Button, Grid, Display } from '@cvp/design-system/react';
import RenderConditional from 'main/components/RenderConditional';
import TagStatusRenda from 'previdencia/features/SimulacaoRenda/components/TagStatusRenda';
import { retornarTipoRenda } from 'previdencia/features/SimulacaoRenda/utils/simulacaoRenda';
import { TipoRenda } from 'previdencia/features/SimulacaoRenda/types/tiposRenda';
import { TiposRendaProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import * as S from 'previdencia/features/SimulacaoRenda/components/TiposRenda/styles';

const TiposRenda: React.FC<TiposRendaProps> = ({
  proximaTela,
  dados,
  planoContratado,
}) => {
  const realizarSimulacaoRenda = (codigoTipoRenda: string) => {
    proximaTela(codigoTipoRenda);
  };

  const TIPOS_RENDA_SIMULACAO_RENDA: TipoRenda[] = [
    {
      codigo: EnumTipoRendaCodigo.Temporaria,
      tipo: 'Temporária',
      descricao: (
        <>
          <Text variant="body01-sm">
            O cliente receberá uma renda mensal pelo prazo que escolheu.
          </Text>
          <Text variant="body01-sm">
            Esse benefício termina no caso de seu falecimento ou caso o prazo
            acabe.
          </Text>
        </>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.PrazoCerto,
      tipo: 'Prazo certo',
      descricao: (
        <>
          <Text variant="body01-sm">
            O cliente receberá uma renda mensal pelo prazo que escolheu.
          </Text>
          <Text variant="body01-sm">
            Caso venha a falecer durante esse período, seus beneficiários
            receberão a sua renda até que o prazo termine.
          </Text>
        </>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.Vitalicia,
      tipo: 'Vitalícia',
      descricao: (
        <>
          <Text variant="body01-sm">
            O cliente receberá uma renda mensal enquanto viver.
          </Text>
          <Text variant="body01-sm">
            Esse benefício só termina no caso de seu falecimento.
          </Text>
        </>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido,
      tipo: 'Vitalícia com prazo mínimo garantido',
      descricao: (
        <>
          <Text variant="body01-sm">
            O cliente receberá uma renda mensal enquanto viver.
          </Text>
          <Text variant="body01-sm">
            Caso venha a falecer, seus beneficiários receberão a sua renda por
            um prazo.
          </Text>
          <Text variant="body01-sm">
            Esse prazo começa a contar assim que transformar a sua reserva em
            renda.
          </Text>
        </>
      ),
    },
    {
      codigo: EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario,
      tipo: 'Vitalícia reversível ao beneficiário',
      descricao: (
        <>
          <Text variant="body01-sm">
            O cliente receberá uma renda mensal enquanto viver.
          </Text>
          <Text variant="body01-sm">
            Caso venha a falecer, apenas um beneficiário receberá a sua renda
            enquanto ele viver. Essa renda pode ser integral ou parcial.
          </Text>
        </>
      ),
    },
  ];

  return (
    <>
      <Text variant="body02-md" color="primary" margin>
        Tipos de Renda
      </Text>

      <S.ContainerTiposRenda>
        {dados?.map(e => {
          const renda = retornarTipoRenda(
            TIPOS_RENDA_SIMULACAO_RENDA,
            e?.codTipoPagamento,
          );

          return (
            <S.ContainerTiposRendaItem>
              <Grid.Item xs={1} md={2 / 10}>
                <Display alignItems="center">
                  <Text variant="lead" margin>
                    <strong>{renda?.tipo}</strong>
                  </Text>
                  <RenderConditional
                    condition={e.codTipoPagamento === planoContratado}
                    component={<TagStatusRenda status="1" />}
                  />
                </Display>
              </Grid.Item>

              <Grid.Item xs={1} lg={6 / 10}>
                <Display alignItems="center">
                  <Text variant="lead">{renda?.descricao}</Text>
                </Display>
              </Grid.Item>

              <Grid.Item xs={1} lg={2 / 10}>
                <Display alignItems="center">
                  <Button
                    variant="secondary"
                    onClick={() => realizarSimulacaoRenda(e.codTipoPagamento)}
                  >
                    Simular
                  </Button>
                </Display>
              </Grid.Item>
            </S.ContainerTiposRendaItem>
          );
        })}
      </S.ContainerTiposRenda>
    </>
  );
};

export default TiposRenda;
