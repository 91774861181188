import React, { ChangeEvent, useContext, useState } from 'react';
import {
  Display,
  Checkbox,
  Text,
  Button,
  Grid,
} from '@cvp/design-system/react';
import { FlexContainer } from 'main/styles/GlobalStyle';
import Table from 'main/components/Table/Table';
import {
  formatarValorPadraoBrasileiro,
  tryGetMonetaryValueOrDefault,
} from 'main/utils/money';
import RenderConditional from 'main/components/RenderConditional';
import masks from 'main/utils/masks';
import {
  IDadosResgate,
  IResponseListaFundosReservaRetorno,
} from 'previdencia/features/SolicitacaoResgate/types/ResponseListaFundosReserva';
import {
  isChecked,
  isValidaSaldoMinimo,
  retornaFundoIdSelecionado,
  retornaTipoResgateSelecionado,
  verificarCarencia,
  verificarCriterioSaldoMinimo,
} from 'previdencia/features/SolicitacaoResgate/utils/ValidarParametrosResgate';
import useFundo from 'previdencia/features/SolicitacaoResgate/hooks/useFundo';
import { gerarColumns } from 'previdencia/features/SolicitacaoResgate/utils/CriarColunasTabelaFundo';
import { AppContext } from 'main/contexts/AppContext';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import {
  checkIfAllItemsAreTrue,
  checkIfSomeItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import * as S from './styles';
import { resgateInfoLabels, TIPOS_RESGATE } from '../../constants/constants';
import {
  disabledInputResgate,
  errorInputResgate,
} from '../../utils/ValidarInputResgate';
import { ISolicitacaoResgateContextData } from '../../types/ISolicitacaoResgateContextData';
import {
  calculaValorRestante,
  desabilitarBotaoSimular,
  subValorBruto,
  sumValorBruto,
} from '../../utils/OperacoesValorFundo';
import { ResponseValorMininoMaximo } from '../../types/ResponseValorMininoMaximo';

type ResgateInfoProps = {
  fundo?: IResponseListaFundosReservaRetorno;
  valorRetirada: string;
  valorSaldo: string | undefined;
  atualizaInputValorResgatado: React.Dispatch<React.SetStateAction<boolean>>;
  valorMinimoMaximo: ResponseValorMininoMaximo | undefined;
};

const ResgateInfo: React.FC<ResgateInfoProps> = ({
  fundo,
  valorRetirada,
  valorSaldo,
  atualizaInputValorResgatado,
  valorMinimoMaximo,
}) => {
  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);
  const { featureData } =
    usePrevidenciaContext<ISolicitacaoResgateContextData>();
  const {
    dadosResgateArr,
    fundoSelecionado,
    handleSelecionarFundo,
    handleSimularResgate,
    handleTipoTransferencia,
    obterChaveIdentificacaoFundo,
    isFetching,
    isLoading,
    messageAlert,
    simulacaoRetirada,
    tipoResgateSelecionadoArray,
    setDadosResgateArr,
  } = useFundo(
    cpfCnpj,
    numCertificado,
    featureData?.coberturaId,
    featureData?.planoId,
    valorRetirada,
    valorSaldo,
    fundo?.detalhesResgate,
  );
  const desabilitaBotaoSimularRegraSaldoMinimo =
    tryGetValueOrDefault(
      [dadosResgateArr?.filter(x => !x.requisitoSaldoMinimoAtendido)],
      [],
    ).length > 0;
  const [selectedInput, setSelectedInput] = useState<string | null>(null);

  const habilitaOpcaoResgateTotal = (valorSaldoFundo?: string) => {
    const valorRetiradaParsed = parseFloat(valorRetirada) / 100;
    const valorSaldoFundoParsed = parseFloat(
      tryGetValueOrDefault([valorSaldoFundo], '0'),
    );

    if (!valorSaldoFundo) return false;
    if (featureData?.isMonofundo && featureData.resgateParcial) return false;
    if (
      !featureData?.isMonofundo &&
      valorRetiradaParsed >= valorSaldoFundoParsed
    ) {
      return true;
    }
    if (featureData?.resgateTotal) return true;
    return false;
  };

  const handleSelecionarCheckboxFundo = (
    idFundo: string,
    idReserva: string,
  ) => {
    const fundoEstavaSelecionado = dadosResgateArr?.find(
      x =>
        obterChaveIdentificacaoFundo(x.idFundo, x.idReserva) ===
          obterChaveIdentificacaoFundo(idFundo, idReserva) && x.isDisabled,
    );
    const dadosAtualizados = dadosResgateArr?.map(x => {
      if (
        obterChaveIdentificacaoFundo(x.idFundo, x.idReserva) ===
        obterChaveIdentificacaoFundo(idFundo, idReserva)
      ) {
        return {
          ...x,
          tipoResgate: !featureData?.resgateParcial
            ? TIPOS_RESGATE.TOTAL
            : TIPOS_RESGATE.PARCIAL,
          valorBruto: !featureData?.resgateParcial
            ? tryGetValueOrDefault([x.valorSaldo], '')
            : '',
          isDisabled: !fundoEstavaSelecionado,
          valorMinimoSaldo: valorMinimoMaximo?.valorMinimoSaldo,
        };
      }
      return x;
    });
    setDadosResgateArr(dadosAtualizados);
  };
  const handleSelecionarTipoResgate = (
    tipoResgate: string,
    idFundo: string,
    idReserva: string,
  ) => {
    setDadosResgateArr(
      dadosResgateArr?.map(x => {
        if (
          obterChaveIdentificacaoFundo(x.idFundo, x.idReserva) ===
          obterChaveIdentificacaoFundo(idFundo, idReserva)
        ) {
          return {
            ...x,
            tipoResgate,
            valorBruto:
              tipoResgate === TIPOS_RESGATE.TOTAL
                ? tryGetValueOrDefault([x.valorSaldo], '')
                : '',
          };
        }
        return x;
      }),
    );
  };

  const handleOnChangeInputResgate = (value: string, item: IDadosResgate) => {
    setDadosResgateArr(
      dadosResgateArr?.map(dadosResgateSelecionado => {
        if (
          obterChaveIdentificacaoFundo(
            dadosResgateSelecionado.idFundo,
            dadosResgateSelecionado.idReserva,
          ) === obterChaveIdentificacaoFundo(item.idFundo, item.idReserva)
        ) {
          return {
            ...dadosResgateSelecionado,
            valorBruto: masks.currencyInput.mask(value),
            requisitoSaldoMinimoAtendido: isValidaSaldoMinimo(item, value),
          };
        }
        return dadosResgateSelecionado;
      }),
    );
  };

  const obterTextoValorMaximo = (item: IDadosResgate) => {
    const dict: Record<string, string | number> = {
      T: tryGetValueOrDefault([item?.valorSaldo], ''),
      P: tryGetValueOrDefault(
        [
          parseFloat(tryGetValueOrDefault([item?.valorSaldo], '0')) -
            parseFloat(tryGetValueOrDefault([item?.valorMinimoSaldo], '0')),
        ],
        0,
      ),
    };
    return dict[item.tipoResgate];
  };

  const obterMascaraInput = (item: IDadosResgate) =>
    item?.tipoResgate === TIPOS_RESGATE.PARCIAL
      ? masks.currencyInput.mask(item.valorBruto)
      : formatarValorPadraoBrasileiro(item.valorBruto);

  const renderTable = () => {
    return (
      dadosResgateArr?.map((item, index) => {
        const cardId = `${item.idFundo}-${item.idReserva}`;
        const isSelected = selectedInput === cardId;

        return {
          descricaoFundo: (
            <FlexContainer alignItems="center">
              <Checkbox
                id={item.idFundo + item.idReserva}
                checked={isChecked(
                  item.idFundo + item.idReserva,
                  fundoSelecionado,
                )}
                onChange={() => {
                  setSelectedInput(cardId);
                  handleSelecionarCheckboxFundo(item.idFundo, item.idReserva);
                  handleSelecionarFundo(item, item.idFundo + item.idReserva);
                }}
              />

              <Text variant="body02-md" style={{ marginLeft: 12 }}>
                {item?.descricaoFundo}
              </Text>
            </FlexContainer>
          ),
          valorSaldo: (
            <>
              <Text variant="body02-md">
                {formatarValorPadraoBrasileiro(item?.valorSaldo)}
              </Text>
              <RenderConditional condition={!!featureData?.resgateParcial}>
                <S.valorMinimo>
                  {resgateInfoLabels.VALOR_MINIMO_FUNDO}
                  {tryGetMonetaryValueOrDefault(
                    valorMinimoMaximo?.valorMinimoSaldo,
                  )}
                </S.valorMinimo>
              </RenderConditional>
            </>
          ),
          tipoResgate: (
            <RenderConditional
              condition={isChecked(
                item.idFundo + item.idReserva,
                fundoSelecionado,
              )}
            >
              <S.SelectTipoResgate
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  handleSelecionarTipoResgate(
                    e.target.value,
                    item.idFundo,
                    item.idReserva,
                  );
                  handleTipoTransferencia(
                    e.target.value,
                    retornaFundoIdSelecionado(
                      item.idFundo + item.idReserva,
                      fundoSelecionado,
                    ),
                  );
                }}
              >
                <RenderConditional condition={!featureData?.resgateTotal}>
                  <S.SelectTipoResgateOption
                    value="P"
                    selected={
                      retornaTipoResgateSelecionado(
                        item.idFundo + item.idReserva,
                        tipoResgateSelecionadoArray,
                      ) === TIPOS_RESGATE.PARCIAL
                    }
                  >
                    {resgateInfoLabels.PARCIAL}
                  </S.SelectTipoResgateOption>
                </RenderConditional>

                <RenderConditional
                  condition={habilitaOpcaoResgateTotal(item.valorSaldo)}
                >
                  <S.SelectTipoResgateOption
                    value="T"
                    selected={
                      retornaTipoResgateSelecionado(
                        item.idFundo + item.idReserva,
                        tipoResgateSelecionadoArray,
                      ) === TIPOS_RESGATE.TOTAL || featureData?.resgateTotal
                    }
                  >
                    {resgateInfoLabels.TOTAL}
                  </S.SelectTipoResgateOption>
                </RenderConditional>
              </S.SelectTipoResgate>
            </RenderConditional>
          ),

          valorRetirar: (
            <RenderConditional
              condition={isChecked(
                item.idFundo + item.idReserva,
                fundoSelecionado,
              )}
            >
              <FlexContainer flexDirection="column">
                <S.InputResgate
                  isError={
                    errorInputResgate(item) ||
                    verificarCriterioSaldoMinimo(item)
                  }
                  disabled={disabledInputResgate(
                    isFetching,
                    item.idFundo,
                    item.idReserva,
                    dadosResgateArr,
                  )}
                  placeholder={formatarValorPadraoBrasileiro('0')}
                  onFocus={() => {
                    setSelectedInput(cardId);
                  }}
                  autoFocus={isSelected}
                  value={obterMascaraInput(item)}
                  onClick={() => {
                    setSelectedInput(cardId);
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (isSelected) {
                      handleOnChangeInputResgate(e.target.value, item);
                    }
                  }}
                />
                <RenderConditional
                  condition={
                    !verificarCriterioSaldoMinimo(item) &&
                    !!featureData?.resgateParcial
                  }
                >
                  <S.valorMinimo isError={errorInputResgate(item)}>
                    {resgateInfoLabels.VALOR_MINIMO}{' '}
                    {tryGetMonetaryValueOrDefault(
                      valorMinimoMaximo?.valorMinimoResgate,
                    )}
                  </S.valorMinimo>
                  <S.valorMinimo isError={errorInputResgate(item)}>
                    {resgateInfoLabels.VALOR_MAXIMO}{' '}
                    {formatarValorPadraoBrasileiro(obterTextoValorMaximo(item))}
                  </S.valorMinimo>
                </RenderConditional>

                <RenderConditional
                  condition={
                    simulacaoRetirada?.detalhesResgate[index]?.fundoId ===
                      item.idFundo && verificarCarencia(simulacaoRetirada)
                  }
                  component={
                    <FlexContainer flexDirection="column">
                      <S.MensagemCarencia>
                        {
                          simulacaoRetirada?.detalhesResgate[
                            index
                          ]?.razao?.split('.')[0]
                        }
                      </S.MensagemCarencia>
                      <S.MensagemCarencia>
                        {
                          simulacaoRetirada?.detalhesResgate[
                            index
                          ]?.razao?.split('.')[1]
                        }
                      </S.MensagemCarencia>
                    </FlexContainer>
                  }
                />
                <RenderConditional
                  condition={checkIfAllItemsAreTrue([
                    item.tipoResgate === TIPOS_RESGATE.PARCIAL,
                    item.isDisabled === true,
                    verificarCriterioSaldoMinimo(item),
                  ])}
                  component={
                    <S.MensagemSaldoMinino>
                      {resgateInfoLabels.VALOR_FUNDO_PERMANECE}
                      <br />
                      {resgateInfoLabels.COM_SALDO_MINIMO}
                      {tryGetMonetaryValueOrDefault(
                        valorMinimoMaximo?.valorMinimoSaldo,
                      )}
                    </S.MensagemSaldoMinino>
                  }
                />
              </FlexContainer>
            </RenderConditional>
          ),
        };
      }) ?? []
    );
  };

  return (
    <Display type="display-block">
      <Grid>
        <Grid.Item xs={1} md={1} lg={1}>
          <Table
            noHeader
            responsive
            data={renderTable()}
            columns={gerarColumns(
              simulacaoRetirada,
              desabilitaBotaoSimularRegraSaldoMinimo,
            )}
            noDataComponent={<p>{resgateInfoLabels.SEM_DADOS}</p>}
          />
        </Grid.Item>
        <Grid.Item xs={1 / 2}>
          <RenderConditional
            component={
              <Text variant="body03-md" color="error">
                {messageAlert}
              </Text>
            }
            condition={!!messageAlert}
          />
        </Grid.Item>
        <Grid.Item xs={1 / 2}>
          <S.WraperTotalResgate>
            <RenderConditional
              condition={subValorBruto(dadosResgateArr, valorRetirada) >= 0}
            >
              <Text>
                {resgateInfoLabels.RESTANTE}
                {formatarValorPadraoBrasileiro(
                  calculaValorRestante(
                    dadosResgateArr,
                    valorRetirada,
                  ).toString(),
                )}
              </Text>
            </RenderConditional>
            <RenderConditional
              condition={subValorBruto(dadosResgateArr, valorRetirada) < 0}
            >
              <Text color="error" variant="body03-md">
                O valor preenchido é maior que o solicitado.
              </Text>
            </RenderConditional>
            <Text>
              Total: {masks.currency.mask(sumValorBruto(dadosResgateArr))}
            </Text>
          </S.WraperTotalResgate>
        </Grid.Item>
        <Grid.Item xs={1}>
          <S.WrapperButton>
            <Button
              variant="outlined"
              onClick={() => atualizaInputValorResgatado(old => !old)}
              style={{ marginRight: 16 }}
            >
              {resgateInfoLabels.NOVO_VALOR_RESGATE}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handleSimularResgate();
              }}
              loading={isLoading}
              disabled={checkIfSomeItemsAreTrue([
                isLoading,
                desabilitaBotaoSimularRegraSaldoMinimo,
                desabilitarBotaoSimular(dadosResgateArr, valorRetirada),
                !!featureData?.prazoDiferimentoExpirado,
              ])}
            >
              Simular
            </Button>
          </S.WrapperButton>
        </Grid.Item>
      </Grid>
    </Display>
  );
};

export default ResgateInfo;
