import { api, IApiResponse } from 'main/services';
import { obterEndpointPermitirAlterarValorContribuicaoRegular } from 'previdencia/config/endpoints';
import { ResponsePermitirAlterarValorContribuicaoRegular } from '../types/ResponsePermitirAlterarValorContribuicaoRegular';

export const obterPermitirAlterarValorContribuicaoRegular = async (
  numeroResgate: string,
  cpfCnpj: string,
  numeroCertificado: string,
): Promise<ResponsePermitirAlterarValorContribuicaoRegular | undefined> => {
  const { data } = await api.post<
    IApiResponse<ResponsePermitirAlterarValorContribuicaoRegular>
  >(obterEndpointPermitirAlterarValorContribuicaoRegular(), {
    numeroCertificado,
    numeroResgate,
    cpfCnpj,
  });

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data?.dados?.entidade;
};
