import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import * as valorMinimoMaximoApi from 'previdencia/features/SolicitacaoResgate/services/valorMinimoMaximo.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { ResponseValorMininoMaximo } from 'previdencia/features/SolicitacaoResgate/types/ResponseValorMininoMaximo';

const useObterValorMinimoMaximo = (
  cpf: string,
  numCertificado: string,
): UseQueryResult<ResponseValorMininoMaximo | undefined> => {
  const { toastError } = useToast();
  return useQuery(
    ['prev-valorminino-maximo', cpf, numCertificado],
    () => valorMinimoMaximoApi.obterValorMinimoMaximo(cpf, numCertificado),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (error: Error) => toastError(error.message),
    },
  );
};

export default useObterValorMinimoMaximo;
