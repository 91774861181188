import InputSelect from 'main/components/form/InputSelect';
import styled from 'styled-components/macro';

export const WrapperButton = styled.div(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
}));

export const ResgateInfoText = styled.p<{ fontWeight?: boolean }>(
  ({ fontWeight }) => ({
    marginBottom: 34,
    fontSize: 18,
    fontWeight: fontWeight ? 'bold' : 'normal',
    textAlign: 'center',
  }),
);

export const MessageSaldo = styled.p`
  font-size: 12px;
  text-align: center;
`;

export const Select = styled(InputSelect)(({ theme: { color } }) => ({
  minWidth: '130px',
  ':disabled': {
    border: `1px solid ${color.neutral['02']}`,
    background: color.neutral['06'],
    cursor: 'not-allowed',
  },
}));

export const InputResgate = styled.input<{ isError: boolean }>(
  ({ theme, isError }) => ({
    height: 54,
    width: 120,
    borderRadius: 3,
    padding: 10,
    outline: 'none',
    fontSize: 16,
    border: `1px solid ${isError ? '#900000' : theme.color.neutral['04']}`,
    marginRight: 5,
    color: isError ? '#900000' : '',
    '&:disabled': {
      background: '#DBDCDE',
    },
  }),
);

export const WraperTotalResgate = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const SelectTipoResgate = styled.select(() => ({
  width: 120,
  height: 54,
  fontSize: 16,
  borderRadius: 3,
  paddingLeft: 6,
  cursor: 'pointer',
}));

export const SelectTipoResgateOption = styled.option`
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  list-style: none;
  background-color: #fff;
  cursor: pointer;
`;

export const MensagemCarencia = styled.span(() => ({
  color: '#900000',
  width: '100%',
  marginBottom: 10,
  fontSize: 16,
}));

export const valorMinimo = styled.p<{ isError?: boolean }>(({ isError }) => ({
  color: isError ? '#900000' : '#808288',
  fontSize: 11,
  whiteSpace: 'normal',
}));

export const MensagemSaldoMinino = styled.span(() => ({
  marginTop: 10,
  marginBottom: 10,
  width: '100%',
  color: '#900000',
  fontSize: 11,
}));
