import React from 'react';
import { Text } from '@cvp/design-system/react';
import { informativoFinalizar } from '../../constants/constants';

const TextoInformativoFinalizar: React.FC = () => {
  return (
    <>
      <Text variant="body02-md" margin>
        {informativoFinalizar.VALORES_ALTERACAO}
      </Text>
      <Text variant="body02-md" margin>
        {informativoFinalizar.IMPOSTO}
      </Text>
      <Text variant="body02-md" margin>
        {informativoFinalizar.RESGATE_TOTAL}
      </Text>
      <Text variant="body02-md" margin>
        {informativoFinalizar.AVALIACAO_SOLICITACAO}
      </Text>
      <Text variant="body02-md" margin>
        {informativoFinalizar.SOLICITACAO_CANCELADA}
      </Text>
      <Text variant="body02-md" margin>
        {informativoFinalizar.PARCELAS_EM_ABERTO}
      </Text>
      <Text variant="body02-md" margin>
        <b>{informativoFinalizar.CARENCIA}</b>
      </Text>
    </>
  );
};
export default TextoInformativoFinalizar;
