import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { useState } from 'react';
import { ResponseValorMininoMaximo } from 'previdencia/features/SolicitacaoResgate/types/ResponseValorMininoMaximo';

type useValidarValorMaximoMinimoRetorno = {
  handleIniciarSimulacao: () => boolean;
  openModal: boolean;
  handleCloseModal: () => void;
  modalMessage: string;
};

const useValidarValorMaximoMinimo = (
  valorResgate: string,
  tipoResgate: string,
  valorMinimoMaximo: ResponseValorMininoMaximo | undefined,
): useValidarValorMaximoMinimoRetorno => {
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setMessageModal] = useState('');

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleIniciarSimulacao = () => {
    const valorResgateFormatado = parseFloat(valorResgate) / 100;

    if (
      tipoResgate === 'total' &&
      valorResgateFormatado > Number(valorMinimoMaximo?.valorSaldo)
    ) {
      setOpenModal(true);
      setMessageModal('Você não pode tirar um valor maior que está na reversa');
      return false;
    }
    if (
      tipoResgate === 'parcial' &&
      Math.floor(
        Number(valorMinimoMaximo?.valorSaldo) - valorResgateFormatado,
      ) < Number(valorMinimoMaximo?.valorMinimoCertificado)
    ) {
      setOpenModal(true);
      setMessageModal(
        `O plano deverá permanecer com reserva mínima de ${formatarValorPadraoBrasileiro(
          valorMinimoMaximo?.valorMinimoCertificado,
        )}`,
      );
      return false;
    }

    return true;
  };

  return {
    handleIniciarSimulacao,
    openModal,
    modalMessage,
    handleCloseModal,
  };
};

export default useValidarValorMaximoMinimo;
