import { useContext, useState } from 'react';
import { AppContext } from 'main/contexts/AppContext';
import { useToast } from 'main/hooks/useToast';
import { IApiResponse } from 'main/services';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';
import * as AssinaturaEletronicaApi from '../services/assinaturaEletronica.api';
import { IResponseVerificarAssinatura } from '../types/IResponseVerificarAssinatura';
import { ISolicitacaoResgateContextData } from '../types/ISolicitacaoResgateContextData';
import {
  DadosContatos,
  ValidarPermissaoResgateResult,
} from '../types/AssinaturaEletronicaResgate';

const validarPermissaoParaFazerResgate = (
  data?: IApiResponse<IResponseVerificarAssinatura>,
): ValidarPermissaoResgateResult => {
  const defaultResult = {
    assinaturaEletronicaRequerida: false,
    resgatePermitido: false,
  };
  if (!data) return defaultResult;
  const {
    dados: { entidade },
  } = data;
  if (entidade) {
    return {
      assinaturaEletronicaRequerida:
        entidade?.assinaturaEletronicaRequerida?.toUpperCase() === 'S',
      resgatePermitido: entidade?.resgatePermitido?.toUpperCase() === 'S',
      prazoDiferimentoExpirado:
        entidade?.prazoDiferimentoExpirado?.toUpperCase() === 'S',
    };
  }

  return defaultResult;
};

export const useAssinaturaEletronicaResgate = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { toastError } = useToast();
  const { featureData } =
    usePrevidenciaContext<ISolicitacaoResgateContextData>();

  const {
    cliente: { cpfCnpj, numCertificado },
  } = useContext(AppContext);

  const verificarPermissaoParaFazerResgate = async (
    codigoBeneficio?: string,
    codigoPlano?: string,
  ): Promise<ValidarPermissaoResgateResult> => {
    try {
      setLoading(true);
      if (codigoBeneficio && codigoPlano) {
        const result =
          await AssinaturaEletronicaApi.verificarNecessidadeAssinatura({
            cpf: cpfCnpj,
            numeroCertificado: numCertificado,
            codigoBeneficio,
            codigoPlano,
          });

        return validarPermissaoParaFazerResgate(result);
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
    return validarPermissaoParaFazerResgate();
  };

  const solicitarAssinatura = async (data: Partial<DadosContatos>) => {
    try {
      setLoading(true);
      if (featureData?.solicitarAssinaturaEletronica && featureData) {
        const resultado =
          await AssinaturaEletronicaApi.solicitarAssinaturaEletronica({
            codResgate: featureData?.codigoResgate,
            cpf: cpfCnpj,
            dddTelefone: data?.numeroDDD,
            email: data?.email,
            numeroTelefone: data.numeroTelefoneSemDDD,
          });
        if (
          !resultado?.dados.sucesso &&
          (resultado?.dados?.mensagens ?? [])?.length > 0
        ) {
          const [mensagemErro] = resultado?.dados?.mensagens ?? [];
          toastError(mensagemErro?.descricao);
        }
        return resultado?.dados?.entidade;
      }
    } catch {
      setError(true);
      return false;
    } finally {
      setLoading(false);
    }
    return null;
  };
  return {
    loading,
    error,
    verificarPermissaoParaFazerResgate,
    solicitarAssinatura,
  };
};
