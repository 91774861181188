import React from 'react';
import { Text } from '@cvp/design-system/react';
import { Display } from 'main/features/Auth/components';
import { messageValidarDadosLabels } from '../../constants/constants';

type MessageAlertResgateProps = {
  message: string;
};

const MessageAlertResgate: React.FC<MessageAlertResgateProps> = ({
  message,
}) => {
  return (
    <Display>
      <Text variant="body02-md" color="primary" margin>
        {messageValidarDadosLabels.ATENCAO}
      </Text>
      <br />
      <Text variant="body02-sm" color="text">
        {message}
      </Text>
    </Display>
  );
};

export default MessageAlertResgate;
