import { useParams } from 'react-router';
import { useQuery, UseQueryResult } from 'react-query';
import { useToast } from 'main/hooks/useToast';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { IApiResponse } from 'main/services';
import {
  RequestSimularBeneficioApi,
  ResponseSimularBeneficios,
} from 'previdencia/features/SimulacaoRenda/types/simularBeneficios';
import * as SimularBeneficioApi from 'previdencia/features/SimulacaoRenda/services/simularBeneficio.api';

const useSimularBeneficio = (
  request: RequestSimularBeneficioApi | undefined,
): UseQueryResult<IApiResponse<ResponseSimularBeneficios> | undefined> => {
  const { toastError, toastSuccess } = useToast();
  const { certificado } = useParams<{ certificado: string }>();

  return useQuery(
    ['prev-simular-beneficio', certificado],
    () => SimularBeneficioApi.simularBeneficios(request),
    {
      cacheTime: reactQueryCacheDuration(),
      refetchOnWindowFocus: false,
      retry: false,
      onError: (erro: Error) => toastError(erro.message),
      onSuccess: data => {
        if (data?.dados?.mensagens) {
          toastSuccess(String(data.dados.mensagens[0].descricao));
        }
      },
    },
  );
};

export default useSimularBeneficio;
