import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import * as consultarResumoAliquotaApi from 'previdencia/features/SolicitacaoResgate/services/consultarResumoAliquota.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { ResponseConsultarResumoAliquota } from 'previdencia/features/SolicitacaoResgate/types/ResponseConsultarResumoAliquota';

const useObterConsultaResumoAliquotas = (
  cpf: string,
  numCertificado: string,
  numResgate: string,
): UseQueryResult<ResponseConsultarResumoAliquota | undefined> => {
  const { toastError } = useToast();
  return useQuery(
    ['-prev-consulta-resumo-aliquota', cpf, numCertificado, numResgate],
    () =>
      consultarResumoAliquotaApi.obterConsultaResumoAliquota(
        cpf,
        numCertificado,
        numResgate,
      ),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (error: Error) => toastError(error.message),
    },
  );
};

export default useObterConsultaResumoAliquotas;
