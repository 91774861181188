import styled from 'styled-components/macro';
import { Modal } from '@cvp/design-system/react';

export const ModalDetalhes = styled(Modal)`
  max-width: 90%;
  @media print {
    max-width: 100%;
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 8px;
  background-color: rgba(0, 95, 200, 0.06);
  @media print {
    display: none;
  }
`;
