import Icon from 'main/components/Icon';

export const colunsTableAccountBank = [
  {
    name: <Icon name="homeFamily" />,
    selector: 'check',
    maxWidth: '80px',
    minWidth: '80px',
  },
  {
    name: 'Banco',
    selector: 'nomeBanco',
    width: '250px',
  },
  {
    name: 'Agência',
    selector: 'numeroAgencia',
  },
  {
    name: 'Tipo da Conta',
    selector: 'tipoContaBanco',
    minWidth: '400px',
  },
  {
    name: 'Conta',
    selector: 'codContaBanco',
    minWidth: '300px',
  },
];
