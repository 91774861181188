import React from 'react';
import { Text, Divider } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional';
import TextoInformativoSimulacaoRenda from 'previdencia/features/SimulacaoRenda/components/TextoInformativoSimulacaoRenda';
import useSimularBeneficio from 'previdencia/features/SimulacaoRenda/hooks/useSimularBeneficio';
import InformacoesRenda from 'previdencia/features/SimulacaoRenda/components/InformacoesRenda';
import TabelaDadosSimulacao from 'previdencia/features/SimulacaoRenda/components/TabelaDadosSimulacao';
import RedirecionamentoUltimasSimulacoes from 'previdencia/features/SimulacaoRenda/components/RedirecionamentoUltimasSimulacoes';
import TextoReceberRenda from 'previdencia/features/SimulacaoRenda/components/TextoReceberRenda';
import TextoInformativoTipoPagamento from 'previdencia/features/SimulacaoRenda/components/TextoInformativoTipoPagamento';
import TextoRendaSimulada from 'previdencia/features/SimulacaoRenda/components/TextoRendaSimulada';
import TextoRendaMensalLiquida from 'previdencia/features/SimulacaoRenda/components/TextoRendaMensalLiquida';
import { EnumTipoRendaCodigo } from 'previdencia/features/SimulacaoRenda/types/enum';
import { ResultadoSimulacaoProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';

const ResultadoSimulacao: React.FC<ResultadoSimulacaoProps> = ({
  request,
  exibirHistorico,
}) => {
  const { data, isLoading } = useSimularBeneficio(request);

  const resultadoSimulacao = data?.dados?.entidade;

  const codigoTipoPagamento = resultadoSimulacao?.codTipoPagamento;

  const exibirTextoReservaMinima = (): boolean => {
    return (
      !!resultadoSimulacao?.descMensagemError &&
      !!resultadoSimulacao?.codTipoPagamento
    );
  };

  const exibirTextoRendaMensalLiquida = (): boolean => {
    return (
      codigoTipoPagamento ===
        EnumTipoRendaCodigo.VitaliciaComPrazoMinimoGarantido ||
      codigoTipoPagamento === EnumTipoRendaCodigo.PrazoCerto ||
      codigoTipoPagamento ===
        EnumTipoRendaCodigo.VitaliciaReversívelAoBeneficiario
    );
  };

  if (isLoading) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <>
      <Text variant="body02-md" margin>
        Veja o resultado da simulação:
      </Text>

      <InformacoesRenda dados={resultadoSimulacao} />

      <Divider />

      <RenderConditional condition={exibirTextoReservaMinima()}>
        <TextoRendaSimulada dados={resultadoSimulacao} />
        <Divider />
      </RenderConditional>

      <RenderConditional condition={exibirTextoRendaMensalLiquida()}>
        <TextoRendaMensalLiquida dados={resultadoSimulacao} />
        <Divider />
      </RenderConditional>

      <RenderConditional condition={!!resultadoSimulacao?.codTipoPagamento}>
        <TextoInformativoTipoPagamento dados={resultadoSimulacao} />
        <Divider />
      </RenderConditional>

      <TabelaDadosSimulacao
        tabelaSimulacao={resultadoSimulacao}
        request={request}
      />

      <Divider />

      <TextoReceberRenda />

      <Divider />

      <TextoInformativoSimulacaoRenda
        codigoTipoPagamento={codigoTipoPagamento}
      />

      <Divider />

      <RedirecionamentoUltimasSimulacoes
        exibirHistorico={exibirHistorico}
        request={request}
      />
    </>
  );
};

export default ResultadoSimulacao;
