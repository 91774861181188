import { api, IApiResponse } from 'main/services';
import { obterEndpointListaFundosParaResgate } from 'previdencia/config/endpoints';
import {
  ResponseListaFundosReserva,
  IResponseListaFundosReserva,
  IResponseListaFundosReservaRetorno,
} from 'previdencia/features/SolicitacaoResgate/types/ResponseListaFundosReserva';

export const obterListaFundosReserva = async (
  cpfCnpj: string,
  numeroCertificado: string,
  beneficioId: string,
  planoId: string,
): Promise<ResponseListaFundosReserva | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseListaFundosReserva>>(
    obterEndpointListaFundosParaResgate(),
    {
      cpfCnpj,
      numeroCertificado,
      beneficioId,
      planoId,
    },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data?.dados?.entidade;
};

export const obterListaFundosParaReserva = async (
  numeroCertificado: string,
  valorBruto: string,
  tipoRetirada: string,
): Promise<IResponseListaFundosReservaRetorno | undefined> => {
  const { data } = await api.post<IApiResponse<IResponseListaFundosReserva>>(
    obterEndpointListaFundosParaResgate(),
    {
      codigoEmpresa: '500',
      codigoCertificado: numeroCertificado,
      valorBruto,
      tipoRetirada,
    },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);

  return data?.dados?.entidade?.dados?.retorno;
};
