import { api, IApiResponse } from 'main/services';
import { obterEndpointValorMinimoMaximo } from 'previdencia/config/endpoints';
import { ResponseValorMininoMaximo } from 'previdencia/features/SolicitacaoResgate/types/ResponseValorMininoMaximo';

export const obterValorMinimoMaximo = async (
  cpfCnpj: string,
  numeroCertificado: string,
): Promise<ResponseValorMininoMaximo | undefined> => {
  const { data } = await api.post<IApiResponse<ResponseValorMininoMaximo>>(
    obterEndpointValorMinimoMaximo(),
    {
      cpfCnpj,
      numeroCertificado,
    },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(messageError);
  return data?.dados?.entidade;
};
