import React from 'react';
import { Button, Display, Text, Card } from '@cvp/design-system/react';
import SkeletonLoading from 'main/components/SkeletonLoading';
import RenderConditional from 'main/components/RenderConditional/RenderConditional';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import { CardResumoProduto } from 'seguros/components/CardResumoProduto/CardResumoProduto';
import { CertificadoEmissaoProps } from 'seguros/types/CertificadoVidaTypes';
import useVidaNavigation from 'seguros/hooks/useVidaNavigation';

export const CertificadoEmissao: React.FC<CertificadoEmissaoProps> = ({
  error,
  gerarPdf,
  loading,
  existsPdf,
  messageService,
}) => {
  const { goDadosSeguradoVida } = useVidaNavigation();

  if (loading) {
    return <SkeletonLoading blocks={2} />;
  }

  return (
    <Display type="display-block">
      <CardResumoProduto />
      <RenderConditional condition={!error}>
        <Card>
          <Card.Content padding={[6, 8, 6, 8]}>
            <Text variant="headline-05" color="primary" margin>
              Emissão de Certificado
            </Text>

            <RenderConditional condition={existsPdf}>
              <Text variant="body02-sm" margin>
                Você pode visualizar o certificado:
              </Text>

              <Display>
                <Button
                  variant="outlined"
                  onClick={goDadosSeguradoVida}
                  data-testid="btnVoltar"
                >
                  Voltar
                </Button>
                <Button
                  variant="primary"
                  onClick={gerarPdf}
                  loading={loading}
                  disabled={loading}
                >
                  Emitir
                </Button>
              </Display>
            </RenderConditional>

            <RenderConditional condition={!existsPdf}>
              <Text variant="body02-sm" align="center" margin>
                {tryGetValueOrDefault([messageService], '-')}
              </Text>

              <Display>
                <Button
                  variant="outlined"
                  onClick={goDadosSeguradoVida}
                  data-testid="btnVoltar"
                >
                  Voltar
                </Button>
              </Display>
            </RenderConditional>
          </Card.Content>
        </Card>
      </RenderConditional>
    </Display>
  );
};
