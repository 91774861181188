import React from 'react';
import { Text, Display, Button } from '@cvp/design-system/react';
import Modal from 'main/components/Modal';
import Icon from 'main/components/Icon';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributarioServicos/types/AlteracaoRegimeTributario';

const ModalAlertaAlteracaoExpirada: React.FC<
  REGIME_TRIBUTARIO_TYPES.IModalAlertaAlteracaoExpiradaProps
> = ({ open, onClose, mensagem }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Text margin color="secondary">
        <strong>
          <Icon name="warning" /> Atenção!
        </strong>
      </Text>
      <Display type="display-block">
        <Text variant="caption-02" margin align="left">
          {mensagem}
        </Text>
      </Display>
      <Display justify="center">
        <Button variant="secondary" onClick={onClose}>
          Certo, entendi!
        </Button>
      </Display>
    </Modal>
  );
};

export default ModalAlertaAlteracaoExpirada;
