import { tryGetValueOrDefault } from 'main/utils/conditional';
import masks from 'main/utils/masks';
import { TIPOS_RESGATE } from '../constants/constants';
import { adicionarResgateTotal } from '../factories/solicitacaoResgateFactory';
import {
  converterValoresResgateParaFloat,
  isChecked,
  isCheckedTipoResgate,
  retornaFundoIdSelecionado,
  retornaTipoResgateSelecionadoFundoId,
} from './ValidarParametrosResgate';
import { IDadosResgate } from '../types/ResponseListaFundosReserva';

interface InputResgateValues {
  fundoId: string;
  reservaId: string;
  fundoSelecionado: IDadosResgate[];
  tipoResgateSelecionadoArray: any[];
  dadosResgateArr: IDadosResgate[];
  dadosResgateTotal: IDadosResgate[];
  valorSaldo: string | undefined;
  valorBruto: string;
}

export const errorInputResgate = (item: IDadosResgate) => {
  const { valorMinimoSaldoParsed, valorSaldoParsed } =
    converterValoresResgateParaFloat(item, item.valorBruto || '');
  if (
    item.tipoResgate === TIPOS_RESGATE.TOTAL &&
    valorSaldoParsed < valorMinimoSaldoParsed
  ) {
    return false;
  }
  if (
    parseFloat(masks.currencyInput.unmask(item.valorBruto || '')) / 100 >
    parseFloat(tryGetValueOrDefault([item.valorSaldo], ''))
  )
    return true;

  return (
    parseFloat(masks.currencyInput.unmask(item.valorBruto || '')) / 100 < 50 &&
    !!item.valorBruto
  );
};

export const disabledInputResgate = (
  isFetching: boolean,
  fundoId: string,
  reservaId: string,
  dadosResgateArr: IDadosResgate[],
) => {
  return (
    isFetching ||
    !!dadosResgateArr.find(
      x =>
        x.idFundo === fundoId &&
        x.idReserva === reservaId &&
        x.tipoResgate === TIPOS_RESGATE.TOTAL,
    )
  );
};

export const checkAutoFocus = (
  fundoId: string,
  reservaId: string,
  fundoSelecionado: IDadosResgate[],
  isDisabled: boolean | undefined,
) => {
  return isChecked(fundoId + reservaId, fundoSelecionado) && isDisabled;
};

export const validarValue = (values: InputResgateValues) => {
  return retornaTipoResgateSelecionadoFundoId(
    values.fundoId + values.reservaId,
    values.tipoResgateSelecionadoArray,
  ) ===
    retornaFundoIdSelecionado(
      values.fundoId + values.reservaId,
      values.fundoSelecionado,
    ) &&
    isCheckedTipoResgate(
      values.fundoId + values.reservaId,
      values.tipoResgateSelecionadoArray,
    )
    ? adicionarResgateTotal(
        retornaFundoIdSelecionado(
          values.fundoId + values.reservaId,
          values.fundoSelecionado,
        ),
        values.fundoId + values.reservaId,
        values.dadosResgateArr,
        values.dadosResgateTotal,
        values.valorSaldo,
      )
    : values.valorBruto;
};
