import { api, IApiResponse } from 'main/services';
import { obterEndpointConfirmarResgate } from 'previdencia/config/endpoints';
import { ResponseConfirmarResgate } from 'previdencia/features/SolicitacaoResgate/types/ResponseConfirmarResgate';

export const obterConfirmacaoResgate = async (
  cpfCnpj: string,
  numeroResgate: string,
): Promise<IApiResponse<ResponseConfirmarResgate | undefined>> => {
  const { data } = await api.post<IApiResponse<ResponseConfirmarResgate>>(
    obterEndpointConfirmarResgate(),
    {
      cpfCnpj,
      numeroResgate,
    },
  );

  const messageError: string | undefined =
    data?.dados?.mensagens?.[0].descricao ||
    'Ocorreu um erro ao confirmar o resgate.';

  if (data?.dados?.sucesso === false || data.sucesso === false)
    throw new Error(messageError);
  return data;
};
