import { useEffect } from 'react';
import { useToast } from 'main/hooks/useToast';
import { useQuery } from 'react-query';
import { useAuth } from 'main/features/Auth/hooks';
import * as comprovanteResgateApi from 'previdencia/services/comprovante.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { ResponseConfirmarResgate } from 'previdencia/features/SolicitacaoResgate/types/ResponseConfirmarResgate';
import { IApiResponse } from 'main/services';
import { IResponseComprovanteOperacao } from 'previdencia/types/ResponseComprovante';

type UseObterComprovanteResgate = {
  data: IApiResponse<IResponseComprovanteOperacao | undefined> | undefined;
  isFetching: boolean;
  isSuccess: boolean;
  isRefetchError: boolean;
  refetch: () => void;
};

const useObterComprovanteResgate = (
  cpf: string,
  numCertificado: string,
  idRequisicao: string,
  isSucessConfirmarResgate: boolean,
  codigoRequisicao: string,
  confirmarResgate?: ResponseConfirmarResgate | undefined,
): UseObterComprovanteResgate => {
  const { toastError, toastSuccess } = useToast();
  const { user } = useAuth();

  const { data, isFetching, isSuccess, isRefetchError, refetch } = useQuery(
    ['-prev-comprovante-resgate', cpf, numCertificado, idRequisicao],
    () =>
      comprovanteResgateApi.obterComprovanteOperacao(
        cpf,
        numCertificado,
        idRequisicao,
        codigoRequisicao,
        user.nomeAcesso,
      ),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      enabled: false,
      onError: (error: Error) => toastError(error.message),
      onSuccess: () => toastSuccess('Gerando comprovante...'),
    },
  );

  useEffect(() => {
    if (isSucessConfirmarResgate && confirmarResgate) {
      refetch();
    }
  }, [isSucessConfirmarResgate, confirmarResgate, refetch]);

  return {
    data,
    isFetching,
    isSuccess,
    isRefetchError,
    refetch,
  };
};

export default useObterComprovanteResgate;
