import { useEffect, useState } from 'react';
import { Button, Card, Display, Text } from '@cvp/design-system/react';
import Modal from 'main/components/Modal';
import SkeletonLoading from 'main/components/SkeletonLoading';
import FeatureAuthorizer from 'main/features/Auth/components/FeatureAuthorizer';
import {
  PREV_PERMISSIONS,
  PRINCIPAL_USERS,
  USER_PROFILES,
} from 'main/features/Auth/config/userProfiles';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import TabelaSolicitacoesResgate from 'previdencia/features/CancelamentoSolicitacaoResgate/components/TabelaSolicitacoesResgate';
import useCancelamentoSolicitacaoResgate from 'previdencia/features/CancelamentoSolicitacaoResgate/hooks/useCancelamentoSolicitacaoResgate';
import useCancelamentoHistoricoResgate from 'previdencia/features/CancelamentoSolicitacaoResgate/hooks/useCancelamentoHistoricoResgate';
import { usePrevidenciaContext } from 'previdencia/contexts/PrevidenciaContextProvider';

const CancelamentoSolicitacaoResgate = (): React.ReactElement => {
  const { response, fetchData, loading } = useCancelamentoSolicitacaoResgate();
  const { clearFeatureData } = usePrevidenciaContext();

  const {
    response: listaHistoricoResgate,
    loading: recuperandoHistoricoResgate,
    fetchData: recuperarHistoricoSolicitacaoResgate,
  } = useCancelamentoHistoricoResgate();
  const [openModal, setOpenModal] = useState(false);
  const { navigateTo } = usePrevNavigation();

  useEffect(() => {
    if (response) {
      recuperarHistoricoSolicitacaoResgate();
      setOpenModal(true);
    }
  }, [response]);

  useEffect(() => {
    clearFeatureData();
  }, []);

  if (recuperandoHistoricoResgate) {
    return <SkeletonLoading blocks={1} />;
  }

  return (
    <Display type="display-block">
      <PrevidenciaResumo />
      <FeatureAuthorizer
        requiredPermissions={[
          PREV_PERMISSIONS.HISTORICO_E_CANCELAMENTO_DE_RESGATE,
        ]}
        requiredRoles={[
          ...PRINCIPAL_USERS,
          USER_PROFILES.ANALISTA_CONSULTA,
          USER_PROFILES.ANALISTA_SAIDA,
          USER_PROFILES.ANALISTA_ENTRADA,
        ]}
      >
        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Text variant="headline-05" color="primary" margin>
              Histórico e Cancelamento de Resgate
            </Text>
            <Text variant="body02-sm" color="text" margin>
              Veja o histórico de solicitações feitas para o certificado ou
              cancele uma solicitação de resgate.
            </Text>
            <TabelaSolicitacoesResgate
              data={listaHistoricoResgate?.entidade?.listaDadosHistorico}
              onClick={(numeroResgate: string): void => {
                fetchData({ numeroResgate });
              }}
              loading={loading}
            />
            <Button
              variant="outlined"
              onClick={() => navigateTo('dados-plano')}
            >
              Voltar
            </Button>
          </Card.Content>
        </Card>
      </FeatureAuthorizer>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Text variant="body02-md">O resgate foi cancelado com sucesso.</Text>
      </Modal>
    </Display>
  );
};

export default CancelamentoSolicitacaoResgate;
