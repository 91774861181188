export interface IModaisRegimeTributario {
  modalTipo: boolean;
  modalDocusign: boolean;
  modalAlertaContatos: boolean;
  modalAlertaAlteracaoExpirada: boolean;
}

export enum ResponsePerfilTributario {
  AGENCIA = 'agencia',
}

export interface IModalAlertaAlteracaoExpiradaProps {
  open: boolean;
  onClose: () => void;
  mensagem: string;
}
