import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import * as consultarDetalheCalculo from 'previdencia/features/SolicitacaoResgate/services/consultarDetalheCalculo.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { ResponseConsultarDetalheCalculo } from 'previdencia/features/SolicitacaoResgate/types/ResponseConsultarDetalheCalculo';

const useObterConsultaDetalheCalculo = (
  cpf: string,
  numCertificado: string,
  numResgate: string,
): UseQueryResult<ResponseConsultarDetalheCalculo | undefined> => {
  const { toastError } = useToast();
  return useQuery(
    ['-prev-consulta-detalhe-calculo', cpf, numCertificado, numResgate],
    () =>
      consultarDetalheCalculo.obterConsultaDetalheCalculo(
        cpf,
        numCertificado,
        numResgate,
      ),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (error: Error) => toastError(error.message),
    },
  );
};

export default useObterConsultaDetalheCalculo;
