import LinkedValue from 'main/features/Validation/types/LinkedValue';
import masks from 'main/utils/masks';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { tryGetValueOrDefault } from 'main/utils/conditional';
import {
  DadosResgate,
  IDadosResgate,
} from 'previdencia/features/SolicitacaoResgate/types/ResponseListaFundosReserva';
import { TIPOS_RESGATE } from '../constants/constants';
import { tipoContaCaixa } from '../constants/typeAccountCaixa';
import { DadosBancarios } from '../types/DadosBancarios';
import {
  ListaDetalhesCalculo,
  ResponseConsultarDetalheCalculo,
} from '../types/ResponseConsultarDetalheCalculo';
import { ResponseContasBancarias } from '../types/ResponseContasBancarias';

export type NewDadosResgate = {
  descricaoFundo: string;
  descricaoReserva: string;
  fundoId: string;
  reservaId: string;
  tipoResgate: string;
  valorBruto: string;
};

type dadosCamposBancariosLink = {
  numeroBancoLink: LinkedValue<string>;
  agenciaLink: LinkedValue<string>;
  contaLink: LinkedValue<string>;
  digitoContaLink: LinkedValue<string>;
  operacaoLink: LinkedValue<string>;
  operacaoContaCaixaLink: LinkedValue<string>;
};

export function adicionarResgateTotal(
  fundoIdSelecionado: string,
  fundoIdItem: string,
  dadosResgateArr: IDadosResgate[] | undefined,
  dadosResgateTotal: IDadosResgate[],
  valor?: string,
) {
  if (fundoIdItem && fundoIdSelecionado) {
    const result = dadosResgateArr?.map(dadosResgateSelecionado => {
      if (
        fundoIdSelecionado ===
          dadosResgateSelecionado.idFundo + dadosResgateSelecionado.idReserva &&
        fundoIdItem ===
          dadosResgateSelecionado.idFundo + dadosResgateSelecionado.idReserva
      ) {
        return {
          ...dadosResgateSelecionado,
          tipoResgate: TIPOS_RESGATE.TOTAL,
          valorBruto: masks.currencyInput.mask(valor || ''),
        };
      }

      return {
        ...dadosResgateSelecionado,
      };
    });

    const converterArrayObjeto = result
      ?.filter(item => item.valorBruto)
      .reduce<any>((acc, item) => {
        const key = item.idFundo + item.idReserva;
        if (item) {
          if (!acc[key]) {
            acc[key] = {};
          }
          acc[key] = item;
          return acc;
        }
        return {};
      }, {} as DadosResgate);

    dadosResgateTotal.push(converterArrayObjeto[fundoIdItem]);

    return formatarValorPadraoBrasileiro(valor);
  }

  return [];
}

const converterSolicitacaoResgateViewModelParaPayload = (
  dadosResgate: IDadosResgate[] | undefined,
) => {
  const formatTotal: NewDadosResgate[] = [];

  dadosResgate?.forEach(item => {
    formatTotal.push({
      fundoId: item.idFundo,
      descricaoFundo: item.descricaoFundo,
      valorBruto: tryGetValueOrDefault([item.valorBruto], ''),
      descricaoReserva: item.descricaoFundo,
      tipoResgate: item.tipoResgate,
      reservaId: item.idReserva,
    });
  });

  return formatTotal
    ?.filter(item => item.valorBruto)
    ?.map(fundos => {
      return {
        ...fundos,
        valorBruto:
          fundos.tipoResgate === TIPOS_RESGATE.PARCIAL
            ? String(
                Number(masks.currencyInput.unmask(fundos.valorBruto)) / 100,
              )
            : fundos.valorBruto,
      };
    });
};

export const FormatarObjetoDadosResgate = (
  dadosResgate: IDadosResgate[] | undefined,
) => {
  return converterSolicitacaoResgateViewModelParaPayload(dadosResgate);
};

const converterSiglaParaCodigoOperacao = (siglaTipoContaBancaria: string) => {
  if (siglaTipoContaBancaria === 'CC') return '001';
  if (siglaTipoContaBancaria === 'CP') return '013';
  return siglaTipoContaBancaria;
};

export const retornaValoresContaBancaria = (
  contaBancariasData: ResponseContasBancarias[] | undefined,
) => {
  return (
    contaBancariasData?.map(item => {
      return {
        descricaoPagamento: item.nomeCanal,
        digitoAgencia: '',
        numeroAgencia: item.numAgencia,
        nomeBanco: item.nomeBanco,
        tipoPagamentoId: '9',
        numeroBanco: item.codBanco,
        numeroConta: item.codContaBanco,
        digitoConta: item.codDigitoConta,
        tipoContaBanco: converterSiglaParaCodigoOperacao(
          item.codTipoContaBanco,
        ),
      } as DadosBancarios;
    }) || []
  );
};

function retornaContaCaixa(value: string) {
  const codigoOperacao = value === '0001' ? '001' : value;
  const result = tipoContaCaixa.find(item => item.id === codigoOperacao);
  return result?.valor || '';
}

export const setarCamposBancarios = (
  data: DadosBancarios,
  {
    numeroBancoLink,
    agenciaLink,
    contaLink,
    digitoContaLink,
    operacaoLink,
    operacaoContaCaixaLink,
  }: dadosCamposBancariosLink,
) => {
  numeroBancoLink.set({
    value: data.numeroBanco,
    isValid: true,
    errorMsg: '',
  });

  agenciaLink.set({
    value: data.numeroAgencia,
    isValid: true,
    errorMsg: '',
  });

  contaLink.set({
    value: data.numeroConta,
    isValid: true,
    errorMsg: '',
  });

  digitoContaLink.set({
    value: data.digitoConta,
    isValid: true,
    errorMsg: '',
  });

  operacaoContaCaixaLink.set({
    value:
      data.numeroConta.length > 13
        ? retornaContaCaixa(data.numeroConta.substring(0, 4))
        : retornaContaCaixa(data.numeroConta.substring(0, 3)),
    isValid: true,
    errorMsg: '',
  });

  operacaoLink.set({
    value: data.tipoContaBanco,
    isValid: true,
    errorMsg: '',
  });
};

export const totalTabelaCalculo = (
  item: ResponseConsultarDetalheCalculo | undefined,
) => {
  return {
    data: 'Total',
    rendimento: item?.totalRendimento,
    carregamentoSaida: item?.totalCarregamentoSaida,
    baseIrrf: item?.totalBaseIrrf,
    valorIrrf: item?.totalValorIrrf,
    taxaSaida: item?.totalTaxaSaida,
    aliquotaIrrf: item?.totalAliquotaIrrf,
    codConta: item?.codConta,
    codFundo: '',
    descFundo: '',
    numResgate: '',
    saldoAporte: item?.totalSaldoAporte,
    saldoPrincipal: item?.totalSaldoPrincipal,
    rendimentos: item?.totalRendimentosAporte,
    valorCorrecao: item?.totalValorCorrecao,
    valorLiquido: item?.totalValorLiquido,
    valorPrincipal: item?.totalValorPrincipalAporte,
    valorSolicitado: item?.totalValorSolicitado,
  } as ListaDetalhesCalculo;
};
