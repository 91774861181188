import { obterEndpointValidarConta } from 'previdencia/config/endpoints';
import { api, IApiResponse } from 'main/services';
import { ResponseValidarConta } from 'previdencia/types/DadosContaBancaria';

export const validarConta = async (
  cpfCnpj: string,
  codigoAgencia: string,
  codigoOperacao: string,
  digitoVerificador: string,
  numeroBanco: string,
  numeroConta: string,
): Promise<ResponseValidarConta | undefined> => {
  const { data } = await api.post<
    IApiResponse<ResponseValidarConta | undefined>
  >(obterEndpointValidarConta(), {
    cpfCnpj,
    codigoAgencia,
    codigoOperacao,
    digitoVerificador,
    numeroBanco,
    numeroConta,
  });

  const mensagemErro: string | undefined =
    data?.dados?.mensagens?.[0].descricao;

  if (data?.dados?.sucesso === false) throw new Error(mensagemErro);

  return data?.dados?.entidade;
};
