import { EnumStatusCardPrevidencia } from 'consultaCliente/types/ICardsApresentacao';
import styled from 'styled-components/macro';

export const Text = styled.div<{ statusLength: number; status: string }>(
  ({ theme: { color, font, line }, statusLength, status }) => ({
    maxWidth: statusLength === 8 ? '52%' : '85%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    overflow: 'hidden',
    color:
      status === EnumStatusCardPrevidencia.Ativo ||
      status === EnumStatusCardPrevidencia.Suspenso
        ? color.line.primary
        : color.neutral['04'],
    fontSize: font.size.md,
    fontWeight: font.weight.md,
    lineHeight: line.height.lg,
  }),
);

export const TextClientName = styled.div<{ statusLength: number }>(
  ({ statusLength }) => ({
    maxWidth: statusLength === 8 ? '45%' : '75%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    overflow: 'hidden',
    color: '#414042',
  }),
);

export const Container = styled.div`
  div {
    margin: 4px 2px;
  }
  span {
    margin: 4px 2px;
  }
  button {
    margin: 4px 2px;
  }
  hr {
    margin: 4px;
  }
`;

export const IconContainer = styled.div`
  margin: 0 !important;
  svg {
    height: 20px;
  }
`;
