import { useToast } from 'main/hooks/useToast';
import { useQuery, UseQueryResult } from 'react-query';
import * as contasBancariasApi from 'previdencia/features/SolicitacaoResgate/services/contasBancarias.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { DadosBancarios } from 'previdencia/features/SolicitacaoResgate/types/DadosBancarios';
import { ResponseContasBancarias } from 'previdencia/features/SolicitacaoResgate/types/ResponseContasBancarias';
import { tipoContasBancariasCefMain } from 'main/constants/tipoContasBancariasMain';
import { useState } from 'react';
import { CODIGO_BANCO_CAIXA } from 'main/utils/formatarNumeroContaCaixa';
import { TipoContaBancaria } from '../types/TipoContaBancaria';
import { retornaValoresContaBancaria } from '../factories/solicitacaoResgateFactory';
import { DadosInstituicaoBancaria } from '../types/DadosInstituicaoBancaria';

type useObterContasBancariasReturn = {
  instituicoesBancarias: DadosInstituicaoBancaria[] | [];
  contasBancarias: DadosBancarios[] | undefined;
  isLoadingDadosBancarios: boolean;
  obterTiposContasBancarias: (numeroBanco: string) => TipoContaBancaria[];
  alternarExibicaoFormularioDadosBancarios: (showForm: boolean) => void;
  exibeFormularioDadosBancarios: boolean;
};

const useContaBancaria = (
  cpf: string,
  resgateId?: string | undefined,
): useObterContasBancariasReturn => {
  const [exibeFormularioDadosBancarios, setExibeFormularioDadosBancarios] =
    useState(false);
  const alternarExibicaoFormularioDadosBancarios = (showForm: boolean) =>
    setExibeFormularioDadosBancarios(showForm);

  const { toastError } = useToast();

  const queryResultContasBancarias = useQuery(
    ['-prev-contas-bancarias', cpf, resgateId],
    () => contasBancariasApi.obterContasBancarias(cpf, resgateId),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (error: Error) => toastError(error.message),
    },
  );

  const prepararRetornoContasBancarias = (
    queryResult: UseQueryResult<ResponseContasBancarias[] | undefined, Error>,
  ): DadosBancarios[] | undefined =>
    retornaValoresContaBancaria(queryResult?.data ?? []);

  const obterTiposContasBancariasNaoCef = (): TipoContaBancaria[] => {
    return [
      {
        codigo: '001',
        descricao: 'Conta Corrente',
      },
      {
        codigo: '013',
        descricao: 'Conta Poupança',
      },
    ];
  };
  const queryResultInstituicoesBancariasMain = useQuery(
    ['-prev-lista-bancos', cpf],
    () => contasBancariasApi.obterListaBancos(cpf),
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      onError: (error: Error) => toastError(error.message),
    },
  );

  const obterTiposContasBancariasCef = (): TipoContaBancaria[] => {
    return tipoContasBancariasCefMain;
  };

  const obterTiposContasBancarias = (
    numeroBanco: string | number,
  ): TipoContaBancaria[] => {
    if (String(numeroBanco) === CODIGO_BANCO_CAIXA)
      return obterTiposContasBancariasCef();
    return obterTiposContasBancariasNaoCef();
  };

  return {
    instituicoesBancarias: queryResultInstituicoesBancariasMain?.data ?? [],
    contasBancarias: prepararRetornoContasBancarias(queryResultContasBancarias),
    isLoadingDadosBancarios: queryResultContasBancarias?.isLoading,
    obterTiposContasBancarias,
    alternarExibicaoFormularioDadosBancarios,
    exibeFormularioDadosBancarios,
  };
};

export default useContaBancaria;
