export enum DefaultValuesSimulacaoRenda {
  CPF_BENEFICIARIO = '0',
  PACOTE_REVERSAO = '0',
}

export enum EnumTipoRendaCodigo {
  Vitalicia = '1',
  Temporaria = '2',
  VitaliciaComPrazoMinimoGarantido = '3',
  VitaliciaReversívelAoBeneficiario = '4',
  PrazoCerto = '12',
}

export enum EtapasFluxoSimulacaoRenda {
  INICIO_SIMULACAO = 0,
  CALCULO_PRE_SIMULACAO = 1,
  RESULTADO_SIMULACAO = 2,
  HISTORICO_SIMULACAO = 3,
}
