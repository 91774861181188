import React from 'react';
import { Text, Divider } from '@cvp/design-system/react';
import { ResponseConsultarResumoAliquota } from 'previdencia/features/SolicitacaoResgate/types/ResponseConsultarResumoAliquota';
import { formatarData, formatarDataHora } from 'main/utils';
import { formatarValorPadraoBrasileiro } from 'main/utils/money';
import { ResponseConsultarDetalheCalculo } from 'previdencia/features/SolicitacaoResgate/types/ResponseConsultarDetalheCalculo';
import { headerInfoSimulacaoLabels } from 'previdencia/features/SolicitacaoResgate/constants/constants';
import * as S from './styles';

type HeaderInfoSimulacaoProps = {
  resumoAliquota: ResponseConsultarResumoAliquota | undefined;
  detalhesCalculo: ResponseConsultarDetalheCalculo | undefined;
  saldo: string;
  valorBruto: number;
};

const HeaderInfoSimulacao: React.FC<HeaderInfoSimulacaoProps> = ({
  resumoAliquota,
  detalhesCalculo,
  saldo,
  valorBruto,
}) => {
  return (
    <>
      <Divider />
      <S.Container>
        <S.Section>
          <S.WrapperItem>
            <S.TextItem>{headerInfoSimulacaoLabels.NOME} </S.TextItem>
            <Text variant="body03-md" color="text-light">
              {resumoAliquota?.nomCliente}
            </Text>
          </S.WrapperItem>

          <S.WrapperItem>
            <S.TextItem>
              {headerInfoSimulacaoLabels.PRODUTO_MODALIDADE}
            </S.TextItem>
            <Text variant="body03-md" color="text-light">
              {resumoAliquota?.desProduto}, {resumoAliquota?.tipProduto}
            </Text>
          </S.WrapperItem>

          <S.WrapperItem>
            <S.TextItem>
              {headerInfoSimulacaoLabels.SALDO_EM} {formatarData(new Date())}
            </S.TextItem>
            <Text variant="body03-md" color="text-light">
              {formatarValorPadraoBrasileiro(saldo)}
            </Text>
          </S.WrapperItem>

          <S.WrapperItem>
            <S.TextItem>{headerInfoSimulacaoLabels.VALOR_SIMULADO} </S.TextItem>
            <Text variant="body03-md" color="text-light">
              {formatarValorPadraoBrasileiro(valorBruto / 100)}
            </Text>
          </S.WrapperItem>

          <S.WrapperItem>
            <S.TextItem>
              {headerInfoSimulacaoLabels.VALOR_LIQUIDO_ESTIMADO}
            </S.TextItem>
            <Text variant="body03-md" color="text-light">
              {formatarValorPadraoBrasileiro(resumoAliquota?.totalValorLiquido)}
            </Text>
          </S.WrapperItem>

          <S.WrapperItem>
            <S.TextItem>
              {headerInfoSimulacaoLabels.VALOR_MAXIMO_PERMITIDO}
            </S.TextItem>
            <Text variant="body03-md" color="text-light">
              R$ 0, 00
            </Text>
          </S.WrapperItem>
        </S.Section>

        <S.Section>
          <S.WrapperItem>
            <S.TextItem>{headerInfoSimulacaoLabels.CERTIFICADO} </S.TextItem>
            <Text variant="body03-md" color="text-light">
              {resumoAliquota?.codConta}
            </Text>
          </S.WrapperItem>

          <S.WrapperItem>
            <S.TextItem>
              {headerInfoSimulacaoLabels.DATA_HORA_SIMULACAO}{' '}
            </S.TextItem>
            <Text variant="body03-md" color="text-light">
              {formatarDataHora(String(new Date()))}
            </Text>
          </S.WrapperItem>

          <S.WrapperItem>
            <S.TextItem>
              {headerInfoSimulacaoLabels.VALOR_NOMINAL_ESTIMADO}{' '}
            </S.TextItem>
            <Text variant="body03-md" color="text-light">
              {formatarValorPadraoBrasileiro(resumoAliquota?.totalSaldoNominal)}
            </Text>
          </S.WrapperItem>

          <S.WrapperItem>
            <S.TextItem>
              {headerInfoSimulacaoLabels.VALOR_IR_ESTIMADO}{' '}
            </S.TextItem>
            <Text variant="body03-md" color="text-light">
              {formatarValorPadraoBrasileiro(resumoAliquota?.totalValorIrrf)}
            </Text>
          </S.WrapperItem>

          <S.WrapperItem>
            <S.TextItem>
              {headerInfoSimulacaoLabels.TAXA_CARREGAMENTO_SAIDA_ESTIMADA}
            </S.TextItem>
            <Text variant="body03-md" color="text-light">
              {formatarValorPadraoBrasileiro(
                detalhesCalculo?.totalCarregamentoSaida,
              )}
            </Text>
          </S.WrapperItem>

          <S.WrapperItem>
            <S.TextItem>
              {headerInfoSimulacaoLabels.VALOR_BLOQUEADO}{' '}
            </S.TextItem>
            <Text variant="body03-md" color="text-light">
              R$ 0,00
            </Text>
          </S.WrapperItem>
        </S.Section>
      </S.Container>
    </>
  );
};

export default HeaderInfoSimulacao;
