import { ICardResumoCertificadoVida } from 'previdencia/types/CardResumoCertificado';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';

export const useCardResumoCertificado = () => {
  const { certificado, id } = useParams<{ certificado: string; id: string }>();
  const client = useQueryClient();
  const [dadosApoliceVida, setDadosApoliceVida] =
    useState<ICardResumoCertificadoVida>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const recuperadadosApoliceVida = () => {
    setIsLoading(true);

    const [result] =
      client.getQueriesData<ICardResumoCertificadoVida[]>('produtos-vida');
    const data = result[1].find(item => item.numeroApolice === certificado);

    const dados: ICardResumoCertificadoVida = {
      numeroCpf: id,
      numeroApolice: data?.numeroApolice ?? '',
      dataInicioVigencia: data?.dataInicioVigencia ?? '',
      dataTerminoVigencia: data?.dataTerminoVigencia ?? '',
      numeroBilhete: data?.numeroBilhete ?? '',
      nomeSegurado: data?.nomeSegurado ?? '',
      descricaoProduto: data?.descricaoProduto ?? '',
      situacaoRegistro: data?.situacaoRegistro ?? '',
      codigoCliente: data?.codigoCliente ?? '',
      codigoProduto: data?.codigoProduto ?? '',
      codigoProdutoOrigem: data?.codigoProdutoOrigem ?? '',
      numeroContrato: data?.numeroContrato ?? '',
      dataEmissao: data?.dataEmissao ?? '',
      dataPropostaEfetivacao: data?.dataPropostaEfetivacao ?? '',
    };
    setDadosApoliceVida(dados);
    setIsLoading(false);
  };

  useEffect(() => {
    recuperadadosApoliceVida();
  }, []);

  return { dadosApoliceVida, isLoading };
};
