import { MotivoResgate } from 'previdencia/features/SolicitacaoResgate/types/MotivoResgate';
import { IDataTableColumn } from 'react-data-table-component';
import Icon from 'main/components/Icon';

export const COLUNAS_MOTIVO_RESGATE: IDataTableColumn<MotivoResgate>[] = [
  {
    selector: 'radio',
    name: <Icon name="homeFamily" />,
    center: true,
    maxWidth: '80px',
    minWidth: '80px',
  },
  {
    selector: 'descricao',
    name: 'Descrição',
    center: false,
  },
];
