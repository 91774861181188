import { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Card, Display, Grid, Text, Button } from '@cvp/design-system/react';
import { AppContext } from 'main/contexts/AppContext';
import { useMessageEvent } from 'main/hooks/useMessageEvent';
import ModalTipoDocuSign from 'main/components/AssinaturaDocuSign/ModalTipoDocuSign';
import ModalTokenDocuSign from 'main/components/AssinaturaDocuSign/ModalTokenDocuSign';
import ModalAlertaContatos from 'main/components/AssinaturaDocuSign/ModalAlertaContatos';
import { useObterContatosDefaultPrevidencia } from 'main/hooks/useObterContatosDefaultPrevidencia';
import RenderConditional from 'main/components/RenderConditional';
import {
  checkIfAllItemsAreTrue,
  tryGetValueOrDefault,
} from 'main/utils/conditional';
import { IMessageEvent } from 'main/types/IMessageEvent';
import usePrevNavigation from 'previdencia/hooks/usePrevNavigation';
import PrevidenciaResumo from 'previdencia/components/PrevidenciaResumo';
import ModalAlertaAlteracaoExpirada from 'previdencia/features/AlteracaoRegimeTributarioServicos/components/ModalAlertaAlteracaoExpirada';
import * as API from 'previdencia/features/AlteracaoRegimeTributarioServicos/hooks/useAlterarPerfilTributario';
import * as CONSTS from 'previdencia/features/AlteracaoRegimeTributarioServicos/constants/constants';
import * as REGIME_TRIBUTARIO_TYPES from 'previdencia/features/AlteracaoRegimeTributarioServicos/types/AlteracaoRegimeTributario';
import * as S from 'previdencia/features/AlteracaoRegimeTributarioServicos/pages/styles';

const AlteracaoRegimeTributarioServicos = () => {
  const history = useHistory();

  const { cliente } = useContext(AppContext);

  const [checkDeclaracao, setCheckDeclaracao] = useState<boolean>(false);

  const [exibirBotaoFecharModal, setExibirBotaoFecharModal] =
    useState<boolean>(false);

  const [open, setOpen] =
    useState<REGIME_TRIBUTARIO_TYPES.IModaisRegimeTributario>(
      CONSTS.MODAIS_REGIME_TRIBUTARIO,
    );

  const { subscribe, unsubscribe } = useMessageEvent();

  const {
    data: reponseObterContatosDefault,
    isFetching: loadingObterContatos,
    isFetched: recuperacaoEmailExecutada,
  } = useObterContatosDefaultPrevidencia();

  const { emailDefault, numerosTelefone } = reponseObterContatosDefault ?? {};

  const { goDadosPlano } = usePrevNavigation();

  const {
    dadosPerfilTributario,
    loadingDadosPerfilTributario,
    obterAtualizacaoPerfilTributario,
  } = API.useAlterarPerfilTributario();

  const urlAssinatura =
    dadosPerfilTributario?.entidade?.assinaturas?.[0].urlAssinatura;

  const msgAlteracaoExpirada = tryGetValueOrDefault(
    [dadosPerfilTributario?.mensagens?.[0].descricao],
    '',
  );

  const confirmarDeclaracao = (): void => {
    if (checkDeclaracao) {
      setOpen(prev => ({ ...prev, modalTipo: true }));
    }
  };

  const handleAssinar = (): void => {
    obterAtualizacaoPerfilTributario();

    setOpen(prev => ({
      ...prev,
      modalDocusign: true,
      modalAlertaAlteracaoExpirada: true,
    }));
  };

  const fecharModal = (): void => {
    setOpen(CONSTS.MODAIS_REGIME_TRIBUTARIO);
  };

  const executarAcoesAposAssinaturaDocusign = (): void => {
    subscribe(event => {
      const {
        data: { eventName, eventSuccess },
      } = event as MessageEvent<IMessageEvent>;
      if (
        checkIfAllItemsAreTrue([
          eventName === 'retornoAcaoConcluirAssinatura',
          eventSuccess,
        ])
      ) {
        setExibirBotaoFecharModal(true);
      }
    });
  };

  const abrirModalAlertaContatos = () => {
    if (
      checkIfAllItemsAreTrue([!loadingObterContatos, recuperacaoEmailExecutada])
    ) {
      setOpen(prev => ({ ...prev, modalAlertaContatos: true }));
    }
  };

  useEffect(abrirModalAlertaContatos, [
    loadingObterContatos,
    recuperacaoEmailExecutada,
  ]);

  useEffect(() => {
    executarAcoesAposAssinaturaDocusign();

    return () => unsubscribe(() => setExibirBotaoFecharModal(false));
  }, []);

  return (
    <>
      <Display type="display-block">
        <PrevidenciaResumo />

        <Card>
          <Card.Content padding={[4, 4, 4]}>
            <Grid>
              <Grid.Item xs={1}>
                <Text
                  variant="headline-05"
                  color="primary"
                  data-testId="tituloRegimeTributario"
                >
                  {CONSTS.TEXTOS_REGIME_TRIBUTARIO.TITULO}
                </Text>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Text
                  variant="body01-lg"
                  color="primary"
                  data-testId="tituloConsentimentoDeclaracaoRegimeTributario"
                >
                  {CONSTS.TEXTOS_REGIME_TRIBUTARIO.SUBTITULO}
                </Text>
                <Display>
                  <Text variant="body02-md" color="text">
                    <S.CheckboxDeclaracao
                      onClick={() => setCheckDeclaracao(!checkDeclaracao)}
                      data-testId="checkboxConsentimentoDeclaracaoRegimeTributario"
                    />
                    <span data-testId="textoConsentimentoDeclaracaoRegimeTributario">
                      {CONSTS.TEXTOS_REGIME_TRIBUTARIO.INFO_CHECKBOX}
                    </span>
                  </Text>
                </Display>
              </Grid.Item>
              <Grid.Item xs={1}>
                <Display>
                  <Button
                    variant="outlined"
                    onClick={goDadosPlano}
                    data-testId="voltarDadosPlano"
                  >
                    Voltar
                  </Button>
                  <Button
                    onClick={confirmarDeclaracao}
                    disabled={!checkDeclaracao}
                    data-testId="confirmarDeclaracao"
                  >
                    Confirmar
                  </Button>
                </Display>
              </Grid.Item>
            </Grid>
          </Card.Content>
        </Card>
      </Display>
      <ModalAlertaContatos
        open={open.modalAlertaContatos}
        onClose={fecharModal}
        redirect={() =>
          history.push('/cliente/produtos/previdencia/dados-participante')
        }
        dados={{
          email: emailDefault,
          telefone: numerosTelefone,
        }}
      />

      <ModalTipoDocuSign
        tituloModal="Alteração de Regime Tributário"
        open={open.modalTipo}
        handleClose={fecharModal}
        executarAssinatura={handleAssinar}
        loading={loadingDadosPerfilTributario}
        dadosCliente={{
          email: emailDefault,
          mobileNumber: numerosTelefone,
          cpfCnpj: cliente?.cpfCnpj,
          numeroCertificado: cliente?.numCertificado,
          tipoProduto: undefined,
        }}
      />

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          open.modalDocusign,
          !!urlAssinatura,
        ])}
      >
        <ModalTokenDocuSign
          open={open.modalDocusign}
          handleClose={fecharModal}
          urlTokenPage={tryGetValueOrDefault([urlAssinatura], '')}
          exibirBotaoFecharModal={exibirBotaoFecharModal}
        />
      </RenderConditional>

      <RenderConditional
        condition={checkIfAllItemsAreTrue([
          open.modalAlertaAlteracaoExpirada,
          !loadingDadosPerfilTributario,
          !urlAssinatura,
        ])}
      >
        <ModalAlertaAlteracaoExpirada
          open={open.modalAlertaAlteracaoExpirada}
          onClose={fecharModal}
          mensagem={msgAlteracaoExpirada}
        />
      </RenderConditional>
    </>
  );
};

export default AlteracaoRegimeTributarioServicos;
