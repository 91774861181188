import React, { useContext } from 'react';
import { Text, Button, Grid } from '@cvp/design-system/react';
import { required, validDate } from 'main/features/Validation/validations';
import { AppContext } from 'main/contexts/AppContext';
import useFieldLink from 'main/hooks/useFieldLink';
import { SelectItem } from 'main/components/form/Select';
import { CalendarData } from 'main/components/form/Calendar/Calendar.type';
import LinkedValue from 'main/features/Validation/types/LinkedValue';
import { DadosRendaVitaliciaReversivelProps } from 'previdencia/features/SimulacaoRenda/types/SimulacaoRendaComponentProps';
import * as S from 'previdencia/features/SimulacaoRenda/components/DadosRendaVitaliciaReversivel/styles';

const DadosRendaVitaliciaReversivel: React.FC<
  DadosRendaVitaliciaReversivelProps
> = ({ proximoServico, voltar, tipoRenda }) => {
  const { cliente } = useContext(AppContext);

  const [dataNascimentoLink, validarDataNascimento] = useFieldLink(
    {} as CalendarData,
  );
  const [generoLink, validarCampoGenero] = useFieldLink('');
  const [porcentagemLink, validarPorcentagem] = useFieldLink('');

  const isSelected = (value: string, link: LinkedValue<string>): boolean => {
    return value === link.get().value;
  };

  const simularRendaVitaliciaReversivel = (): void => {
    if (
      validarDataNascimento().isValid &&
      validarCampoGenero().isValid &&
      validarPorcentagem().isValid
    ) {
      proximoServico({
        cpf: cliente?.cpfCnpj,
        numeroCertificado: cliente?.numCertificado,
        tipoRenda,
        dthNascimentoConjuge: dataNascimentoLink
          .get()
          .value.initialDate?.toISOString(),
        staGeneroConjuge: generoLink.get().value,
        pctReversao: porcentagemLink.get().value,
        cpfBeneficiario: '0',
      });
    }
  };

  return (
    <>
      <Grid>
        <Grid.Item xs={1} md={1 / 3}>
          <Text variant="caption-01" color="text-light">
            Porcentagem reversível ao beneficiário
          </Text>
          <S.InputSelectSimulacao
            link={porcentagemLink}
            placeholder="Selecione uma porcentagem"
            validationRules={[required()]}
          >
            <SelectItem
              value="100"
              text="Toda a sua renda"
              selected={isSelected('100', porcentagemLink)}
            />
            <SelectItem
              value="75"
              text="75%"
              selected={isSelected('75', porcentagemLink)}
            />
            <SelectItem
              value="50"
              text="50%"
              selected={isSelected('50', porcentagemLink)}
            />
          </S.InputSelectSimulacao>
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 3}>
          <Text variant="caption-01" color="text-light">
            Sexo do beneficiário
          </Text>
          <S.InputSelectSimulacao
            link={generoLink}
            placeholder="Selecione o sexo"
            validationRules={[required()]}
          >
            <SelectItem
              value="M"
              text="Masculino"
              selected={isSelected('M', generoLink)}
            />
            <SelectItem
              value="F"
              text="Feminino"
              selected={isSelected('F', generoLink)}
            />
          </S.InputSelectSimulacao>
        </Grid.Item>

        <Grid.Item xs={1} md={1 / 3}>
          <Text variant="caption-01" color="text-light">
            Data de nascimento
          </Text>
          <S.InputDataNascimento
            link={dataNascimentoLink}
            validationRules={[validDate()]}
            maxDate={new Date()}
          />
        </Grid.Item>
      </Grid>

      <S.ContainerButtons>
        <Button variant="outlined" onClick={voltar}>
          Voltar
        </Button>
        <Button variant="primary" onClick={simularRendaVitaliciaReversivel}>
          Simular
        </Button>
      </S.ContainerButtons>
    </>
  );
};

export default DadosRendaVitaliciaReversivel;
