import { useEffect } from 'react';
import { useToast } from 'main/hooks/useToast';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from 'react-query';
import * as confirmarResgateApi from 'previdencia/features/SolicitacaoResgate/services/confirmarResgate.api';
import { reactQueryCacheDuration } from 'portabilidade/config/constants/app.config';
import { ResponseDefinirContribuicaoRegular } from 'previdencia/features/SolicitacaoResgate/types/ResponseDefinirContribuicaoRegular';
import { IApiResponse } from 'main/services';
import { ResponseConfirmarResgate } from 'previdencia/features/SolicitacaoResgate/types/ResponseConfirmarResgate';
import { useHandleReponseResgate } from './useHandleResponseResgate';

type UseObterConfirmacaoResgate = {
  confirmarResgate: ResponseConfirmarResgate | undefined;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<
    QueryObserverResult<
      IApiResponse<ResponseConfirmarResgate | undefined>,
      Error
    >
  >;
  isFetchingConfirmacaoResgate: boolean;
  isSuccessConfirmarResgate: boolean;
};

const useObterConfirmacaoResgate = (
  cpf: string,
  numResgate: string,
  definirContribuicaoRegular?: ResponseDefinirContribuicaoRegular,
  isSuccessDefinirContribuicaoRegular?: boolean,
): UseObterConfirmacaoResgate => {
  const { handleResponseResgate, exibirMensagensFeedbackResgate } =
    useHandleReponseResgate();
  const { toastError, toastSuccess } = useToast();
  const {
    refetch,
    isFetching,
    data,
    isSuccess: isSuccessConfirmarResgate,
  } = useQuery(
    ['-prev-confirmar-resgate', cpf, numResgate],
    async () => {
      const responseConfirmacaoResgate =
        await confirmarResgateApi.obterConfirmacaoResgate(cpf, numResgate);
      const { sucesso, status, mensagemSucesso } = handleResponseResgate(
        responseConfirmacaoResgate,
      );
      exibirMensagensFeedbackResgate(sucesso, status, mensagemSucesso);
      return responseConfirmacaoResgate;
    },
    {
      staleTime: reactQueryCacheDuration(),
      retry: false,
      enabled: false,
      onError: (error: Error) => toastError(error.message),
      onSuccess: dataResult => {
        if (dataResult) {
          toastSuccess(dataResult?.dados?.mensagens?.[0].descricao);
        }
      },
    },
  );

  useEffect(() => {
    if (isSuccessDefinirContribuicaoRegular && definirContribuicaoRegular) {
      refetch();
    }
  }, [
    isSuccessDefinirContribuicaoRegular,
    definirContribuicaoRegular,
    refetch,
  ]);

  return {
    confirmarResgate: data?.dados?.entidade,
    refetch,
    isFetchingConfirmacaoResgate: isFetching,
    isSuccessConfirmarResgate,
  };
};

export default useObterConfirmacaoResgate;
